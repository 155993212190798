import React, {useState} from 'react';
import Button from 'components/Button';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { modalActions } from 'store/modal/modal.store';

interface RejectPromptProps {
  title: string;
  description: string;
  onConfirm: (comment: string) => void;
}


const RejectPrompt = ({ title, description, onConfirm }: RejectPromptProps) => {

  const dispatch: any = useDispatch();
  const [comment, setComment] = useState('');
  const [touched, setTouched] = useState(false);
  const closeModalHandler = () => {
    dispatch(modalActions.closeModal());
  };

  const commentHandler = (e: any): void => {
    setComment(e.target.value);
    setTouched(true);
  };
  const rejectHandler = (): void => {
    if (touched && comment) {
      onConfirm(comment);
    }
    setTouched(true);
  };

  return (
    <div>
      <section className="py-5 text-gray-600 text-center flex items-center justify-center">
        <div>
          <div className="flex items-center justify-center">
            <FontAwesomeIcon icon={faCommentDots}/>
          </div>
          {title &&
              <p className="text-lg font-bold mt-2">{title}</p>
          }
        </div>
      </section>
      {description &&
          <div className="relative px-16 mx-auto border-y py-7">
              <textarea
                  name="comment"
                  id="comment"
                  cols={40}
                  rows={3}
                  placeholder="Unesi komentar . . ."
                  onChange={commentHandler}
                  className={`w-full border p-2 ${!comment && touched ? ' ring ring-red-600' : 'focus:ring focus:ring-base-500'} rounded-lg focus:outline-none`}>

              </textarea>
            {!comment && touched && <small className="absolute bottom-2.5 left-0 pl-16 text-red-600 font-medium">*Obavezno polje</small>}
          </div>
      }
      <section className="flex items-center justify-center gap-x-6 py-5 px-16">
        <Button
          bg="outline-primary"
          className="w-1/2"
          size="regular"
          onClick={closeModalHandler}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-1/2"
          size="regular"
          onClick={rejectHandler}
        >
          Potvrdi
        </Button>
      </section>
    </div>
  );
};

export default RejectPrompt;