import http from 'libs/http';

class CartApi {

  makePurchase = (payload: any) => {
    return http.post('/v2/admin/purchase-for-user/', payload);
  };
}


export default CartApi;