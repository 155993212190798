import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { loaderActions } from 'store/loader/loader.store';
import NotificationApi from 'modules/notification/services/notification.api';
import {setHeaders} from 'libs/http';


const api: NotificationApi = new NotificationApi();


interface BookingState {
  notifications: any[];
}

const initialState: BookingState = {
  notifications: [],
};


const notificationSlice: any = createSlice({
  name: 'notification',
  initialState,
  reducers: {

  },
});

export const uploadImage = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({'content-type': 'multipart/form-data'});
      const {data} = await api.uploadImage(payload);
      await setHeaders({'content-type': 'application/json'});
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const deleteImage = (imageId: number) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.deleteImage(imageId);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const uploadAttachments = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({'content-type': 'multipart/form-data'});
      const {data} = await api.uploadAttachments(payload);
      await setHeaders({'content-type': 'application/json'});
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const deleteAttachment = (imageId: number) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.deleteAttachment(imageId);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const sendNotification = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({'content-type': 'multipart/form-data'});
      const { data } = await api.sendNotification(payload);
      await setHeaders({'content-type': 'application/json'});
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};





export const notificationActions: any = notificationSlice.actions;

export default notificationSlice.reducer;