import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

interface ImageViewerProps {
  images: string[];
  index: number;
}

const ImageViewer = ({ images, index }: ImageViewerProps) => {
  const [selectedIndex, setSelectedIndex] = useState(index); // Čuvamo indeks, a ne URL

  const previousImageHandler = (): void => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImageHandler = (): void => {
    setSelectedIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    console.log(`Selected image: ${images[selectedIndex]}`);
  }, [selectedIndex, images]);

  return (
    <div className="relative overflow-hidden">
      <div className="absolute left-0 top-0 h-full flex items-center justify-center">
        <button
          onClick={previousImageHandler}
          className={`h-10 w-10 rounded-full shadow border border-gray-200 bg-white/70 z-10 text-gray-700
              transform translate-x-4 group transition duration-200 ease-in-out`}
        >
          <FontAwesomeIcon icon={faAngleLeft} className="text-gray-500" />
        </button>
      </div>
      <div className="w-full h-[calc(100vh-105.6px)] flex items-center justify-center">
        <img
          src={images[selectedIndex]}
          alt={`Image ${selectedIndex}`}
          className="aspect-[2/1] object-contain"
        />
      </div>
      <div className="absolute right-0 top-0 h-full flex items-center justify-center">
        <button
          onClick={nextImageHandler}
          className={`h-10 w-10 rounded-full shadow border border-gray-200 bg-white/70 z-10 text-gray-700
              transform -translate-x-4 group transition duration-200 ease-in-out`}
        >
          <FontAwesomeIcon icon={faAngleRight} className="text-gray-500" />
        </button>
      </div>
    </div>
  );
};

export default ImageViewer;
