import http from 'libs/http';

class ProviderApi {
  getProviders = (url: string) => {
    return http.get(url);
  };

  addProvider = (payload: any) => {
    return http.post('/v2/admin/providers/', payload);
  };

  editProvider = (providerId: number, payload: any) => {
    return http.patch(`/v2/admin/providers/${providerId}/`, payload);
  };

  deleteProvider = (providerId: number) => {
    return http.patch(`/v2/admin/providers/${providerId}/`, { hide: true });
  };

  exportXlsx = () => {
    return http.get(`/v2/admin/benefits/export/`, { responseType: "blob" });
    // return http.get(`/v2/admin/providers/export/`, { responseType: "blob" });
  };

  getAdministrators = (url: string) => {
    return http.get(url);
  };

  addAdministrator = (payload: any) => {
    return http.post(`/v2/admin/provider-administrators/`, payload);
  };

  editAdministrator = (payload: any, adminId: any) => {
    return http.patch(`/v2/admin/provider-administrators/${adminId}/`, payload);
  };

  deleteAdministrator = (adminId: any) => {
    return http.patch(`/v2/admin/provider-administrators/${adminId}/`, { hide: true });
  };

  configureCompany = (companyId: number, payload: any) => {
    return http.patch(`/v2/admin/companies/${companyId}/`, payload);
  };

  getCompany = (companyId: number) => {
    return http.get(`/v2/admin/companies/${companyId}`);
  };
}


export default ProviderApi;