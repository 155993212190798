import React, { useEffect, useState } from 'react';
import { decamelize } from 'humps';
import { capitalize } from 'lodash';
import Input from 'components/Input';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import CheckBox from 'components/Checkbox';
import TextEditor from 'components/TextEditor';
import { ElementLoader } from 'components/Loaders';
import UploadImage from 'components/UploadImage';
import { countryOptions } from 'libs/countryOptions';
import {modalActions} from 'store/modal/modal.store';
import SearchableDropdown from 'components/SearchableDropdown';
import { addClient, editClient } from 'modules/client/services/client.store';

interface AddEditClientFormProps {
  client?: any;
  type: string;
}

const AddEditClientForm = ({ client, type }: AddEditClientFormProps) => {

  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(false);
  const [logoImg, setLogoImg] = useState<undefined | File>(undefined)

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: 'company',
      name: '',
      email: '',
      address: '',
      phone: '',
      bank: '',
      bankNum: '',
      bankAddress: '',
      pib: '',
      cin: '',
      contactName: '',
      country: '',
      description: '',
      picture: '',
      teamBuilding: false,
      employeesPriceLimit: '',
    },
  });

  const onSubmit = async (data: any): Promise<any> => {
    const formData = new FormData();
    for (const key in data) {
      if (data[key] !== '') formData.append(decamelize(key), data[key]);
    }

    if (isValid) {
      try {
        setLoading(true);
        type === 'add' ? await dispatch(addClient(formData)) : await dispatch(editClient(data.id, formData));
        setLoading(false);
        dispatch(modalActions.closeModal());
        toast.success(`Uspešno ste ${type === 'add' ? 'dodali' : 'ažurirali'} klijenta!`)
      } catch (e: any) {
        if (e.status !== 500) {
          const errorKeys = Object.keys(e.data);
          errorKeys.forEach((key: any) => {
            e.data[key].forEach((err: any) => {
              toast.error(`${capitalize(key)}: ${capitalize(err)}`);
            })
          })
        } else {
          toast.error('Nešto nije u redu!');
        }
        setLoading(false);
      }
    }
  };

  const setFileHandler = (file: File): void => {
    // @ts-ignore
    setValue('picture', file);
    setLogoImg(file)
  };

  const removeImageHandler = () => {
    if (getValues('picture')){
      // @ts-ignore
      setValue('picture', null)
      setLogoImg(undefined)
    }
  }

  const closeForm = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    if (client) {
      const disabledProps = ['restrictionsCategories', 'restrictionsProviders', 'restrictionsCategoriesObjects', 'restrictionsProvidersObjects'];
      for (const key in client) {
        if (client[key] !== '' && key !== 'picture' && !disabledProps.includes(key)) {
          // @ts-ignore
          setValue(key, client[key]);
        }
      }
    }
  }, [client]);

  return(
    <div className="relative">
      <div className="p-7 space-y-6">
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='text'
              label="Naziv"
              autoFocus
              height='medium'
              placeholder="Unesi naziv"
              isTouched={touchedFields.name}
              error={errors.name}
              validationRules={register('name', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='text'
              label="Email"
              height='medium'
              placeholder="primer@mail.com"
              isTouched={touchedFields.email}
              error={errors.email}
              validationRules={register('email', {
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
                  message: "Pogrešan format mejla",
                },
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='text'
              label="Adresa"
              height='medium'
              placeholder="Unesi adresu"
              isTouched={touchedFields.address}
              error={errors.address}
              validationRules={register('address', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='number'
              label="Telefon"
              height='medium'
              placeholder="Unesi telefon"
              isTouched={touchedFields.phone}
              error={errors.phone}
              validationRules={register('phone', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='text'
              label="Banka"
              height='medium'
              placeholder="Unesi banku"
              isTouched={touchedFields.bank}
              error={errors.bank}
              validationRules={register('bank', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='number'
              label="Broj bankovnog racuna"
              height='medium'
              placeholder="Unesi broj racuna"
              isTouched={touchedFields.bankNum}
              error={errors.bankNum}
              validationRules={register('bankNum', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="PIB"
              height='medium'
              placeholder="Unesi PIB"
              isTouched={touchedFields.pib}
              error={errors.pib}
              validationRules={register('pib', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='text'
              label="Adresa banke"
              height='medium'
              placeholder="Unesi adresu"
              validationRules={register('bankAddress')}
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='text'
              label="CIN"
              height='medium'
              placeholder="Unesi CIN"
              validationRules={register('cin')}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='text'
              label="Ime kontakta"
              height='medium'
              placeholder="Unesi ime kontakta"
              validationRules={register('contactName')}
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <SearchableDropdown
              label="Država"
              value={countryOptions.find((c: any) => c.key === getValues('country')) || ''}
              idField="key"
              valueToShow="name"
              height="medium"
              placeholder="Izaberi državu"
              options={countryOptions}
              onChange={(option: any) => setValue('country', option.key)}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='number'
              label="Limit za zaposlene po ceni"
              height='medium'
              placeholder="Unesi limit"
              isTouched={touchedFields.employeesPriceLimit}
              error={errors.employeesPriceLimit}
              validationRules={register('employeesPriceLimit', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
                min: {
                  value: 0,
                  message: 'Minimalan iznos je 0'
                }
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className='w-1/2'>
            <UploadImage
              label="Logo"
              placeholder="Otpremi fotografiju"
              imageToShow={logoImg || client?.picture}
              isDisabled={false}
              removeImageHandler={removeImageHandler}
              onUpload={(file: File) => setFileHandler(file)}
            />
          </div>
          <div className="w-1/2 h-48">
            <TextEditor
              label="Opis"
              value={client?.description || ''}
              onChange={(e: any) => setValue('description', e)}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <CheckBox
              title="Team Building"
              size="large"
              value={getValues('teamBuilding')}
              textClassNames="font-medium"
              onChange={(val: boolean) => setValue('teamBuilding', val)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-x-5 z-10 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
        <Button
          bg="white"
          className="w-full flex items-center justify-center gap-x-2"
          size="regular"
          onClick={closeForm}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-full gap-x-2 relative"
          size="regular"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          {type === 'add' ? 'Dodaj' : 'Ažuriraj'}
          {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
        </Button>
      </div>
    </div>
  );
};

export default AddEditClientForm;