import React from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);

interface PieChartProps {
  data: any;
  size: number;
}

const chartOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  cutoutPercentage: 70, // Adjust the inner radius to make it look like your example
  rotation: -75, // Rotate to start from the left
  radius: 115,
  circumference: 150, // Only show half of the doughnut
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: '#ffffff',
      font: {
        weight: 'bold',
        size: 14,
      },
      formatter: (value: any) => ``,
    },
    tooltip: {
      callbacks: {
        label: function(tooltipItem: any) {
          // Return the custom label text
          const label = tooltipItem.label || '';
          const value = tooltipItem.raw; // access raw value
          return `${label}: ${value}%`;
        }
      }
    },
  },
}

const PieHalfChart = ({ data, size }: PieChartProps) => {

  return (
    <div className="transform -translate-y-0">
      <Doughnut
        id="pie-half"
        data={data}
        width={320}
        height={150}
        options={chartOptions}
      />
    </div>
  );
}

export default PieHalfChart;