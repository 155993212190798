import React, {useEffect} from 'react';
import Benefits from 'modules/benefit/UI/Benefits';
import PurchaseFilters from 'modules/employee/UI/PurchaseFilters';
import Providers from 'modules/provider/UI/Providers';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {benefitActions, getBenefits} from 'modules/benefit/services/benefit.store';
import {providerActions} from 'modules/provider/services/provider.store';
import Cart from 'modules/cart/UI/Cart';
import PreviewEmployee from 'modules/employee/UI/PreviewEmployee';

interface PurchaseBenefitProps {
  employee: any;
}

const PurchaseBenefit = ({ employee }: PurchaseBenefitProps) => {

  const dispatch = useDispatch();
  const selectedProvider: any = useSelector((state: any) => state.providerStore.selectedProvider, shallowEqual);
  const cart: any = useSelector((state: any) => state.cartStore.cart, shallowEqual);

  const fetchBenefits = async (isFetchMore: boolean): Promise<void> => {
    try {
      if (selectedProvider) await dispatch(getBenefits(isFetchMore, selectedProvider.id, true));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  useEffect(() => {
    dispatch(benefitActions.setCount(0));
    dispatch(benefitActions.setNextPage(null));
    dispatch(benefitActions.resetBenefits());
    fetchBenefits(false);
  }, [selectedProvider]);

  useEffect(() => {
    return () => {
      dispatch(providerActions.setSelectedProvider(null));
    }
  }, []);

  console.log(selectedProvider, 'selectedProvider')

  return(
    <div className="p-5 overflow-hidden space-y-5">
      <Cart employee={employee} />
      {cart.length > 0 &&
        <div className="w-2/3 h-[calc(100vh-480px)] overflow-y-auto">
          <PreviewEmployee employee={employee}/>
        </div>
      }
      {cart.length === 0 &&
        <>
          <PurchaseFilters />
          <div className="flex items-start gap-x-20">
            <div className="w-1/2 h-[calc(100vh-120px)] pb-20 mt-5 px-4 overflow-y-scroll overflow-x-hidden">
              <Providers mode="purchase" />
            </div>
            <div className="w-1/2 h-[calc(100vh-120px)] pb-20 mt-5 px-4 overflow-y-scroll overflow-x-hidden">
              {selectedProvider && <Benefits mode="purchase" />}
              {!selectedProvider && <div className="flex items-center justify-center h-40">Izaberi provajdera</div>}
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default PurchaseBenefit