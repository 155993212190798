import React, {useEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import { Tooltip } from 'react-tooltip';
import CheckBox from 'components/Checkbox';
import  { formatDate } from 'libs/datetime';
import { ElementLoader } from 'components/Loaders';
import {useDispatch, useSelector} from 'react-redux';
import { modalActions } from 'store/modal/modal.store';
import Filters from 'modules/reimbursement/UI/Filters';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RejectPrompt from 'modules/reimbursement/UI/RejectPrompt';
import { getCategories } from 'modules/benefit/services/benefit.store';
import ApprovalConfirmDeny from 'modules/reimbursement/UI/ApprovalConfirmDeny';
import {
  acceptSingleReimbursement,
  declineSingleReimbursement,
  getReimbursements,
  reimbursementActions,
} from 'modules/reimbursement/services/reimbursement.store';
import {
  faHourglassHalf,
  faTimes,
  faCheck,
  faFile, faListCheck,
} from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import {monetaryFormatter} from "libs/monetary";
import http from "libs/http";

const thClassNames = 'h-12 pl-2 flex items-center';
const tdClassNames = 'h-20 pl-2 flex items-center';

const Reimbursements = () => {

  const dispatch: any = useDispatch();
  const [expanded, setExpanded] = useState<number | null>(null);
  const count: any = useSelector((state: any) => state.reimbursementStore.count);
  const nextPage: any = useSelector((state: any) => state.reimbursementStore.nextPage);
  const searchString: any = useSelector((state: any) => state.reimbursementStore.searchString);
  const status: any = useSelector((state: any) => state.reimbursementStore.status);
  const startDate: any = useSelector((state: any) => state.reimbursementStore.dateFrom);
  const endDate: any = useSelector((state: any) => state.reimbursementStore.dateTo);
  const user: any = useSelector((state: any) => state.authStore.user);
  const reimbursements: any[] = useSelector((state: any) => state.reimbursementStore.reimbursements);
  const categories: any[] = useSelector((state: any) => state.benefitStore.categories);
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableHeight, setTableHeight] = useState<number>(0);

  const tableRef = useRef(null);
  const countRef = useRef(count);
  const nextPageRef = useRef(nextPage);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === reimbursements.length;
  }, [count, reimbursements]);

  const resetReimbursements = async () => {
    await dispatch(reimbursementActions.setCount(0));
    await dispatch(reimbursementActions.resetReimbursement());
    await dispatch(reimbursementActions.setNextPage(null));
  }

  useEffect(() => {
    return () => {
      resetReimbursements();
    }
  }, []);

  const fetchMoreReimbursements = async (resetData: boolean = false): Promise<void> => {
    try {
      await dispatch(getReimbursements(resetData));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  useEffect(() => {
    resetReimbursements();
    fetchMoreReimbursements(true);
  }, [searchString, status, startDate, endDate]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreReimbursements(false);
      }
    };

    // @ts-ignore
    window.addEventListener('scroll', handleScroll);
    return () => {
      // @ts-ignore
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const searchStringHandler = (payload: string) => {
    dispatch(reimbursementActions.setSearchString(payload));
  };

  const dateFromHandler = (payload: string) => {
    const date: string = formatDate(payload, 'YYYY-MM-DD');
    dispatch(reimbursementActions.setDateFrom(date));
  };

  const dateToHandler = (payload: string) => {
    const date: string = formatDate(payload, 'YYYY-MM-DD');
    dispatch(reimbursementActions.setDateTo(date));
  };

  const statusHandler = (payload: string) => {
    dispatch(reimbursementActions.setStatus(payload));
  };

  const getTableHeight = () => {
    let height: number = 0;

    if (selectedIds.length > 0) {
      height = document.documentElement.clientHeight - 300;
    } else {
      height = document.documentElement.clientHeight - 265;
    }
    setTableHeight(height);
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      getTableHeight();
    })
  }, [document.documentElement.clientHeight]);

  useEffect(() => {
    getTableHeight();
  }, [reimbursements, selectedIds]);

  const toggleExpanded = (id: number) => {
    if (id === expanded) setExpanded(null);
    else setExpanded(id);
  }


  const getCategoryName = (categoryId: number) => {
    if (categoryId && categories && categories.length > 0) {
      const category = categories.find((c: any) => c.id === categoryId);
      if (category?.name.split('/')[1]) {
        return category?.name.split('/')[1];
      } else {
        return category?.name;
      }
    } else {
      return '';
    }
  }

  const selectedItemsHandler = (id: number) => {
    let selectedItems = [...selectedIds];

    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter((item: number) => item !== id);
    } else {
      selectedItems = [...selectedItems, id];
    }

    setSelectedIds(selectedItems);
  };


  const openApprovalPrompt = (e: any): void => {
    let modalContent: React.JSX.Element = (
      <ApprovalConfirmDeny
        onDeny={(comment: string) => declineMultipleReimbursement(comment, selectedIds)}
        onAccept={() => acceptMultipleReimbursement(selectedIds)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };

  const openDeclinePrompt = (e: any, reimbursement: any): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <RejectPrompt
        title="Napiši komentar"
        description="test"
        onConfirm={(comment: string) => declineReimbursementHandler(comment, reimbursement)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  }

  const declineReimbursementHandler = async (comment: string, reimbursement: any): Promise<any> => {
    const payload: any = {
      declineReason: comment,
      refundStatus: false,
      id: reimbursement.id
    }

    try {
      await dispatch(declineSingleReimbursement(payload));
      dispatch(modalActions.closeModal());
      if (selectedIds.length === 0) {
        await resetReimbursements();
        await fetchMoreReimbursements(true);
        setSelectedIds([]);
        toast.success(`Uspešno ste odbili ${reimbursement.refundType === 'transaction' ? 'transakciju' : 'refundaciju'}!`)
      }
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  }

  const declineMultipleReimbursement = async (comment: string, ids: number[]): Promise<any> => {
    ids.forEach((id: number) => {
      const reimbursement = reimbursements.find((r: any) => r.id === id);
      declineReimbursementHandler(comment, reimbursement);
    })
    // NOTE: put a timeout due to the fact that when there is no timeout, the backend returns non-updated data
    setTimeout(() => {
      resetReimbursements();
      fetchMoreReimbursements(true);
      setSelectedIds([]);
      toast.success(`Uspešno ste odbili zahteve!`)
    }, 300);

  };

  const acceptReimbursementHandler = async (e: any, reimbursement: any): Promise<any> => {
    if (e) e.stopPropagation();
    const payload: any = {
      declineReason: "",
      refundStatus: true,
      id: reimbursement.id
    }

    try {
      await dispatch(acceptSingleReimbursement(payload));
      if (selectedIds.length === 0) {
        await resetReimbursements();
        await fetchMoreReimbursements(true);
        setSelectedIds([]);
        dispatch(modalActions.closeModal());
        toast.success(`Uspešno ste odobrili ${reimbursement.refund_type === 'transaction' ? 'transakciju' : 'refundaciju'}!`)
      }
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  }
  const acceptMultipleReimbursement = async (ids: number[]): Promise<any> => {
    ids.forEach((id: number) => {
      const reimbursement = reimbursements.find((r: any) => r.id === id);
      acceptReimbursementHandler(null, reimbursement);
    })
    // NOTE: put a timeout due to the fact that when there is no timeout, the backend returns non-updated data
    setTimeout(() => {
      resetReimbursements();
      fetchMoreReimbursements(true);
      setSelectedIds([]);
      toast.success(`Uspešno ste odobrili zahteve!`);
      dispatch(modalActions.closeModal());
    }, 300);
  };

  useEffect(() => {
    // dispatch(getCategories());
  }, []);

  const debugBase64 = (base64URL: any) => {
    var win = window.open();
    // @ts-ignore
    win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  const statusUrlHandler = (status: any) => {
    switch (status) {
      case true:
        return `&status=True`;
      case false:
        return '&status=False';
      case null:
        return '&status=null';
      default:
        return '';
    }
  }

  const exportHandler = async () => {
    try {
      let apiUrl = `/v2/admin/refunds/export?search=${searchString}`
      if (startDate) apiUrl += `&created_from=${startDate}`;
      if (endDate) apiUrl += `&created_to=${endDate}`;
      if (status === true || status === false || status === null) apiUrl += statusUrlHandler(status);

      const response = await http.get(apiUrl, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'refunds.xlsx';

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (e) {
      toast.error('Nešto nije u redu!');
    }
  };

  const checkAllUnresolvedRefunds = (value: boolean) => {
    let selected: any = [];

    if (value) {
      selected = [];
      reimbursements.forEach((r: any) => {
        if (r.refundStatus === null) selected.push(r.id);
      })
      setSelectedIds(selected);
    } else {
      selected = [];
      setSelectedIds([]);
    }
  };

  const checkIsSelected = (id: any) => {
    return selectedIds.includes(id);
  };


  const getStatusString = (status: boolean | null): string => {
    switch (status) {
      case true:
        return 'Prihvaćeno';
      case false:
        return 'Odbijeno'
      case null:
        return 'Na čekanju'
    }
  }

  const getStatusIcon = (status: boolean | null) => {
    const wrapperClass: string = 'flex items-center justify-center rounded-full h-8 w-8 text-white text-xl';

    switch (status) {
      case true:
        return (
          <span
            data-tooltip-id="status"
            data-tooltip-content={getStatusString(status)}
            className={`${wrapperClass} bg-green-600`}
          >
            <FontAwesomeIcon icon={faCheck}/>
          </span>
        );
      case false:
        return (
          <span
            data-tooltip-id="status"
            data-tooltip-content={getStatusString(status)}
            className={`${wrapperClass} bg-red-600`}
          >
            <FontAwesomeIcon icon={faTimes}/>
          </span>
        );
      case null:
        return (
          <span
            data-tooltip-id="status"
            data-tooltip-content={getStatusString(status)}
            className={`${wrapperClass} bg-yellow-500`}
          >
            <FontAwesomeIcon icon={faHourglassHalf}/>
          </span>
        );
    }

    return (
      <span className={`${wrapperClass} bg-yellow-500`}>
        <FontAwesomeIcon icon={faHourglassHalf}/>
      </span>
    );
  }

  return (
    <>
      <Filters
        onExport={() => exportHandler()}
        onSearch={(str: string) => searchStringHandler(str)}
        onStatusChange={(status: any) => statusHandler(status.value)}
        onEndDateChange={(date: any) => dateToHandler(date)}
        onStartDateChange={(date: any) => dateFromHandler(date)}
      />

      {selectedIds.length > 0 &&
        <div>
          <Button
            bg="primary"
            className="w-2/12 mt-6"
            size="regular"
            onClick={(e: any) => openApprovalPrompt(e)}
          >
            Razreši selektovane
          </Button>
        </div>
      }

      <section id="table" className={`${loading && 'min-h-[70vh]'} relative w-full ${selectedIds.length > 0 ? 'max-h-[calc(100vh-280px)] mt-3' : 'h-[calc(100vh-250px)] mt-7'}  overflow-y-auto rounded-lg overflow-hidden border`}>
        {tableHeight > 0 && reimbursements.length > 0 && <InfiniteScroll
          dataLength={reimbursements.length}
          next={fetchMoreReimbursements}
          height={tableHeight}
          hasMore={count !== reimbursements.length}
          loader={""}
          // scrollThreshold={1}
        >
          <div
            className="sticky top-0 w-full h-12 rounded-t-lg bg-base-600 text-white text-sm font-bold flex divide-x-2 divide-white items-center">
            <div className={`w-1/12 font-medium ${thClassNames} flex items-center justify-between`}>
              <p>ID</p>
              {selectedIds.length > 0 &&
                <div className="w-1/3 h-full flex items-center justify-center">
                  <CheckBox
                    size="small"
                    value={reimbursements.filter((r: any) => r.refundStatus === null).length === selectedIds.length}
                    wrapperClassNames="flex items-center justify-center transform -translate-x-12"
                    onChange={checkAllUnresolvedRefunds}
                  />
                </div>
              }
            </div>
            {/*<div className={`w-1/12 ${thClassNames}`}>ID</div>*/}
            <div className={`w-1/12 ${thClassNames}`}>Datum</div>
            <div className={`w-1/12 ${thClassNames}`}>Tip</div>
            <div className={`w-3/12 ${thClassNames}`}>Korisnik</div>
            <div className={`w-2/12 ${thClassNames}`}>Kategorija</div>
            <div className={`w-1/12 ${thClassNames} justify-center pl-0`}>Račun</div>
            <div className={`w-2/12 ${thClassNames}`}>Iznos refundacije</div>
            <div className={`w-1/12 ${thClassNames} justify-center !pl-0`}>Status</div>
          </div>
          {reimbursements.length > 0 && reimbursements.map((r: any) =>
            <div key={r.id}
                 className={`${r.id === expanded && 'border border-base-600 border-box transition duration-500 ease-in-out'} hover:bg-gray-50`}>
              <div onClick={() => toggleExpanded(r.id)}
                   className="w-full h-20 bg-primary-50 border-t-2 border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center cursor-pointer">
                <div className={`w-1/12 font-medium flex items-center justify-between px-2`}>
                  <p>{r.id}</p>
                  {r.refundStatus === null &&
                    <div className="w-1/3 h-full flex items-center justify-center">
                      <CheckBox
                        size="small"
                        value={() => checkIsSelected(r.id)}
                        wrapperClassNames="flex items-center justify-center"
                        onChange={(checked: boolean) => selectedItemsHandler(r.id)}
                      />
                    </div>
                  }
                </div>
                <div className={`w-1/12 ${tdClassNames}`}>{ formatDate(r.createdAt, 'DD.MM.YYYY.') }</div>
                <div className={`w-1/12 ${tdClassNames} tracking-tight`}>{ r.refundType === 'reimbursement' ? 'Refundacija' : 'Kupovina'  }</div>
                <div className={`w-3/12 ${tdClassNames} font-medium`}>{ r.user.firstName } { r.user.lastName }</div>
                <div className={`w-2/12 ${tdClassNames}`}>{ getCategoryName(r.category) }</div>
                <div onClick={(e: any) => e.stopPropagation()} className={`w-1/12 ${tdClassNames} flex items-center justify-center pl-0`}>
                  <p onClick={() => debugBase64(r.fileUrl)}>
                    <Tooltip id="download" className="z-10" />
                    <FontAwesomeIcon
                      icon={faFile}
                      data-tooltip-id="download"
                      data-tooltip-content="Klikni da otvoriš"
                      className="hover:text-base-500 animation duration-200 ease-in-out text-2xl"
                    />
                  </p>
                </div>
                <div className={`w-2/12 ${tdClassNames} font-medium`}>{ monetaryFormatter(Number(r.reimbursementAmount)) } {user.currency}</div>
                <div className={`w-1/12 flex items-center justify-center ${tdClassNames} !pl-0`}>
                  <Tooltip id="status" className="z-10" />
                  {getStatusIcon(r.refundStatus)}
                </div>
              </div>
              <div>
                <div className={`collapsible-content ${r.id === expanded && 'open'} w-full bg-primary-50 border-t-2 border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center`}>
                  <div className={`w-1/12 font-medium ${tdClassNames}`}>
                    <div className="space-y-1">
                      <p className="font-medium">User ID</p>
                      <p className="text-base-600 font-medium">{r.user.id}</p>
                    </div>
                  </div>
                  <div className={`w-2/12 ${tdClassNames}`}>
                    <div className="space-y-1">
                      <p className="font-medium">Naziv kompanije</p>
                      <p className="text-base-600 font-medium">{ user.company.name }</p>
                    </div>
                  </div>
                  {/*<div className={`w-1/12 ${tdClassNames}`}>*/}
                    {/*<div className="space-y-1">*/}
                    {/*  <p className="font-medium">User ID</p>*/}
                    {/*  <p className="text-base-600 font-medium">{r.user.id}</p>*/}
                    {/*</div>*/}
                  {/*</div>*/}
                  <div className={`w-3/12 ${tdClassNames}`}>
                    <div className="space-y-1 truncate">
                      <p className="font-medium">Mejl</p>
                      <Tooltip id="email" />
                      <p
                        data-tooltip-id="email"
                        data-tooltip-content={ r.user.email ? r.user.email : '/' }
                        className="text-base-600 font-medium truncate">
                        { r.user.email ? r.user.email : '/' }
                      </p>
                    </div>
                  </div>
                  <div className={`w-3/12 ${tdClassNames}`}>
                    <div className="space-y-1 truncate">
                      <p className="font-medium">Komentar</p>
                      <Tooltip id="comment" />
                      <p
                        data-tooltip-id="comment"
                        data-tooltip-content={r.declineReason}
                        className="text-base-600 font-medium truncate">
                        { r.declineReason ? r.declineReason : '/'}
                      </p>
                    </div>
                  </div>
                  <div className={`w-3/12 ${tdClassNames}`}>
                    {selectedIds.length === 0 &&
                      <div className="w-full flex items-center justify-around gap-x-3 pr-2">
                        <div className="w-1/2">
                          <Button
                            bg="warning"
                            className="w-full"
                            disabled={r.refundStatus !== null}
                            size="small"
                            onClick={(e: any) => openDeclinePrompt(e, r)}
                          >
                            Odbij
                          </Button>
                        </div>
                        <div className="w-1/2">
                          <Button
                            bg="success"
                            className="w-full"
                            disabled={r.refundStatus !== null}
                            size="small"
                            onClick={(e: any) => acceptReimbursementHandler(e, r)}
                          >
                            Prihvati
                          </Button>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="h-12 w-full"></div>
        </InfiniteScroll>}
        {loading && <ElementLoader size="regular" color="primary" classNames="flex items-center justify-center backdrop-blur-[2px] rounded-lg" />}
        {!loading && reimbursements.length === 0 &&
          <div className="h-[65vh] flex items-center justify-center text-gray-600 text-sm font-medium">
            <div className="text-center space-y-4">
              <FontAwesomeIcon icon={faListCheck} className="text-4xl"/>
              <p className="font-medium">Nema pronađenih refundacija!</p>
            </div>
          </div>
        }

        {/*{filteredReimbursements.length === 0 && <div className="h-[55vh] flex items-center justify-center text-sm font-medium text-gray-600">Nema pronađenih refundacija.</div>}*/}
      </section>
    </>
  );
};

export default Reimbursements;





// import React, {useEffect, useRef, useState} from 'react';
// import { toast } from 'react-toastify';
// import Button from 'components/Button';
// import { Tooltip } from 'react-tooltip';
// import CheckBox from 'components/Checkbox';
// import  { formatDate } from 'libs/datetime';
// import { ElementLoader } from 'components/Loaders';
// import {useDispatch, useSelector} from 'react-redux';
// import { modalActions } from 'store/modal/modal.store';
// import Filters from 'modules/reimbursement/UI/Filters';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import RejectPrompt from 'modules/reimbursement/UI/RejectPrompt';
// import { getCategories } from 'modules/benefit/services/benefit.store';
// import ApprovalConfirmDeny from 'modules/reimbursement/UI/ApprovalConfirmDeny';
// import {
//   acceptSingleReimbursement,
//   declineSingleReimbursement,
//   getReimbursements,
//   reimbursementActions,
// } from 'modules/reimbursement/services/reimbursement.store';
// import {
//   faHourglassHalf,
//   faTimes,
//   faCheck,
//   faFile, faListCheck,
// } from '@fortawesome/free-solid-svg-icons';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import {monetaryFormatter} from "libs/monetary";
// import http from "libs/http";
//
// const thClassNames = 'h-12 pl-2 flex items-center';
// const tdClassNames = 'h-20 pl-2 flex items-center';
//
// const Reimbursements = () => {
//
//   const dispatch: any = useDispatch();
//   const [expanded, setExpanded] = useState<number | null>(null);
//   const count: any = useSelector((state: any) => state.reimbursementStore.count);
//   const nextPage: any = useSelector((state: any) => state.reimbursementStore.nextPage);
//   const searchString: any = useSelector((state: any) => state.reimbursementStore.searchString);
//   const status: any = useSelector((state: any) => state.reimbursementStore.status);
//   const startDate: any = useSelector((state: any) => state.reimbursementStore.dateFrom);
//   const endDate: any = useSelector((state: any) => state.reimbursementStore.dateTo);
//   const user: any = useSelector((state: any) => state.authStore.user);
//   const reimbursements: any[] = useSelector((state: any) => state.reimbursementStore.reimbursements);
//   const categories: any[] = useSelector((state: any) => state.benefitStore.categories);
//   const [selectedIds, setSelectedIds] = useState<any>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [tableHeight, setTableHeight] = useState<number>(0);
//
//   const countRef = useRef(count);
//   const nextPageRef = useRef(nextPage);
//
//   useEffect(() => {
//     nextPageRef.current = nextPage;
//   }, [nextPage]);
//
//   useEffect(() => {
//     countRef.current = count === reimbursements.length;
//   }, [count, reimbursements]);
//
//   const resetReimbursements = async () => {
//     await dispatch(reimbursementActions.setCount(0));
//     await dispatch(reimbursementActions.resetReimbursement());
//     await dispatch(reimbursementActions.setNextPage(null));
//   }
//
//   useEffect(() => {
//     return () => {
//       resetReimbursements();
//     }
//   }, []);
//
//   const fetchMoreReimbursements = async (resetData: boolean = false): Promise<void> => {
//     try {
//       await dispatch(getReimbursements(resetData));
//     } catch (e: any) {
//       console.log(e, 'Err');
//     }
//   };
//
//   useEffect(() => {
//     resetReimbursements();
//     fetchMoreReimbursements(true);
//   }, [searchString, status, startDate, endDate]);
//
//   useEffect(() => {
//     const handleScroll = () => {
//       let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
//       if (bottomOfWindow && nextPageRef.current && !countRef.current) {
//         fetchMoreReimbursements(false);
//       }
//     };
//
//     // @ts-ignore
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       // @ts-ignore
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//
//   const searchStringHandler = (payload: string) => {
//     dispatch(reimbursementActions.setSearchString(payload));
//   };
//
//   const dateFromHandler = (payload: string) => {
//     const date: string = formatDate(payload, 'YYYY-MM-DD');
//     dispatch(reimbursementActions.setDateFrom(date));
//   };
//
//   const dateToHandler = (payload: string) => {
//     const date: string = formatDate(payload, 'YYYY-MM-DD');
//     dispatch(reimbursementActions.setDateTo(date));
//   };
//
//   const statusHandler = (payload: string) => {
//     dispatch(reimbursementActions.setStatus(payload));
//   };
//
//   const getTableHeight = () => {
//     let height: number = 0;
//
//     if (selectedIds.length > 0) {
//       height = document.documentElement.clientHeight - 300;
//     } else {
//       height = document.documentElement.clientHeight - 265;
//     }
//     setTableHeight(height);
//   }
//
//   useEffect(() => {
//     window.addEventListener('resize', () => {
//       getTableHeight();
//     })
//   }, [document.documentElement.clientHeight]);
//
//   useEffect(() => {
//     getTableHeight();
//   }, [reimbursements, selectedIds]);
//
//   const toggleExpanded = (id: number) => {
//     if (id === expanded) setExpanded(null);
//     else setExpanded(id);
//   }
//
//
//   const getCategoryName = (categoryId: number) => {
//     if (categoryId && categories && categories.length > 0) {
//       const category = categories.find((c: any) => c.id === categoryId);
//       if (category?.name.split('/')[1]) {
//         return category?.name.split('/')[1];
//       } else {
//         return category?.name;
//       }
//     } else {
//       return '';
//     }
//   }
//
//   const selectedItemsHandler = (id: number) => {
//     let selectedItems = [...selectedIds];
//
//     if (selectedItems.includes(id)) {
//       selectedItems = selectedItems.filter((item: number) => item !== id);
//     } else {
//       selectedItems = [...selectedItems, id];
//     }
//
//     setSelectedIds(selectedItems);
//   };
//
//
//   const openApprovalPrompt = (e: any): void => {
//     let modalContent: React.JSX.Element = (
//       <ApprovalConfirmDeny
//         onDeny={(comment: string) => declineMultipleReimbursement(comment, selectedIds)}
//         onAccept={() => acceptMultipleReimbursement(selectedIds)}
//       />
//     );
//
//     dispatch(
//       modalActions.openModal({
//         modalContent,
//         modalProps: {
//           closable: false,
//           size: 'small',
//         },
//       })
//     );
//   };
//
//   const openDeclinePrompt = (e: any, reimbursement: any): void => {
//     e.stopPropagation();
//     let modalContent: React.JSX.Element = (
//       <RejectPrompt
//         title="Napiši komentar"
//         description="test"
//         onConfirm={(comment: string) => declineReimbursementHandler(comment, reimbursement)}
//       />
//     );
//
//     dispatch(
//       modalActions.openModal({
//         modalContent,
//         modalProps: {
//           closable: false,
//           size: 'small',
//         },
//       })
//     );
//   }
//
//   const declineReimbursementHandler = async (comment: string, reimbursement: any): Promise<any> => {
//     const payload: any = {
//       declineReason: comment,
//       refundStatus: false,
//       id: reimbursement.id
//     }
//
//     try {
//       await dispatch(declineSingleReimbursement(payload));
//       dispatch(modalActions.closeModal());
//       if (selectedIds.length === 0) {
//         await resetReimbursements();
//         await fetchMoreReimbursements(true);
//         setSelectedIds([]);
//         toast.success(`Uspešno ste odbili ${reimbursement.refundType === 'transaction' ? 'transakciju' : 'refundaciju'}!`)
//       }
//     } catch (e: any) {
//       toast.error('Nešto nije u redu!');
//     }
//   }
//
//   const declineMultipleReimbursement = async (comment: string, ids: number[]): Promise<any> => {
//     ids.forEach((id: number) => {
//       const reimbursement = reimbursements.find((r: any) => r.id === id);
//       declineReimbursementHandler(comment, reimbursement);
//     })
//     // NOTE: put a timeout due to the fact that when there is no timeout, the backend returns non-updated data
//     setTimeout(() => {
//       resetReimbursements();
//       fetchMoreReimbursements(true);
//       setSelectedIds([]);
//       toast.success(`Uspešno ste odbili zahteve!`)
//     }, 300);
//
//   };
//
//   const acceptReimbursementHandler = async (e: any, reimbursement: any): Promise<any> => {
//     if (e) e.stopPropagation();
//     const payload: any = {
//       declineReason: "",
//       refundStatus: true,
//       id: reimbursement.id
//     }
//
//     try {
//       await dispatch(acceptSingleReimbursement(payload));
//       if (selectedIds.length === 0) {
//         await resetReimbursements();
//         await fetchMoreReimbursements(true);
//         setSelectedIds([]);
//         dispatch(modalActions.closeModal());
//         toast.success(`Uspešno ste odobrili ${reimbursement.refund_type === 'transaction' ? 'transakciju' : 'refundaciju'}!`)
//       }
//     } catch (e: any) {
//       toast.error('Nešto nije u redu!');
//     }
//   }
//   const acceptMultipleReimbursement = async (ids: number[]): Promise<any> => {
//     ids.forEach((id: number) => {
//       const reimbursement = reimbursements.find((r: any) => r.id === id);
//       acceptReimbursementHandler(null, reimbursement);
//     })
//     // NOTE: put a timeout due to the fact that when there is no timeout, the backend returns non-updated data
//     setTimeout(() => {
//       resetReimbursements();
//       fetchMoreReimbursements(true);
//       setSelectedIds([]);
//       toast.success(`Uspešno ste odobrili zahteve!`);
//       dispatch(modalActions.closeModal());
//     }, 300);
//   };
//
//   useEffect(() => {
//     dispatch(getCategories());
//   }, []);
//
//   const debugBase64 = (base64URL: any) => {
//     var win = window.open();
//     // @ts-ignore
//     win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
//   }
//
//   const statusUrlHandler = (status: any) => {
//     switch (status) {
//       case true:
//         return `&status=True`;
//       case false:
//         return '&status=False';
//       case null:
//         return '&status=null';
//       default:
//         return '';
//     }
//   }
//
//   const exportHandler = async () => {
//     try {
//       let apiUrl = `/v2/admin/refunds/export?search=${searchString}`
//       if (startDate) apiUrl += `&created_from=${startDate}`;
//       if (endDate) apiUrl += `&created_to=${endDate}`;
//       if (status === true || status === false || status === null) apiUrl += statusUrlHandler(status);
//
//       const response = await http.get(apiUrl, {
//         responseType: 'blob'
//       });
//
//       const blob = new Blob([response.data], { type: response.headers['content-type'] });
//       const url = window.URL.createObjectURL(blob);
//
//       const link = document.createElement('a');
//       link.href = url;
//
//       const contentDisposition = response.headers['content-disposition'];
//       let fileName = 'refunds.xlsx';
//
//       if (contentDisposition) {
//         const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
//         if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
//       }
//
//       link.setAttribute('download', fileName);
//
//       document.body.appendChild(link);
//       link.click();
//
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(link);
//     } catch (e) {
//       toast.error('Nešto nije u redu!');
//     }
//   };
//
//   const checkAllUnresolvedRefunds = (value: boolean) => {
//     let selected: any = [];
//
//     if (value) {
//       selected = [];
//       reimbursements.forEach((r: any) => {
//         if (r.refundStatus === null) selected.push(r.id);
//       })
//       setSelectedIds(selected);
//     } else {
//       selected = [];
//       setSelectedIds([]);
//     }
//   };
//
//   const checkIsSelected = (id: any) => {
//     return selectedIds.includes(id);
//   };
//
//
//   const getStatusString = (status: boolean | null): string => {
//     switch (status) {
//       case true:
//         return 'Prihvaćeno';
//       case false:
//         return 'Odbijeno'
//       case null:
//         return 'Na čekanju'
//     }
//   }
//
//   const getStatusIcon = (status: boolean | null) => {
//     const wrapperClass: string = 'flex items-center justify-center rounded-full h-8 w-8 text-white text-xl';
//
//     switch (status) {
//       case true:
//         return (
//           <span
//             data-tooltip-id="status"
//             data-tooltip-content={getStatusString(status)}
//             className={`${wrapperClass} bg-green-600`}
//           >
//             <FontAwesomeIcon icon={faCheck}/>
//           </span>
//         );
//       case false:
//         return (
//           <span
//             data-tooltip-id="status"
//             data-tooltip-content={getStatusString(status)}
//             className={`${wrapperClass} bg-red-600`}
//           >
//             <FontAwesomeIcon icon={faTimes}/>
//           </span>
//         );
//       case null:
//         return (
//           <span
//             data-tooltip-id="status"
//             data-tooltip-content={getStatusString(status)}
//             className={`${wrapperClass} bg-yellow-500`}
//           >
//             <FontAwesomeIcon icon={faHourglassHalf}/>
//           </span>
//         );
//     }
//
//     return (
//       <span className={`${wrapperClass} bg-yellow-500`}>
//         <FontAwesomeIcon icon={faHourglassHalf}/>
//       </span>
//     );
//   }
//
//   return (
//     <>
//       <Filters
//         onExport={() => exportHandler()}
//         onSearch={(str: string) => searchStringHandler(str)}
//         onStatusChange={(status: any) => statusHandler(status.value)}
//         onEndDateChange={(date: any) => dateToHandler(date)}
//         onStartDateChange={(date: any) => dateFromHandler(date)}
//       />
//
//       {selectedIds.length > 0 &&
//         <div>
//           <Button
//             bg="primary"
//             className="w-2/12 mt-6"
//             size="regular"
//             onClick={(e: any) => openApprovalPrompt(e)}
//           >
//             Razreši selektovane
//           </Button>
//         </div>
//       }
//
//       <section id="table" className={`${loading && 'min-h-[70vh]'} relative w-full ${selectedIds.length > 0 ? 'max-h-[calc(100vh-280px)] mt-3' : 'h-[calc(100vh-250px)] mt-7'}  overflow-y-auto rounded-lg overflow-hidden border`}>
//         {tableHeight > 0 && reimbursements.length > 0 && <InfiniteScroll
//             dataLength={reimbursements.length}
//             next={fetchMoreReimbursements}
//             height={tableHeight}
//             hasMore={count !== reimbursements.length}
//             loader={""}
//             scrollThreshold={1}
//         >
//             <div
//                 className="sticky top-0 z-10 w-full h-12 rounded-t-lg bg-base-600 text-white text-sm font-bold flex divide-x-2 divide-white items-center">
//                 <div className={`w-1/12 font-medium ${thClassNames} flex items-center justify-between`}>
//                     <p>ID</p>
//                   {selectedIds.length > 0 &&
//                       <div className="w-1/3 h-full flex items-center justify-center">
//                           <CheckBox
//                               size="small"
//                               value={reimbursements.filter((r: any) => r.refundStatus === null).length === selectedIds.length}
//                               wrapperClassNames="flex items-center justify-center transform -translate-x-12"
//                               onChange={checkAllUnresolvedRefunds}
//                           />
//                       </div>
//                   }
//                 </div>
//                 {/*<div className={`w-1/12 ${thClassNames}`}>ID</div>*/}
//                 <div className={`w-2/12 ${thClassNames}`}>Datum</div>
//                 <div className={`w-1/12 ${thClassNames}`}>Komp. ID</div>
//                 <div className={`w-2/12 ${thClassNames}`}>Zaposleni</div>
//                 <div className={`w-2/12 ${thClassNames}`}>Kategorija</div>
//                 <div className={`w-1/12 ${thClassNames} justify-center pl-0`}>Račun</div>
//                 <div className={`w-2/12 ${thClassNames}`}>Iznos refundacije</div>
//                 <div className={`w-1/12 ${thClassNames} justify-center !pl-0`}>Status</div>
//             </div>
//           {reimbursements.length > 0 && reimbursements.map((r: any) =>
//             <div key={r.id}
//                  className={`${r.id === expanded && 'border border-base-600 border-box transition duration-500 ease-in-out'} hover:bg-gray-50`}>
//               <div onClick={() => toggleExpanded(r.id)}
//                    className="w-full h-20 bg-primary-50 border-t-2 border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center cursor-pointer">
//                 <div className={`w-1/12 font-medium ${tdClassNames} flex items-center justify-between`}>
//                   <p>{r.id}</p>
//                   {r.refundStatus === null &&
//                       <div className="w-1/3 h-full flex items-center justify-center">
//                     <CheckBox
//                       size="small"
//                       value={() => checkIsSelected(r.id)}
//                       wrapperClassNames="flex items-center justify-center transform -translate-x-12"
//                       onChange={(checked: boolean) => selectedItemsHandler(r.id)}
//                     />
//                   </div>
//                 }
//               </div>
//               <div className={`w-2/12 ${tdClassNames}`}>{ formatDate(r.createdAt, 'DD.MM.YYYY.') }</div>
//               <div className={`w-1/12 ${tdClassNames}`}>{ r.idCompany }</div>
//               <div className={`w-2/12 ${tdClassNames} font-medium`}>{ r.user.firstName } { r.user.lastName }</div>
//               <div className={`w-2/12 ${tdClassNames}`}>{ getCategoryName(r.category) }</div>
//               <div onClick={(e: any) => e.stopPropagation()} className={`w-1/12 ${tdClassNames} flex items-center justify-center pl-0`}>
//                 <p onClick={() => debugBase64(r.fileUrl)}>
//                   <Tooltip id="download" className="z-10" />
//                   <FontAwesomeIcon
//                     icon={faFile}
//                     data-tooltip-id="download"
//                     data-tooltip-content="Klikni da otvoriš"
//                     className="hover:text-base-500 animation duration-200 ease-in-out text-2xl"
//                   />
//                 </p>
//               </div>
//               <div className={`w-2/12 ${tdClassNames} font-medium`}>{ monetaryFormatter(Number(r.reimbursementAmount)) } {user.currency}</div>
//               <div className={`w-1/12 flex items-center justify-center ${tdClassNames} !pl-0`}>
//                 <Tooltip id="status" className="z-10" />
//                 {getStatusIcon(r.refundStatus)}
//               </div>
//             </div>
//             <div>
//               <div className={`collapsible-content ${r.id === expanded && 'open'} w-full bg-primary-50 border-t-2 border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center`}>
//                 <div className={`w-1/12 font-medium ${tdClassNames}`}></div>
//                 <div className={`w-2/12 ${tdClassNames}`}>
//                   <div className="space-y-1">
//                     <p className="font-medium">Naziv kompanije</p>
//                     <p className="text-base-600 font-medium">{ user.company.name }</p>
//                   </div>
//                 </div>
//                 <div className={`w-1/12 ${tdClassNames}`}>
//                   <div className="space-y-1">
//                     <p className="font-medium">User ID</p>
//                     <p className="text-base-600 font-medium">{r.user.id}</p>
//                   </div>
//                 </div>
//                 <div className={`w-2/12 ${tdClassNames}`}>
//                   <div className="space-y-1 truncate">
//                     <p className="font-medium">Mejl</p>
//                     <Tooltip id="email" />
//                     <p
//                       data-tooltip-id="email"
//                       data-tooltip-content={ r.user.email ? r.user.email : '/' }
//                       className="text-base-600 font-medium truncate">
//                       { r.user.email ? r.user.email : '/' }
//                     </p>
//                   </div>
//                 </div>
//                 <div className={`w-3/12 ${tdClassNames}`}>
//                   <div className="space-y-1 truncate">
//                     <p className="font-medium">Komentar</p>
//                     <Tooltip id="comment" />
//                     <p
//                       data-tooltip-id="comment"
//                       data-tooltip-content={r.declineReason}
//                       className="text-base-600 font-medium truncate">
//                       { r.declineReason ? r.declineReason : '/'}
//                     </p>
//                   </div>
//                 </div>
//                 <div className={`w-3/12 ${tdClassNames}`}>
//                   {selectedIds.length === 0 &&
//                     <div className="w-full flex items-center justify-around gap-x-3 pr-2">
//                       <div className="w-1/2">
//                         <Button
//                           bg="warning"
//                           className="w-full"
//                           disabled={r.refundStatus !== null}
//                           size="small"
//                           onClick={(e: any) => openDeclinePrompt(e, r)}
//                         >
//                             Odbij
//                         </Button>
//                       </div>
//                       <div className="w-1/2">
//                         <Button
//                           bg="success"
//                           className="w-full"
//                           disabled={r.refundStatus !== null}
//                           size="small"
//                           onClick={(e: any) => acceptReimbursementHandler(e, r)}
//                         >
//                             Prihvati
//                         </Button>
//                       </div>
//                     </div>
//                   }
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//         </InfiniteScroll>}
//         {loading && <ElementLoader size="regular" color="primary" classNames="flex items-center justify-center backdrop-blur-[2px] rounded-lg" />}
//         {!loading && reimbursements.length === 0 &&
//           <div className="h-[65vh] flex items-center justify-center text-gray-600 text-sm font-medium">
//             <div className="text-center space-y-4">
//               <FontAwesomeIcon icon={faListCheck} className="text-4xl"/>
//               <p className="font-medium">Nema pronađenih refundacija!</p>
//             </div>
//           </div>
//         }
//
//         {/*{filteredReimbursements.length === 0 && <div className="h-[55vh] flex items-center justify-center text-sm font-medium text-gray-600">Nema pronađenih refundacija.</div>}*/}
//       </section>
//     </>
//   );
// };
//
// export default Reimbursements;