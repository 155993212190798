import http from 'libs/http';


class TransactionApi {
  getTransactions = (url: string) => {
    return http.get(url);
  };

  finishTransaction = (transactionId: number) => {
    return http.patch(`/v2/admin/transactions/${transactionId}/`, { finished: true });
  };

  cancelTransaction = (transactionId: number) => {
    return http.patch(`/v2/admin/transactions/${transactionId}/`, { canceled: true });
  };

}


export default TransactionApi;