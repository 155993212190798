import React, { useEffect, useState } from 'react';
import {faCircleXmark} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImage, faTrash} from '@fortawesome/free-solid-svg-icons';

interface UploadImageProps {
  label?: string;
  placeholder?: string;
  rounded?: boolean;
  imageToShow?: string | File | boolean,
  isDisabled?:boolean,
  onUpload: (payload: any) => void;
  removeImageHandler?: any,
}

const initialState = {
  name: '',
}
const UploadImage = ({ label, placeholder, onUpload, rounded, imageToShow, isDisabled, removeImageHandler  }: UploadImageProps) => {
  const [selectedFile, setSelectedFile] = useState<string | boolean | File | null | undefined>(imageToShow ? imageToShow : null);
  const [imageUrl, setImageUrl] = useState<any>(imageToShow ? imageToShow : '');

  useEffect(() => {
    setSelectedFile(imageToShow)
    setImageUrl(imageToShow)
}, [imageToShow])

  const handleFileChange = (e: any): void => {
      const file = e.target.files[0];
      setSelectedFile(file);
      if (file) {
        onUpload(file);
        getImageUrl(file);
      }
    
  };

  

  const getImageUrl = (file: File): void => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  }

  return (
    <div className='h-fit relative'>
    {!isDisabled && imageToShow &&  <p onClick={removeImageHandler} className='absolute top-0 right-0 z-10 cursor-pointer select-none  '>
        <span className='flex items-center justify-center h-6 w-6 bg-red-600 rounded-full hover:scale-110 transition-transform active:scale-100 select-none animation duration-200 ease-in-out'>
          <FontAwesomeIcon icon={faTrash} className="text-xs text-white" />
        </span>
        
      </p>}
      {label && <p className="text-gray-700 text-sm font-bold mb-2">{ label }</p>}
      <label htmlFor="file-upload" className={`relative group border bg-white shadow-lg  overflow-hidden  flex items-center  ${rounded ? "rounded-full w-32 h-32" : "rounded-md w-full min-h-36"} text-gray-400/90  text-sm ${!isDisabled && "hover:text-gray-300"}`}>
        <div className={`  top-0 left-0 ${rounded ? "rounded-full w-32 h-32 absolute" : "rounded-md w-full h-full absolute"}  bg-black/50 invisible ${isDisabled ? "" : "group-hover:visible cursor-pointer"} flex items-center justify-center`}>
          <div className="text-center space-y-2">
            <FontAwesomeIcon icon={faImage} className="text-2xl" />
            <p className="">Otpremi sliku</p>
          </div>
        </div>

        {selectedFile &&
          <div className="h-40">
            <img src={imageUrl} alt="Image" className="object-contain aspect-[2/1]" />
          </div>
        }

        {!selectedFile && placeholder &&
          <div className="h-40 w-full flex items-center justify-center">
            <div className="text-center space-y-3">
              <FontAwesomeIcon icon={faImage} className="text-2xl" />
              <p>Otpremi sliku</p>
            </div>
          </div>
        }
      </label>

      <input id="file-upload" type="file" disabled={isDisabled} className="hidden" onChange={handleFileChange}/>
    </div>
  );
};

export default UploadImage;