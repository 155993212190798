import { useEffect, useState } from 'react';


const useWidth = () => {
  const [width, setWidth] = useState<any>(window.innerWidth);


  const setSelectWidth = (e: any): void => {
    setWidth(e.target.innerWidth);
  }


  useEffect(() => {
    window.addEventListener('resize', setSelectWidth);


    return () => {
      window.removeEventListener('resize', setSelectWidth);
    }
  });


  return width;
};


export default useWidth;
