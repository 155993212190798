import React from 'react';
import Input from 'components/Input';
import {benefitActions} from 'modules/benefit/services/benefit.store';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {providerActions} from 'modules/provider/services/provider.store';

const PurchaseFilters = () => {

  const dispatch = useDispatch();

  const selectedProvider: any = useSelector((state: any) => state.providerStore.selectedProvider, shallowEqual);

  const setProviderHandler = (payload: string) => {
    dispatch(providerActions.resetProviders());
    dispatch(providerActions.setSearchString(payload));
  };

  const searchBenefitHandler = (payload: string): void => {
    dispatch(benefitActions.setSearchString(payload));
  };

  return(
    <div className="flex items-center gap-x-20 mt-8">
      <div className="lg:w-1/2 px-4">
        <Input
          type='text'
          isSearch
          height='regular'
          debounceTime={400}
          placeholder="Pronađi provajdera"
          onChange={setProviderHandler}
        />
      </div>
      <div className="lg:w-1/2 px-4">
        <Input
          type='text'
          isSearch
          disabled={!selectedProvider}
          height='regular'
          debounceTime={400}
          placeholder="Pronađi benefit"
          onChange={searchBenefitHandler}
        />
      </div>
    </div>
  );
};

export default PurchaseFilters;