import React, {useEffect, useRef, useState} from 'react';
import Input from 'components/Input';
import { Tooltip } from 'react-tooltip';
import TextEditor from 'components/TextEditor';
import SearchableDropdown from 'components/SearchableDropdown';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clientActions, getClients } from 'modules/client/services/client.store';
import { employeeActions, getEmployees } from 'modules/employee/services/employee.store';
import Button from 'components/Button';
import {faFile, faFloppyDisk, faImage, faPaperclip, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import http, {setHeaders} from 'libs/http';
import {
  deleteAttachment,
  sendNotification,
  uploadAttachments,
  uploadImage
} from 'modules/notification/services/notification.store';
import {toast} from 'react-toastify';
import {modalActions} from 'store/modal/modal.store';
import ConfirmMessagePrompt from 'modules/notification/UI/ConfirmMessagePrompt';
import ComboBox from 'components/ComboBox';

const countryOptions = [
  { label: 'SRB', name: 'Srbija', value: 'sER', key: 1 },
  { label: 'BIH', name: 'Bosna i Hercegovina', value: 'bIH', key: 2 },
  { label: 'MNE', name: 'Crna Gora', value: 'mNE', key: 3 },
]

const NewNotificationForm = () => {

  const dispatch: any = useDispatch();
  const [client, setClient] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [message, setMessage] = useState<any>('');
  const [images, setImages] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const importImageRef = useRef<any>(null);
  const importFileRef = useRef<any>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const clients: any = useSelector((state: any) => state.clientStore.clients, shallowEqual);
  const filters: any = useSelector((state: any) => state.clientStore.filters, shallowEqual);
  const employees: any = useSelector((state: any) => state.employeeStore.employees, shallowEqual);
  const employeeSearchString: any = useSelector((state: any) => state.employeeStore.searchString, shallowEqual);
  const [filteredOptions, setFilteredOptions] = useState(countryOptions);

  const searchClientsHandler = (payload: string) => {
    dispatch(clientActions.setSearchString(payload));
  }

  const setCountryHandler = (option: any): void => {
    if (!option) {
      setCountry('');
      dispatch(clientActions.setCountry(''));
    } else {
      setCountry(option)
      dispatch(clientActions.setCountry(option.key));
    }
  };

  const clientHandler = (client: any) => {
    if (!client) dispatch(employeeActions.resetEmployees());
    console.log('uslii')
    setClient(client);
  };

  const employeeHandler = (payload: any) => {
    if (payload === null) {
      setSelectedEmployees([]);
      return;
    }

    let selectedItems: any[] = [...selectedEmployees];

    if (selectedItems.some((e: any) => e.id === payload.id)) {
      selectedItems = selectedItems.filter((item: any) => item.id !== payload.id);
    } else {
      selectedItems = [...selectedItems, payload];
    }

    setSelectedEmployees(selectedItems);
  };

  const searchEmployeeHandler = async (payload: string) => {
    console.log('uslii', payload)
    dispatch(employeeActions.setSearchString(payload));
  };

  const getCountry = () => {
    const country: any = countryOptions.find((c: any) => c.key === filters.country);
    setCountry(country);
  };

  const messageHandler = (e: any): void => {
    setMessage(e);
  };

  const setImageHandler = async (e: any): Promise<any> => {
    const selectedImages = [];
    selectedImages.push({
      position: 0,
      image: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0]
    });
    setImages(selectedImages);
  };

  const uploadImageHandler = async (notificationId: number): Promise<any> => {
    for (const img of images) {
      let payload: FormData = new FormData();
      payload.append('image', img.file);
      payload.append('position', '0');
      payload.append('notification', String(notificationId));

      try {
        await setHeaders({ 'content-type': 'multipart/form-data' });
        await dispatch(uploadImage(payload));
        await setHeaders({ 'content-type': 'application/json' });
      } catch (e: any) {
        console.log(e, 'ERR');
      }
    }
  };

  const deleteImageHandler = async (image: any): Promise<any> => {
    const imgs = images.filter((e: any) => e.image !== image);
    setImages(imgs);
  };

  const openUploadImagePrompt = (): void => {
    importImageRef.current.click();
  };

  const openUploadPrompt = (): void => {
    importFileRef.current.click();
  };

  const uploadAttachmentHandler = async (e: any): Promise<any> => {
    let req = new FormData()
    req.append('title', 'attachment');
    req.append('file', e.target.files[0]);
    console.log(e.target.files[0], 'e.target.files[0]')
    try {
      setHeaders({ 'content-type': 'multipart/form-data' });
      const resp: any  = await dispatch(uploadAttachments(req));
      console.log(resp, 'resp');
      setHeaders({ 'content-type': 'application/json' });
      const files = [resp];
      setAttachments(files);
    } catch (e: any) {
      console.log(e, 'any');
      // toast.error(e);
    }
  };

  const deleteAttachmentHandler = async (id: any) => {
    try {
      // await http.delete(`v2/admin/promo/${id}/`);
      await dispatch(deleteAttachment(id));
      const files = attachments.filter((e: any) => e.id != id);
      setAttachments(files);
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };

  const sendMessageHandler = () => {
    let modalContent: React.JSX.Element = (
      <ConfirmMessagePrompt
        title="Pošalji poruku"
        description="Da li ste sigruni da želite da pošaljete poruku?"
        onConfirm={onSubmit}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          size: 'small',
        },
      })
    );
  }

  const onSubmit = async () => {
    const payload: any = {}

    if (client && client.id) payload.companyId = client.id;

    if (selectedEmployees.length > 0) {
      payload.userIds = selectedEmployees.map((e: any) => e.id);
    }

    if (message) payload.text = message;

    if (images.length > 0) {
        payload.image = images[0].image;
    }

    if (attachments.length > 0) {
      payload.attachments = attachments.map((a: any) => a.id).join(',');
    }

    try {
      await dispatch(sendNotification(payload));
      dispatch(modalActions.closeModal());
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }

    console.log(payload, 'PAYLOAD');
  };

  useEffect(() => {
    dispatch(clientActions.setCountry(''));
  }, []);

  useEffect(() => {
    if (country) getCountry();
  }, [filters.country]);

  useEffect(() => {
    dispatch(getClients(true));
    dispatch(employeeActions.resetEmployees());
  }, [filters]);

  useEffect(() => {
    if (client) dispatch(getEmployees(true, client.id));
  }, [employeeSearchString, client]);

  useEffect(() => {
    console.log(client, 'client')
    console.log(selectedEmployees, 'selectedEmployees')
  }, [selectedEmployees]);

  const filterOptions = (string: string) => {
    const opt: any = countryOptions.filter((option: any) => option.name.toLowerCase().includes(string.toLowerCase()));
    setFilteredOptions(opt);
  };


  return(
    <div className="flex items-start gap-x-5">
      <div className="w-1/3 space-y-8">
        <div>
          <ComboBox
            idField="value"
            valueToShow="name"
            options={countryOptions}
            selectedOptions={country}
            placeholder="Izaberi državu"
            onClear={() => setCountryHandler('')}
            onInputChange={(string: string) => filterOptions(string)}
            onSelect={(option: any) => setCountryHandler(option)}
          />
          {/*<SearchableDropdown*/}
          {/*  value={country}*/}
          {/*  idField="value"*/}
          {/*  valueToShow="name"*/}
          {/*  placeholder="Izaberi državu"*/}
          {/*  options={countryOptions}*/}
          {/*  onClear={() => setCountryHandler('')}*/}
          {/*  onChange={(option: any) => setCountryHandler(option.key)}*/}
          {/*/>*/}
        </div>
        <div>
          <ComboBox
            options={clients}
            valueToShow="name"
            idField="id"
            placeholder="Pretrazi klijente"
            selectedOptions={client}
            onClear={() => clientHandler('')}
            onSelect={(option: any) => clientHandler(option)}
            onInputChange={(payload: any) => searchClientsHandler(payload)}
          />
        </div>
        <div>
          <ComboBox
            options={employees}
            valueToShow="fullName"
            idField="id"
            placeholder="Pretraži zaposlene"
            showSelected={true}
            selectedOptions={selectedEmployees}
            onClear={() => employeeHandler(null)}
            onSelect={(option: any) => employeeHandler(option)}
            onInputChange={(payload: any) => searchEmployeeHandler(payload)}
          />
          {/*<div>*/}
          {/*  <SearchableDropdown*/}
          {/*    value={selectedEmployees}*/}
          {/*    idField="id"*/}
          {/*    valueToShow="fullName"*/}
          {/*    placeholder="Izaberi zaposlene"*/}
          {/*    multiselect={true}*/}
          {/*    options={employees}*/}
          {/*    onClear={() => employeeHandler('')}*/}
          {/*    onChange={(option: any) => employeeHandler(option)}*/}
          {/*    onInputChange={(payload: any) => searchEmployeeHandler(payload)}*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<div className="flex flex-wrap gap-1 mt-2">*/}
          {/*  {selectedEmployees.length > 0 && selectedEmployees.map((e: any, i: number) =>*/}
          {/*    i < 30 &&*/}
          {/*      (<span onClick={() => employeeHandler(e)} className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-green-50 hover:bg-red-50  text-green-700 hover:text-red-700 ring-green-600/20 hover:ring-red-600/20  px-1.5 hover:cursor-pointer py-0.5 text-xs font-medium  ring-1 ring-inset`}>*/}
          {/*        <span data-tooltip-id="remove" data-tooltip-content="Ukloni" data-tooltip-place="bottom">{e.fullName}</span>*/}
          {/*        <Tooltip id="remove" />*/}
          {/*      </span>)*/}
          {/*  )}*/}
          {/*  {selectedEmployees.length > 30 &&*/}
          {/*    <span className="mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-green-50   text-green-700  ring-green-600/20   px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset">*/}
          {/*      + {selectedEmployees.length - 30}*/}
          {/*    </span>*/}
          {/*  }*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="w-2/3">
        <div className="flex items-center gap-x-4">
          <div className="w-full">
            <Input
              type="text"
              height="regular"
              classNames="w-full"
              placeholder="Poruka od"
              onChange={() => null}
            />
          </div>
          <div className="w-12">
            <Button
              bg="primary"
              className="w-full"
              size="regular"
              onClick={() => null}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </Button>
          </div>
        </div>
        <div className="mt-5 ">
          <TextEditor
            value={message}
            placeholder="Unesi poruku"
            onChange={messageHandler}
          />
        </div>
        <div className="flex items-center gap-x-2">
          <div>
            <input onChange={setImageHandler} hidden type="file" ref={importImageRef}/>
            <Button
              bg="outline-primary"
              className="w-8 flex items-center justify-center gap-x-2"
              size="small"
              onClick={openUploadImagePrompt}
            >
              <FontAwesomeIcon icon={faImage}/>
            </Button>
          </div>
          {images.map((img: any) =>
            <div className="flex items-end gap-x-2">
              <img key={img.image} src={img.image} alt="" className="h-16 rounded-lg"/>
              {/*<div className="flex items-center">*/}
              {/*  <Button*/}
              {/*    bg="outline-warning"*/}
              {/*    className="w-8 mt-0.5 flex items-center justify-center gap-x-2"*/}
              {/*    size="small"*/}
              {/*    onClick={() => deleteImageHandler(img.image)}*/}
              {/*  >*/}
              {/*    <FontAwesomeIcon icon={faTrashCan}/>*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </div>
          )}
          <div className="ml-6">
            <input onChange={uploadAttachmentHandler} hidden type="file" ref={importFileRef}/>
            <Button
              bg="outline-primary"
              className="w-8 flex items-center justify-center gap-x-2"
              size="small"
              onClick={openUploadPrompt}
            >
              <FontAwesomeIcon icon={faPaperclip}/>
            </Button>
          </div>
          <div className="flex items-center gap-x-2">
            {attachments.map((attachment: any) =>
              <div className="flex items-center gap-x-1.5">
                <a target="_blank" href={attachment.file} className="group hover:text-base-600 truncate">
                  <FontAwesomeIcon icon={faFile} className="group-hover:text-base-600 text-4xl text-gray-500"/>
                  <span className="pl-2">{attachment.title}</span>
                </a>
                {/*<div className="flex items-center">*/}
                {/*  <Button*/}
                {/*    bg="outline-warning"*/}
                {/*    className="w-10 mt-0.5 flex items-center justify-center gap-x-2"*/}
                {/*    size="regular"*/}
                {/*    onClick={() => deleteAttachmentHandler(attachment.id)}*/}
                {/*  >*/}
                {/*    <FontAwesomeIcon icon={faTrashCan}/>*/}
                {/*  </Button>*/}
                {/*</div>*/}
              </div>
            )}
          </div>
          <div className="ml-auto w-48">
            <Button
              bg="primary"
              className="w-full"
              size="regular"
              onClick={() => sendMessageHandler()}
            >
              Pošalji
            </Button>
          </div>
        </div>
        {/*<div className="mt-3">*/}
        {/*  {images.map((img: any) =>*/}
        {/*    <div className="flex items-end gap-x-2">*/}
        {/*      <img key={img.image} src={img.image} alt="" className="h-16 rounded-lg"/>*/}
        {/*      <div className="flex items-center">*/}
        {/*        <Button*/}
        {/*          bg="outline-warning"*/}
        {/*          className="w-8 mt-0.5 flex items-center justify-center gap-x-2"*/}
        {/*          size="small"*/}
        {/*          onClick={() => deleteImageHandler(img.image)}*/}
        {/*        >*/}
        {/*          <FontAwesomeIcon icon={faTrashCan}/>*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default NewNotificationForm;