import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { modalActions } from 'store/modal/modal.store';
import {faShuffle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ConfirmDenyDialogProps {
  onAccept: () => void;
  onDeny: (comment: string) => void;
}

const ApprovalConfirmDeny = ({ onDeny, onAccept }: ConfirmDenyDialogProps) => {

  const dispatch: any = useDispatch();
  const [isDeny, setIsDeny] = useState(false);
  const [comment, setComment] = useState('');
  const [touched, setTouched] = useState(false);

  const handleClose = () => {
    // e.stopPropagation();
    dispatch(modalActions.closeModal());
  }

  const denyAll = () => {
    setIsDeny(true);
  }

  const commentHandler = (e: any) => {
    setComment(e.target.value);
    setTouched(true);
  }

  const onDenyHandler = (): void => {
    if (touched && comment) {
      onDeny(comment);
    }
    setTouched(true);
  }



  return (
    <div>
      <section className="py-5 text-gray-600 text-center flex items-center justify-center">
        <div>
          <div className="flex items-center justify-center">
            <FontAwesomeIcon icon={faShuffle} />
          </div>
          {!isDeny && <p className="text-lg font-bold mt-2">Prihvati/Odbij</p>}
          {isDeny && <p className="text-lg font-bold mt-2">Odbij sve</p>}
        </div>
      </section>
      {!isDeny &&
        <section className="text-center font-medium text-gray-800 border-y py-5">
          <p>Da li ste sigurni da želite prihvatiti/odbiti izabrane račune?</p>
        </section>
      }
      {isDeny &&
        <div className="relative px-16 mx-auto border-y py-7">
          <textarea
            name="comment"
            id="comment"
            cols={40}
            rows={3}
            onChange={commentHandler}
            placeholder="Unesi komentar . . ."
            className={`w-full border p-2 ${!comment && touched ? ' ring ring-red-600' : 'focus:ring focus:ring-base-500'} rounded-lg focus:outline-none`}>
          </textarea>
          {!comment && touched && <small className="absolute bottom-2.5 left-0 pl-16 text-red-600 font-medium">*Obavezno polje</small>}
        </div>
      }
      {!isDeny &&
        <section className="px-16 flex items-center justify-center gap-x-6 py-5">
          <Button
            bg="warning"
            className="w-1/2"
            size="regular"
            onClick={denyAll}
          >
            Odbij sve
          </Button>
          <Button
            bg="success"
            className="w-1/2"
            size="regular"
            onClick={onAccept}
          >
            Prihvati sve
          </Button>
        </section>
      }
      {isDeny &&
          <section className="px-16 flex items-center justify-center gap-x-6 py-5">
              <Button
                  bg="outline-primary"
                  className="w-1/2"
                  size="regular"
                  onClick={handleClose}
              >
                  Otkaži
              </Button>
              <Button
                  bg="primary"
                  className="w-1/2"
                  size="regular"
                  onClick={onDenyHandler}
              >
                  Potvrdi
              </Button>
          </section>
      }
    </div>
  );
};

export default ApprovalConfirmDeny;