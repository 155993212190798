import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ClientApi from './client.api';
import { loaderActions } from 'store/loader/loader.store';


const api: ClientApi = new ClientApi();

interface ProviderState {
  clients: any[];
  pagination: any;
  filters: any;
}

const initialState: ProviderState = {
  clients: [],
  pagination: {
    nextPage: null,
    count: -1,
  },
  filters: {
    searchString: '',
    country: 1,
    ordering: '-',
    previewType: 'grid',
  }
};

const clientSlice: any = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClients: (state: any, action: PayloadAction<any, any>): any => {
      const rawClients = [...state.clients, ...action.payload];
      const uniqueClientsMap = new Map();
      rawClients.forEach(client => {
        uniqueClientsMap.set(client.id, client);
      });
      state.clients = Array.from(uniqueClientsMap.values());
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.pagination.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.pagination.count = action.payload;
    },
    setSearchString: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.searchString = action.payload;
    },
    setCountry: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.country = action.payload;
    },
    setOrdering: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.ordering = action.payload;
    },
    setPreviewType: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.previewType = action.payload;
    },
    resetClients: (state: any): any => {
      state.clients = [];
    },
  },
});


export const getClients = (resetData: boolean) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const filters = await getState().clientStore.filters;
      const pagination = await getState().clientStore.pagination;

      let url: string = `/v2/admin/companies/?country=${filters.country}&search=${filters.searchString}`;

      if (resetData) dispatch(clientActions.resetClients())

      if (!resetData && pagination.nextPage) {
        url = pagination.nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getClients(url);
      dispatch(clientActions.setNextPage(data.next));
      dispatch(clientActions.setCount(data.count));
      dispatch(clientActions.setClients(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const addClient = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(false));
      const { data } = await api.addClient(payload);
      dispatch(clientActions.resetClients());
      dispatch(clientActions.setNextPage(0));
      dispatch(getClients(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const editClient = (clientId: number, payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.editClient(clientId, payload);
      dispatch(clientActions.resetClients());
      dispatch(clientActions.setNextPage(0));
      dispatch(getClients(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const deleteClient = (clientId: number) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.deleteClient(clientId);
      dispatch(clientActions.resetClients());
      dispatch(clientActions.setNextPage(0));
      dispatch(getClients(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const exportClients = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      // dispatch(loaderActions.setLoading(true));
      const { data } = await api.exportClients();
      // dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};




export const clientActions: any = clientSlice.actions;

export default clientSlice.reducer;
