import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCartShopping, faCopy,
  faEnvelope,
  faPencil,
  faPhone,
  faTrashCan,
  faUser,
  faUserXmark
} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "react-tooltip";
import {useSelector} from "react-redux";
import {monetaryFormatter} from "libs/monetary";

interface ListViewProps {
  benefits: any[];
  onCopy: (benefit: any) => void;
  onEdit: (benefit: any) => void;
  onDelete: (benefitId: any) => void;
  onPreview: (benefit: any) => void;
}

const ListView = ({ benefits, onCopy, onDelete, onEdit, onPreview }: ListViewProps) => {

  const stripHtmlTags = (input: string): string => {
    if (input) return input.replace(/<\/?[^>]+(>|$)/g, "");
    else return input;
  };

  const parseCategoryName = (name: any) => {
    if (name) return name.split('/')[1];
    return name;
  };

  const copyHandler = (e: any, benefit: any) => {
    e.stopPropagation();
    onCopy(benefit);
  };

  const editHandler = (e: any, benefit: any) => {
    e.stopPropagation();
    onEdit(benefit);
  };

  const deleteHandler = (e: any, benefitId: any) => {
    e.stopPropagation();
    onDelete(benefitId);
  };

  const previewHandler = (e: any, benefit: any) => {
    e.stopPropagation();
    onPreview(benefit);
  };

  return (
    <ul className="my-9 space-y-4">
      {benefits.length > 0 && benefits.map((benefit: any) =>
        <li
          onClick={(e: any) => previewHandler(e, benefit)}
          key={benefit.id}
          className="bg-white hover:bg-gray-50 flex items-center justify-between gap-x-5 shadow-sm rounded-lg px-5 h-24 transform hover:scale-[1.02] animation duration-200 ease-in-out">
          <div className="w-1/2">
            <div className="flex items-center gap-x-3 truncate">
              <div>
                {benefit.pictures && benefit.pictures.length > 0 ? (
                  <img
                    src={benefit.pictures[0].image}
                    alt="benefit"
                    className="w-28 h-16 rounded-lg border"
                  />
                ) : (
                  <div className="border bg-white w-28 h-16 rounded-lg flex items-center justify-center">
                    <img
                      src="/hob-logo.png"
                      alt="benefit"
                      className="w-24 rounded-lg transform scale-[0.85]"
                    />
                  </div>
                )}
              </div>
              <div>
                <p className="text-lg font-medium text-base-600 truncate">{benefit.name}</p>
                <p className="font-medium text-gray-500 truncate">{benefit.id}</p>
              </div>
            </div>
          </div>
          <div className="w-1/2 flex items-center justify-between">
            <div className="w-9/12 mr-5">
              {/*<div className="font-medium text-gray-500 text-ellipsis text-wrap text-sm truncate h-[74px] flex items-center overflow-hidden gap-x-2">*/}
              {/*  <p className="flex items-start max-h-[75px]">{stripHtmlTags(benefit.description)}</p>*/}
              {/*</div>*/}
              <div>
                <div className="flex items-center gap-x-5">
                  <p className="text-lg font-medium  text-base-600"><span
                    className="text-black">Cena:</span> {monetaryFormatter(+benefit.price)} {benefit.currency}</p>
                  <p className="text-lg font-medium  text-base-600"><span
                    className="text-black">Popust:</span> {benefit.discount}%</p>
                </div>
                <p className="text-medium font-medium text-gray-500">
                  <span>Kategorija:</span> {benefit.categoryComputed ? parseCategoryName(benefit.categoryComputed[0].name) : '/'}
                </p>
              </div>
            </div>
            <div className="w-32 flex items-center justify-end gap-x-2 ml-auto">
              <button
                onClick={(e: any) => editHandler(e, benefit)}
                data-tooltip-id="edit"
                data-tooltip-content="Izmeni"
                className="h-9 w-9 rounded-full flex items-center justify-center bg-base-600 hover:bg-base-500 text-white animation duration-200 ease-in-out">
                <Tooltip id="edit"/>
                <FontAwesomeIcon icon={faPencil}/>
              </button>
              <button
                onClick={(e: any) => copyHandler(e, benefit)}
                data-tooltip-id="copy"
                data-tooltip-content="Kopiraj"
                className="h-9 w-9 rounded-full flex items-center justify-center bg-orange-550 hover:bg-amber-400 text-white animation duration-200 ease-in-out">
                <Tooltip id="copy"/>
                <FontAwesomeIcon icon={faCopy}/>
              </button>
              <button
                onClick={(e: any) => deleteHandler(e, benefit.id)}
                data-tooltip-id="delete"
                data-tooltip-content="Izbriši"
                className="h-9 w-9 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-500 text-white animation duration-200 ease-in-out">
                <Tooltip id="delete"/>
                <FontAwesomeIcon icon={faTrashCan}/>
              </button>
            </div>
          </div>
        </li>
      )}
      {benefits.length === 0 &&
        <div className="h-[55vh] flex items-center justify-center text-gray-600 text-sm font-medium">
          Nema pronađenih benefita.
        </div>
      }
    </ul>
  )
};

export default ListView;