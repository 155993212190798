import React, {useEffect, useState} from 'react';
import Input from 'components/Input';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import CheckBox from 'components/Checkbox';
import { ElementLoader } from 'components/Loaders';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'store/modal/modal.store';
import { getCategories } from 'modules/benefit/services/benefit.store';
import {
  addCategoryBudget,
  deleteCategoryBudget,
  editCategoryBudget,
  editEmployee
} from 'modules/employee/services/employee.store';
import SearchableDropdown from 'components/SearchableDropdown';
import { faPlus, faTrashCan, faPencil, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {capitalize} from "lodash";
import {monetaryFormatter} from 'libs/monetary';

interface EditSingleUserProps {
  employee: any;
  isPreview?: boolean;
  clientId: any;
}

const EditSingleUserForm = ({ employee, isPreview, clientId }: EditSingleUserProps) => {

  const dispatch: any = useDispatch();
  const categories: any = useSelector((state: any) => state.benefitStore.categories, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(employee);
  const [editBudgetIndex, setEditBudgetIndex] = useState<number | null>(null);
  const [budgetAmount, setBudgetAmount] = useState<number>(0);
  const [categoryBudgets, setCategoryBudgets] = useState(employee.categoryBudgets);

  const { register, getValues, setValue, handleSubmit, formState: { errors, isValid, touchedFields }}: any = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      baseBalance: '',
      balance: '',
      phone: '',
      position: '',
      idCompany: '',
      baseSalary: '',
      guaranteedAllowances: '',
      annualBonus: '',
      keyUser: false,
    },
  });

  useEffect(() => {
    if (employee.firstName) setValue('firstName', employee.firstName);
    if (employee.lastName) setValue('lastName', employee.lastName);
    if (employee.email) setValue('email', employee.email);
    if (employee.baseBalance) setValue('baseBalance', employee.baseBalance);
    if (employee.balance) setValue('balance', employee.balance);
    if (employee.phone) setValue('phone', employee.phone);
    if (employee.position) setValue('position', employee.position);
    if (employee.idCompany) setValue('idCompany', employee.idCompany);
    if (employee.baseSalary) setValue('baseSalary', employee.baseSalary);
    if (employee.guaranteedAllowances) setValue('guaranteedAllowances', employee.guaranteedAllowances);
    if (employee.annualBonus) setValue('annualBonus', employee.annualBonus);
    setValue('keyUser', employee.isCompanyAdmin);
  }, []);

  const onSubmit = async (data: any): Promise<void> => {
    const keys: string[] = Object.keys(employee);
    let payload = Object.entries(data).reduce((acc: any, [key, value]) => {
      if (keys.includes(key) && value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
    payload.keyUser = getValues('keyUser');
    payload.isCompanyAdmin = getValues('keyUser');
    if (isValid) {
      try {
        setLoading(true);
        await dispatch(editEmployee(employee.id, payload, clientId));
        setLoading(false);
        toast.success('Uspešno ste ažurirali korisnika!')
        dispatch(modalActions.closeModal());
      } catch (e: any) {
        if (e.status !== 500) {
          const errorKeys = Object.keys(e.data);
          console.log(e, 'ERROR')
          errorKeys.forEach((key: any) => {
            e.data[key].forEach((err: any) => {
              toast.error(`${capitalize(key)}: ${capitalize(err)}`);
            })
          })
        } else {
          toast.error('Nešto nije u redu!')
        }
        setLoading(false);
      }
    }
  };

  const keyUserHandler = (value: boolean): void => {
    setValue('keyUser', value);
  };

  const closeForm = () => {
    dispatch(modalActions.closeModal());
  };

  const addCategoryBudgetHandler = async (): Promise<any> => {
    const payload = {amount: 0, category: categories[1].id, user: employee.id};

    try {
      const resp: any = await dispatch(addCategoryBudget(payload, clientId));
      setCategoryBudgets((prevState: any) => ([
        ...prevState,
        resp
      ]));
    } catch (e: any) {
      console.log(e, 'err');
      toast.error('Nesto je poslo po zlu!')
    }
  };

  const selectCategoryHandler = async (id: number, categoryId: number, index: number): Promise<void> => {
    try {
      const resp: any = await dispatch(editCategoryBudget(id, { category: categoryId }, clientId));
      let budgets: any[] = [...categoryBudgets];
      budgets[index] = resp;
      setCategoryBudgets(budgets);
    } catch (e: any) {
      console.log(e, 'err');
    }
  };

  const editCategoryBudgetHandler = (amount: any): void => {
    setBudgetAmount(amount);
  };

  const saveCategoryBudgetHandler = async (id: number, amount: any, index: number) => {
    try {
      const resp = await dispatch(editCategoryBudget(id, { amount: amount }, clientId));
      let budgets = [...categoryBudgets];
      budgets[index] = resp;
      setEditBudgetIndex(null);
      setCategoryBudgets(budgets);
    } catch (e: any) {
      console.log(e, 'err');
    }
  };

  const deleteBudgetHandler = async (id: number, index: number): Promise<void> => {
    try {
      await dispatch(deleteCategoryBudget(id, clientId));
      let budgets = [...categoryBudgets];
      budgets.splice(index, 1);
      setCategoryBudgets(budgets);
    } catch (e: any) {
      console.log(e, 'err');
    }
  };


  useEffect(() => {
    dispatch(getCategories());
  }, []);

  console.log(categoryBudgets, 'cb')

  const getSelectedCategory = (categoryId: any) => {
    return categories.find((c: any) => c.id === categoryId);
  };

  return (
    <div className="relative">
      <div className="p-7 space-y-6">
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='text'
              label="Ime"
              autoFocus
              height='medium'
              disabled={isPreview}
              placeholder="Unesi ime"
              isTouched={touchedFields.firstName}
              error={errors.firstName}
              validationRules={register('firstName', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='text'
              label="Prezime"
              height='medium'
              disabled={isPreview}
              placeholder="Unesi prezime"
              isTouched={touchedFields.lastName}
              error={errors.lastName}
              validationRules={register('lastName', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div>
          <Input
            type='text'
            label="Email"
            height='medium'
            disabled={isPreview}
            placeholder="primer@mail.com"
            isTouched={touchedFields.email}
            error={errors.email}
            validationRules={register('email', {
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
                message: "Pogrešan format mejla",
              },
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="Početno stanje"
              height='medium'
              placeholder="Početno stanje"
              disabled={isPreview}
              isTouched={touchedFields.baseBalance}
              error={errors.baseBalance}
              validationRules={register('baseBalance', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='number'
              value={getValues('balance')}
              label="Stanje"
              height='medium'
              disabled
              placeholder="Stanje"
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="Telefon"
              height='medium'
              disabled={isPreview}
              placeholder="Telefon"
              isTouched={touchedFields.phone}
              error={errors.phone}
              validationRules={register('phone')}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='text'
              label="Kompanijski ID"
              height='medium'
              placeholder="Kompanijski ID"
              disabled={isPreview}
              isTouched={touchedFields.idCompany}
              error={errors.idCompany}
              validationRules={register('idCompany', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div>
          <Input
            type='text'
            label="Pozicija"
            height='medium'
            disabled={isPreview}
            placeholder="Unesi poziciju"
            validationRules={register('position')}
            onChange={() => null}
          />
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="Osnovna plata"
              disabled={isPreview}
              height='medium'
              placeholder="Osnovna plata"
              validationRules={register('baseSalary')}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='number'
              label="Garantovani dodaci"
              disabled={isPreview}
              height='medium'
              placeholder="Garantovani dodaci"
              validationRules={register('guaranteedAllowances')}
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="Godišnji bonus"
              height='medium'
              disabled={isPreview}
              placeholder="Godišnji bonus"
              validationRules={register('annualBonus')}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <CheckBox
              title="Admin"
              size="large"
              disabled={isPreview}
              textClassNames="font-medium"
              value={getValues('keyUser')}
              onChange={keyUserHandler}
            />
          </div>
        </div>
        <div>
          <label className="text-gray-700 font-bold text-sm mb-3 flex items-center gap-x-4">
            <p>Budžeti za kategorije</p>
            <Button
              bg="primary"
              disabled={isPreview}
              className="w-10"
              size="regular"
              onClick={addCategoryBudgetHandler}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </label>
          <ul className="space-y-3">
            {categoryBudgets && categoryBudgets.map((budget: any, i: number) =>
              <li key={budget.id} className="flex items-start gap-x-2.5">
                <div className="w-1/2 pr-1.5">
                  <SearchableDropdown
                    options={categories}
                    value={getSelectedCategory(budget.category)}
                    menuDirection="top"
                    disabled={isPreview}
                    valueToShow="name"
                    placeholder="Izaberi kategoriju"
                    idField="id"
                    onChange={(category: any) => selectCategoryHandler(budget.id, category.id, i)}
                  />
                </div>
                <div className="w-2/4 flex items-start gap-x-3">
                  <div className="w-2/3">
                    {editBudgetIndex === i &&
                      <div>
                        <Input
                          key={i}
                          type='number'
                          height='regular'
                          disabled={isPreview}
                          placeholder="Unesi novi iznos"
                          onChange={editCategoryBudgetHandler}
                        />
                      </div>
                    }
                    {editBudgetIndex !== i &&
                      <p className="w-full border tracking-tight h-10 flex items-center rounded-lg px-3">{monetaryFormatter(Number(budget.amount))} {employee.currency}</p>
                    }
                    {budget.current && !isNaN(budget.current) && <small className="text-xs text-red-600">Preostalo: {monetaryFormatter(Number(budget.current))} {employee.currency}</small>}
                  </div>
                  {editBudgetIndex !== i &&
                    <Button
                      bg="outline-primary"
                      className="w-10"
                      disabled={isPreview}
                      size="regular"
                      onClick={() => setEditBudgetIndex(i)}
                    >
                      <FontAwesomeIcon icon={faPencil}/>
                    </Button>
                  }
                  {editBudgetIndex === i &&
                    <Button
                      bg="outline-primary"
                      className="w-10"
                      disabled={isPreview}
                      size="regular"
                      onClick={() => saveCategoryBudgetHandler(budget.id, budgetAmount, i)}
                    >
                      <FontAwesomeIcon icon={faFloppyDisk}/>
                    </Button>
                  }
                  <Button
                    bg="outline-warning"
                    className="w-10"
                    disabled={isPreview}
                    size="regular"
                    onClick={() => deleteBudgetHandler(budget.id, i)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </Button>
                </div>
              </li>
            )}

          </ul>
        </div>
      </div>
      {!isPreview &&
        <div className="flex items-center justify-between gap-x-5 z-10 sticky bottom-0 left-0 px-7 z-20 py-6 border-t w-full bg-white">
          <Button
            bg="white"
            className="w-full flex items-center justify-center gap-x-2"
            size="regular"
            onClick={closeForm}
          >
            Otkaži
          </Button>
          <Button
            bg="primary"
            className="w-full gap-x-2 relative"
            size="regular"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Ažuriraj
            {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
          </Button>
        </div>
      }
    </div>
  );
};

export default EditSingleUserForm;