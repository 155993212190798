import React, {useEffect, useState} from 'react';
import {capitalize} from 'lodash';
import Input from 'components/Input';
import {toast} from 'react-toastify';
import Button from 'components/Button';
import {useForm} from 'react-hook-form';
import {ElementLoader} from 'components/Loaders';
import {modalActions} from 'store/modal/modal.store';
import {useDispatch, useSelector} from 'react-redux';
import {addAdministrator, addProvider, editAdministrator, editProvider} from 'modules/provider/services/provider.store';
import {decamelize, decamelizeKeys} from 'humps';
import {useParams} from 'react-router-dom';

interface AddEditAdministratorFormProps {
  administrator?: any;
  providerId: string | undefined;
}

const AddEditAdministratorForm = ({ administrator, providerId }: AddEditAdministratorFormProps) => {

  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(false);
  const [logoImg, setLogoImg] = useState<undefined | File>(undefined)
  const categories: any = useSelector((state: any) => state.benefitStore.categories);

  useEffect(() => {
    // dispatch(getCategories());
  }, []);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data: any): Promise<any> => {
    const formData = new FormData();
    if (!data.company) formData.append('company', String(providerId))
    for (const key in data) {
      if (data[key] !== '') formData.append(decamelize(key), data[key]);
    }

    if (isValid) {
      try {
        setLoading(true);
        !administrator ? await dispatch(addAdministrator(formData, providerId)) : await dispatch(editAdministrator(formData, data.id, providerId));
        setLoading(false);
        dispatch(modalActions.closeModal());
        toast.success(`Uspešno ste ${!administrator ? 'dodali' : 'ažurirali'} provajder administratora!`)
      } catch (e: any) {
        if (e.status !== 500) {
          const errorKeys = Object.keys(e.data);
          errorKeys.forEach((key: any) => {
            e.data[key].forEach((err: any) => {
              toast.error(`${capitalize(key)}: ${capitalize(err)}`);
            })
          })
        } else {
          toast.error('Nešto nije u redu!');
        }
        setLoading(false);
      }
    }
  };


  const closeForm = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    if (administrator) {
      for (const key in administrator) {
        if (administrator[key] !== '' && key !== 'picture') {
          // @ts-ignore
          setValue(key, administrator[key]);
        }
      }
    }
  }, [administrator]);

  return(
    <div className="relative">
      <div className="p-7 space-y-6">
        <div>
          <Input
            type='text'
            label="Ime"
            autoFocus
            height='medium'
            placeholder="Unesi ime"
            isTouched={touchedFields.firstName}
            error={errors.firstName}
            validationRules={register('firstName', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='text'
            label="Prezime"
            height='medium'
            placeholder="Unesi prezime"
            isTouched={touchedFields.lastName}
            error={errors.lastName}
            validationRules={register('lastName', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='text'
            label="Email"
            height='medium'
            placeholder="primer@mail.com"
            isTouched={touchedFields.email}
            error={errors.email}
            validationRules={register('email', {
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
                message: "Pogrešan format mejla",
              },
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='password'
            label="Lozinka"
            height='medium'
            placeholder="Unesi lozinku"
            isTouched={touchedFields.password}
            error={errors.password}
            validationRules={register('password', {
              required: {
                value: !administrator,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
      </div>
      <div className="flex items-center justify-between gap-x-5 z-10 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
        <Button
          bg="white"
          className="w-full flex items-center justify-center gap-x-2"
          size="regular"
          onClick={closeForm}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-full gap-x-2 relative"
          size="regular"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          {!administrator ? 'Dodaj' : 'Ažuriraj'}
          {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
        </Button>
      </div>
    </div>
  );
};

export default AddEditAdministratorForm;