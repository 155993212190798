import React, { useEffect, useState, useRef } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStore,
  faArrowRight,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { modalActions } from 'store/modal/modal.store';
import ProvidersGridView from 'modules/provider/UI/ProvidersGridView';
import ProvidersListView from 'modules/provider/UI/ProvidersListView';
import { ConfirmDeleteDialog } from 'components/Dialogs';
import { ElementLoader } from 'components/Loaders';
import {deleteProvider, exportXlsx, getProviders, providerActions} from 'modules/provider/services/provider.store';
import ProviderFilters from 'modules/provider/UI/ProviderFilters';
import AddEditProviderForm from 'modules/provider/UI/AddEditProviderForm';
import http from 'libs/http';

type Mode = "purchase" | "page";

interface ProvidersProps {
  mode: Mode;
}


const Providers = ({ mode }: ProvidersProps) => {

  const dispatch: any = useDispatch();
  const importRef = useRef<any>(null);
  const globalLoading: any = useSelector((state: any) => state.loaderStore.loading);
  const providers: any = useSelector((state: any) => state.providerStore.providers, shallowEqual);
  const filters: any = useSelector((state: any) => state.providerStore.filters, shallowEqual);
  const pagination: any = useSelector((state: any) => state.providerStore.pagination, shallowEqual);
  const [previewType, setPreviewType] = useState('grid');
  const [loading, setLoading] = useState(false);

  const openAddEditProviderForm = (e: any, provider: any, type: string): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <AddEditProviderForm provider={provider} type={type} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: `${type === 'add' ? 'Dodaj' : 'Ažuriraj'} provajdera ${type === 'add' ? '' : `: ${provider.name}`}`,
          size: 'medium',
        },
      })
    );
  };

  const selectProviderHandler = (e: any, provider: any) => {
    dispatch(providerActions.setSelectedProvider(provider));
  }

  const openDeletePrompt = (e: any, providerId: number): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <ConfirmDeleteDialog
        title="Izbriši provajdera"
        description="Da li zaista želiš obrisati provajdera? Svi podaci će biti trajno uklonjeni sa servera i prvajder se više neće prikazivati na listi. Ova akcija se ne može poništiti."
        onDelete={() => deleteProviderHandler(providerId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };


  const deleteProviderHandler = async (providerId: number): Promise<void> => {
    try {
      await dispatch(deleteProvider(providerId))
      dispatch(modalActions.closeModal());
    } catch (e: any) {
      if (e.status !== 500) {
        toast.error(e.data);
      } else {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const exportXlsxHandler = async () => {
    // try {
    //   const response = await dispatch(exportXlsx());
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "HOB-transactions-provider.xlsx");
    //   document.body.appendChild(link);
    //   link.click();
    // } catch (e: any) {
    //   console.log(e, 'err');
    // }
    let url = `/v2/admin/benefits/export/`
    http({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "HOB-provider-benefits.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  // const importUsersHandler = async (e: any): Promise<any> => {
  //   let payload = new FormData();
  //   payload.append('file', e.target.files[0]);
  //   try {
  //     await dispatch(importUsers(payload));
  //   } catch (e: any) {
  //     console.log(e, 'err');
  //     toast.error('Nešto je poslo po zlu!')
  //   }
  // }

  // const exportUsersHandler = () => {
  //   setLoading(true);
  //   let url = `v2/admin/employees/export?search=${searchString}`
  //   http({
  //     url: url,
  //     method: "GET",
  //     responseType: "blob", // important
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "korisnici.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //     setLoading(false);
  //   });
  //   setLoading(false);
  // }



  useEffect(() => {
    fetchMoreProviders(true);
  }, [filters.searchString, filters.ordering, filters.country]);

  const resetFilters = () => {
    dispatch(providerActions.setCount(0));
    dispatch(providerActions.setNextPage(null));
    dispatch(providerActions.setSearchString(''));
    dispatch(providerActions.resetProviders());
  };

  useEffect(() => {
    resetFilters();
    return (): void => {
      resetFilters();
    }
  }, []);


  const fetchMoreProviders = async (resetData: boolean): Promise<void> => {
    try {
      await dispatch(getProviders(resetData));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const nextPageRef = useRef(pagination.nextPage);
  const countRef = useRef(pagination.count);

  useEffect(() => {
    nextPageRef.current = pagination.nextPage;
  }, [pagination.nextPage]);

  useEffect(() => {
    countRef.current = pagination.count === providers.length;
  }, [pagination.count, providers]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreProviders(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openImportPopup = () => {
    importRef.current.click();
  };

  return (
    <>
      {mode === "page" &&
        <section className="flex items-center justify-between">
          <div>
            <Button
              bg="primary"
              className="lg:w-64 flex items-center justify-center gap-x-2"
              size="regular"
              onClick={(e: any) => openAddEditProviderForm(e, null, 'add')}
            >
              <span>
                +
                <FontAwesomeIcon icon={faStore}/>
              </span>
              <p>Dodaj benefit provajdera</p>
            </Button>

          </div>
          <div className="flex items-center justify-end gap-x-3">
            <div>
              <Button
                bg="outline-primary"
                className="lg:w-64 flex items-center justify-center gap-x-2"
                size="regular"
                disabled={loading}
                onClick={() => exportXlsxHandler()}
              >
                <FontAwesomeIcon icon={faDownload}/>
                <p>Preuzmi sve benefite (.xlsx)</p>
              </Button>

            </div>
          </div>
        </section>
      }

      {mode === "page" && <ProviderFilters />}

      {filters.previewType === 'grid' && providers.length > 0 &&
        <ProvidersGridView
          mode={mode}
          providers={providers}
          onSelect={(e: any, provider: any) => selectProviderHandler(e, provider)}
          onEdit={(e: any, provider: any) => openAddEditProviderForm(e, provider, 'edit')}
          onCopy={(e: any, provider: number) => openAddEditProviderForm(e, provider, 'add')}
          onDelete={(e: any, providerId: number) => openDeletePrompt(e, providerId)}
        />
      }
      {filters.previewType === 'list' && providers.length > 0 &&
        <ProvidersListView
          mode={mode}
          providers={providers}
          onEdit={(e: any, provider: any) => openAddEditProviderForm(e, provider, 'edit')}
          onCopy={(e: any, provider: number) => openAddEditProviderForm(e, provider, 'add')}
          onDelete={(e: any, providerId: number) => openDeletePrompt(e, providerId)}
        />
      }

      {loading && !globalLoading &&
        <div className="relative h-[35vh] lg:h-[55vh] rounded-lg my-9 flex items-center justify-center text-gray-700">
          <ElementLoader color="primary" size="regular" classNames="flex items-center justify-center"/>
        </div>
      }

      {!loading && providers.length === 0 &&
        <div
          className="h-[35vh] lg:h-[55vh] rounded-lg my-9 font-medium flex items-center justify-center text-gray-700">
          Nema podataka.
        </div>
      }
    </>
  )
    ;
};

export default Providers;