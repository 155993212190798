import React, {useEffect} from 'react';
import { Tooltip } from 'react-tooltip';
import {useSelector} from "react-redux";
import {monetaryFormatter} from 'libs/monetary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCartShopping, faCopy, faPencil, faTrashCan, faMobileScreenButton, faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons';
import { ElementLoader } from 'components/Loaders';

type Mode = "purchase" | "page";

interface GridViewProps {
  benefits: any[];
  mode: Mode;
  onCopy: (benefit: any) => void;
  addToCart: (e: any, benefit: any) => void;
  onEdit: (benefit: any) => void;
  onDelete: (benefitId: any) => void;
  onPreview: (benefit: any) => void;
}

const GridView = ({ benefits, onCopy, onDelete, onEdit, addToCart, onPreview, mode }: GridViewProps) => {


  const stripHtmlTags = (input: string): string => {
    if (input) return input.replace(/<\/?[^>]+(>|$)/g, "");
    else return input;
  };

  const parseCategoryName = (name: any) => {
    if (name) return name.split('/')[1];
    return name;
  };

  const copyHandler = (e: any, benefit: any) => {
    e.stopPropagation();
    onCopy(benefit);
  };

  const editHandler = (e: any, benefit: any) => {
    e.stopPropagation();
    onEdit(benefit);
  };

  const deleteHandler = (e: any, benefitId: any) => {
    e.stopPropagation();
    onDelete(benefitId);
  };

  const previewHandler = (e: any, benefit: any) => {
    e.stopPropagation();
    if (mode !== 'purchase') onPreview(benefit);
  };

  console.log(benefits, 'beneftis')

  return (
    <ul role="list" className={`grid grid-cols-1 sm:grid-cols-2 ${mode === 'page' ? 'lg:grid-cols-3' : 'lg:grid-cols-2'}  gap-10 my-9`}>
      {benefits.map((benefit: any) => (
        <li onClick={(e: any) => previewHandler(e, benefit)} key={benefit.id} className="relative group h-56 md:h-72 w-full mx-auto col-span-1 rounded-xl cursor-pointer bg-white shadow transform hover:scale-[1.03] animation duration-200 ease-in-out overflow-hidden">
          {benefit.discount > 0 &&
            <div className="absolute right-0 top-0 h-14 w-14 rounded-bl-2xl bg-base-500 text-white font-bold flex items-center justify-center">
              -{benefit.discount}%
            </div>
          }
          <div className="w-full h-3/6 flex items-center justify-center">
            {benefit.pictures[0] && <img src={benefit.pictures[0].image} className="w-full h-full object-center" alt="cover"/>}
            {!benefit.pictures[0] && <img src="/hob-logo.png" className="h-4/6 lg:h-3/6 object-center" alt="hob" />}
          </div>
          <div className="w-full h-1/6 bg-base-200 px-2.5 flex items-center justify-between">
            {(+benefit.price > 0) &&
              <>
                {!benefit.discount &&
                  <p className="font-black group-hover:text-base-500 animation duration-200 ease-in-out">
                    {monetaryFormatter(+benefit.price)} {benefit.currency}
                  </p>
                }

                {benefit.discount > 0 &&
                  <div className="mr-4 -space-y-1 group-hover:text-base-500">
                    <p className="font-medium line-through">{monetaryFormatter(+benefit.price)} {benefit.currency}</p>
                    <p className="font-black">
                      {monetaryFormatter(+benefit.price * (100 - benefit.discount) / 100)} {benefit.currency}
                    </p>
                  </div>
                }

                {benefit.discount > 0 &&
                  <div className="space-y-1">
                    <p className="font-bold ml-1 leading-3">{benefit.priceDiscountCurrency}</p>
                    <p
                      className="font-medium text-gray-400 line-through text-sm md:text-base ml-1 mr-2 leading-3">{benefit.priceCurrency}</p>
                  </div>
                }
              </>
            }

            {(+benefit.price === 0 && !benefit.nfc) &&
              <p
                className="font-black text-gray-600 group-hover:text-base-500 animation duration-200 ease-in-out flex items-center gap-x-2">
                <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                INVOICE
              </p>
            }

            {(+benefit.price === 0 && benefit.nfc) &&
              <p
                className="font-black text-gray-600 group-hover:text-base-500 animation duration-200 ease-in-out flex items-center gap-x-2">
                <FontAwesomeIcon icon={faMobileScreenButton}/>
                IN-STORE
              </p>
            }


            {mode === 'page' &&
              <div className="flex items-center justify-end gap-x-2 ml-auto">
                <button
                  onClick={(e: any) => editHandler(e, benefit)}
                  data-tooltip-id={`edit${benefit.id}`}
                  data-tooltip-content="Izmeni"
                  className="h-7 w-7 rounded-full flex items-center justify-center bg-base-500 hover:bg-base-600 text-white animation duration-200 ease-in-out">
                  <Tooltip id={`edit${benefit.id}`}/>
                  <FontAwesomeIcon icon={faPencil}/>
                </button>
                <button
                  onClick={(e: any) => copyHandler(e, benefit)}
                  data-tooltip-id={`copy${benefit.id}`}
                  data-tooltip-content="Kopiraj"
                  className="h-7 w-7 rounded-full flex items-center justify-center bg-orange-550 hover:bg-amber-400 text-white animation duration-200 ease-in-out">
                  <Tooltip id={`copy${benefit.id}`}/>
                  <FontAwesomeIcon icon={faCopy}/>
                </button>
                <button
                  onClick={(e: any) => deleteHandler(e, benefit.id)}
                  data-tooltip-id={`delete${benefit.id}`}
                  data-tooltip-content="Izbriši"
                  className="h-7 w-7 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-500 text-white animation duration-200 ease-in-out">
                  <Tooltip id={`delete${benefit.id}`}/>
                  <FontAwesomeIcon icon={faTrashCan}/>
                </button>
              </div>
            }
            {mode === 'purchase' &&
              <div className="flex items-center justify-end gap-x-2 ml-auto">
                <button
                  onClick={(e: any) => addToCart(e, benefit)}
                  data-tooltip-id={`edit${benefit.id}`}
                  data-tooltip-content="Dodaj u korpu"
                  className="h-8 w-8 rounded-full flex items-center justify-center bg-base-600 hover:bg-base-500 text-white animation duration-200 ease-in-out">
                  <Tooltip id={`edit${benefit.id}`}/>
                  <FontAwesomeIcon icon={faCartShopping}/>
                </button>
              </div>
            }
          </div>
          <div className="w-full h-2/6 px-2.5 pt-1 font-medium group-hover:text-base-500 animation duration-200 ease-in-out">
            <p>{benefit.name}</p>
            <p className="text-sm text-gray-500 mt-0.5 line-clamp-2">{stripHtmlTags(benefit.description)}</p>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default GridView;