export const MUNICIPALITIES_SERBIA: any = [
  { name: 'Cela Srbija', value: 'Srbija' },
  { name: 'Beograd', value: 'Beograd' },
  { name: 'Novi Sad', value: 'Novi Sad' },
  { name: 'Niš', value: 'Niš' },
  { name: 'Kragujevac', value: 'Kragujevac' },
  { name: 'Ostalo', value: 'Ostalo' },
  { name: 'Online', value: 'Online' },
];

export const MUNICIPALITIES_MONTENEGRO: any = [
  { name: 'Cela Crna Gora', value: 'Crna Gora' },
  { name: 'Podgorica', value: 'Podgorica' },
  { name: 'Budva', value: 'Budva' },
  { name: 'Nikšić', value: 'Nikšić' },
  { name: 'Ostalo', value: 'Ostalo' },
];