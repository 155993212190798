
import React, { useEffect, useState, useMemo, useRef } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faCheck} from '@fortawesome/free-solid-svg-icons';
import { useDetectClickOutside } from 'react-detect-click-outside';
import {isArray} from 'lodash';
import {Tooltip} from 'react-tooltip';
import {faXmarkCircle} from '@fortawesome/free-regular-svg-icons';


interface ComboBoxProps {
  options: any[];
  selectedOptions?: any;
  placeholder?: string;
  valueToShow: string;
  idField: string;
  disabled?: boolean;
  showSelected?: boolean;
  onInputChange?: (e: any) => void;
  onClear?: () => void;
  onSelect: (option: any) => void;
}

const ComboBox = ({ options, placeholder, showSelected, disabled, selectedOptions, onClear, onInputChange, onSelect, valueToShow, idField }: ComboBoxProps) => {

  const inputRef = useRef<any>(null);
  const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState('');

  // const filteredOptions = useMemo(() => {
  //   return localValue === ''
  //     ? options
  //     : options.filter((option: any) =>
  //       option[valueToShow].toLowerCase().includes(localValue.toLowerCase())
  //     );
  // }, [localValue, options]);

  const onInputChangeHandler = (e: any) => {
    if (onInputChange) {
      onInputChange(e);
      setLocalValue(e);
      // setDisplayDropdown(true);
      // setFocus(true);
    }
  };

  const onSelectHandler = (option: any) => {
    console.log('1')
    onSelect(option);
    setDisplayDropdown(false);
    setFocus(false);
    setLocalValue('');
    inputRef.current.value = '';
  };

  const onClearHandler = () => {
    if (onClear) {
      onClear();
      setDisplayDropdown(false);
      setFocus(false);
      setLocalValue('');
      inputRef.current.value = '';
      onInputChangeHandler('');
    }
  }

  const isSelected = (option: any): boolean => {
    if (!isArray(selectedOptions)) {
      return option[idField] === selectedOptions[idField];
    } else {
      return selectedOptions.some((o: any) => o[idField] === option[idField]);
    }
  };

  const getValue = () => {
    if (selectedOptions) {
      if (!isArray(selectedOptions)) {
        return selectedOptions[valueToShow] || '';
      } else {
        return selectedOptions.map((e: any) => e[valueToShow]).join(', ') || '';
      }
    } else {
      return '';
    }
  };

  const focusHandler = (e: any, type: string) => {
    e.stopPropagation();
    if (type === 'focus' && !focus) setFocus(true);
    else if (type === 'blur' && focus) {
      setFocus(false);
      setLocalValue('');
      inputRef.current.value = '';
    }
  }

  const clickOutsideHandler = () => {
    setDisplayDropdown(false);
    setFocus(false);
  };

  useEffect(() => {
    if (focus) {
      setDisplayDropdown(true);
      inputRef.current.focus();
    } else {
      // setDisplayDropdown(false);
    }
  }, [focus]);


  const ref: any = useDetectClickOutside({ onTriggered: () => clickOutsideHandler() });

  return (
    <div className="relative select-none" ref={ref}>
      <div>
        <div className="relative truncate">
          <input
            type="text"
            ref={inputRef}
            disabled={disabled}
            onFocus={(e: any) => focusHandler(e, 'focus')}
            onBlur={(e: any) => focusHandler(e, 'blur')}
            onChange={(e: any) => onInputChangeHandler(e.target.value)}
            onClick={() => setDisplayDropdown(true)}
            className={`h-10 caret-primary-900 border ${!disabled && 'hover:border-base-500'} ${focus && 'border-base-500'} w-full pl-3 rounded-lg text-gray-700 outline-none leading-tight animation duration-300 ease-in-out`}
          />

          {!focus && !getValue() && !localValue && <div onClick={(e: any) => focusHandler(e, 'focus')} className="absolute left-3 top-0 h-10 flex items-center text-gray-400 truncate pr-16">
            {placeholder}
          </div>}


          {!focus && getValue() && !localValue && <div onClick={(e: any) => focusHandler(e, 'focus')} className="absolute left-3 right-16 top-0 h-10 flex items-center truncate">
            <span className="truncate">{getValue()}</span>
          </div>}

          {localValue && <div onClick={(e: any) => focusHandler(e, 'focus')} className="absolute left-3 right-16 top-0 h-10 flex items-center truncate">
            <span className="truncate">{localValue}</span>
          </div>}

          <div onClick={() => setDisplayDropdown(!displayDropdown)} className={`absolute right-3 top-0 h-10 flex items-center cursor-pointer text-gray-400`}>
            <FontAwesomeIcon icon={faChevronDown} className={`transform ${displayDropdown ? 'rotate-180' : ''} animation duration-200 ease-in-out`} />
          </div>

          {getValue() &&
            <div onClick={() => onClearHandler()} className={`absolute right-9 top-0 h-10 flex items-center cursor-pointer text-red-600`}>
              <FontAwesomeIcon icon={faXmarkCircle} className={`relative`} />
            </div>
          }

        </div>
      </div>

      {(displayDropdown && options.length > 0) &&
        <ul className="absolute top-12 left-0 w-full max-h-64 z-10 rounded-lg overflow-y-auto bg-white shadow-xl">
          {options.map((option: any) =>
            <li
              onClick={() => onSelectHandler(option)}
              className="border-b p-2 hover:bg-base-500 hover:text-white hover:cursor-pointer flex items-center justify-between">
              <span>{option[valueToShow]}</span>
              {isSelected(option) &&
                <FontAwesomeIcon icon={faCheck} className="text-base-500" />
              }
            </li>
          )}
        </ul>
      }
      {(displayDropdown && options.length === 0) &&
        <ul className="absolute top-12 left-0 w-full max-h-64 z-10 rounded-lg overflow-y-auto bg-white shadow-xl">
            <li
              className="border-b py-5 px-3 text-sm  hover:cursor-pointer flex items-center justify-center text-gray-400">
              Nema rezultata za pretragu!
            </li>
        </ul>
      }
      {showSelected && <div className="flex flex-wrap gap-1 mt-0.5">
        {(isArray(selectedOptions) && selectedOptions.length > 0) && selectedOptions.map((e: any, i: number) =>
          i < 30 &&
          (<span onClick={() => onSelectHandler(e)}
                 className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-green-50 hover:bg-red-50  text-green-700 hover:text-red-700 ring-green-600/20 hover:ring-red-600/20  px-1.5 hover:cursor-pointer py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                  <span data-tooltip-id="remove" data-tooltip-content="Ukloni"
                        data-tooltip-place="bottom"
                        data-tooltip-class-name="z-50">{e[valueToShow]}</span>
                  <Tooltip id="remove"/>
                </span>)
        )}
        {isArray(selectedOptions) && selectedOptions.length > 30 &&
          <span
            className="mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-green-50   text-green-700  ring-green-600/20   px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset">
                + {selectedOptions.length - 30}
              </span>
        }
        {!isArray(selectedOptions) && selectedOptions &&
          <span onClick={() => onClearHandler()}
                className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-green-50 hover:bg-red-50  text-green-700 hover:text-red-700 ring-green-600/20 hover:ring-red-600/20  px-1.5 hover:cursor-pointer py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                  <span data-tooltip-id="remove" data-tooltip-content="Ukloni"
                        data-tooltip-place="bottom"
                        data-tooltip-class-name="z-50">{selectedOptions[valueToShow]}</span>
                  <Tooltip id="remove"/>
                </span>
        }
      </div>}
    </div>
  )
}

export default ComboBox;
