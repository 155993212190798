import axios from 'axios';
import { getBaseUrl } from './urlBuilder';
import { camelCase, snakeCase } from 'libs/http-helper';

const config = {
  baseURL: getBaseUrl(),
};

let headers: any = {};

export const setHeaders = (newHeaders: any): void => {
  headers = { ...headers, ...newHeaders };
};

export const removeHeader = (header: string): void => {
  delete headers[header];
};

const http = axios.create(config);

// Storage for tracking active requests
const activeRequests = new Map();

// Function for creating unique key for each request
const createRequestKey = (config: any): string => {
  if (!config) return '';  // Dodata provera
  return `${config.method}:${config.url}:${JSON.stringify(config.params)}`;
};

// Function to add request in storage
const addRequest = (config: any): any => {
  const requestKey = createRequestKey(config);
  if (!activeRequests.has(requestKey)) {
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    activeRequests.set(requestKey, source);
    return config;
  } else {
    return Promise.reject(`Duplicate request: ${requestKey}`);
  }
};

// Function to remove request from storage
const removeRequest = (config: any): void => {
  const requestKey = createRequestKey(config);
  activeRequests.delete(requestKey);
};

http.interceptors.request.use((config: any) => {
  for (const header in headers) {
    config.headers[header] = headers[header];
  }

  const updatedConfig = snakeCase(config);
  if (config.url.includes('page')) {
    return addRequest(updatedConfig);
  } else {
    return updatedConfig;
  }
}, (error: any) => {
  return Promise.reject(error);
});

http.interceptors.response.use(
  (response: any) => {
    removeRequest(response.config);
    return camelCase(response);
  },
  (error: any) => {
    if (error.config) {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
      }
      removeRequest(error.config);
    }
    interceptors.forEach((fn: (arg: any) => void) => fn(error));
    if (axios.isCancel(error)) {
      console.warn(error.message);  // Upozorenje za otkazane zahteve
      return Promise.reject(error.message);
    }
    return Promise.reject(error.response || error.message || error);
  }
);

// Function for canceling all active requests
export const cancelAllRequests = (): void => {
  activeRequests.forEach((source) => {
    source.cancel('Operation canceled by the user.');
  });
  activeRequests.clear();
};

export const interceptors: Array<(arg: any) => void> = [];

export default http;