import http from 'libs/http';


class ReimbursementApi {
  // getReimbursements = () => {
  //   return http.get('/v2/admin/refunds/');
  // };

  getReimbursements = (url: string) => {
    return http.get(url);
  };

  acceptSingleReimbursement = (payload: any) => {
    return http.post('/v2/admin/refunds/', payload);
  };

  acceptReimbursements = (payload: any) => {
    return http.get('/v2/reimbursements-accept/', payload);
  };

  declineSingleReimbursement = (payload: any) => {
    return http.post('/v2/admin/refunds/', payload);
  };

  declineReimbursements = (payload: any) => {
    return http.get('/v2/reimbursements-reject/', payload);
  };
}


export default ReimbursementApi;