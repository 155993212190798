import http from 'libs/http';


class BookingApi {
  getBookings = (url: string) => {
    return http.get(url);
  };

  getUnreadBookingsCount = () => {
    return http.get('/v2/admin/book-demo/?previewed=false');
  };

  markAsRead = (payload: any) => {
    return http.patch('/v2/admin/book-demo/bulk-update/', payload);
  };
}


export default BookingApi;