import React, {useEffect, useState} from 'react';
import Input from 'components/Input';
import {useForm} from 'react-hook-form';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { addEmployee } from 'modules/employee/services/employee.store';
import {useDispatch, useSelector} from 'react-redux';
import { modalActions } from 'store/modal/modal.store';
import { ElementLoader } from 'components/Loaders';
import {capitalize} from 'lodash';
import CheckBox from 'components/Checkbox';
import { getCompany } from "modules/provider/services/provider.store";

interface AddSingleUserFormProps {
  clientId: any
}

const AddSingleUserForm = ({ clientId }: AddSingleUserFormProps) => {

  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(false);
  const company = useSelector((state: any) => state.benefitStore.company);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      baseBalance: '',
      balance: '',
      phone: '',
      position: '',
      idCompany: '',
      baseSalary: '',
      guaranteedAllowances: '',
      annualBonus: '',
      keyUser: false,
      company: clientId,
    },
  });

  const onSubmit = async (data: any): Promise<any> => {

    let payload = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== '')
    );

    payload = {
      ...payload,
      language: 'SR',
      user_level: 'user',
      currency: 'RSD',
      is_company_admin: getValues('keyUser'),
    }

    // if (+company.country === 1) payload.currency = 'RSD';
    // if (+company.country === 2) payload.currency = 'BAM';
    // if (+company.country === 3) payload.currency = 'EUR';

    if (isValid) {
      try {
        setLoading(true);
        await dispatch(addEmployee(payload, clientId));
        setLoading(false);
        toast.success('Uspešno ste dodali korisnika!')
        dispatch(modalActions.closeModal());
      } catch (e: any) {
        if (e.status !== 500) {
          const errorKeys = Object.keys(e.data);
          console.log(e, 'ERROR')
          errorKeys.forEach((key: any) => {
            e.data[key].forEach((err: any) => {
              toast.error(`${capitalize(key)}: ${capitalize(err)}`);
            })
          })
        } else {
          toast.error('Nešto nije u redu!')
        }
        setLoading(false);
      }
    }
  };
  
  const keyUserHandler = (value: boolean): void => {
    setValue('keyUser', value);
  };

  const closeForm = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    // dispatch(getCompany());
  }, []);

  return (
    <div className="relative">
      <div className="p-7 space-y-6">
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='text'
              label="Ime"
              autoFocus
              height='medium'
              placeholder="Unesi ime"
              isTouched={touchedFields.firstName}
              error={errors.firstName}
              validationRules={register('firstName', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='text'
              label="Prezime"
              height='medium'
              placeholder="Unesi prezime"
              isTouched={touchedFields.lastName}
              error={errors.lastName}
              validationRules={register('lastName', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div>
          <Input
            type='text'
            label="Email"
            height='medium'
            placeholder="primer@mail.com"
            isTouched={touchedFields.email}
            error={errors.email}
            validationRules={register('email', {
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
                message: "Pogrešan format mejla",
              },
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="Početno stanje"
              height='medium'
              placeholder="Početno stanje"
              isTouched={touchedFields.baseBalance}
              error={errors.baseBalance}
              validationRules={register('baseBalance', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='number'
              label="Stanje"
              height='medium'
              disabled
              placeholder="Stanje"
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="Telefon"
              height='medium'
              placeholder="Telefon"
              validationRules={register('phone', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='text'
              label="Kompanijski ID"
              height='medium'
              placeholder="Kompanijski ID"
              validationRules={register('idCompany')}
              onChange={() => null}
            />
          </div>
        </div>
        <div>
          <Input
            type='text'
            label="Pozicija"
            height='medium'
            placeholder="Unesi poziciju"
            validationRules={register('position')}
            onChange={() => null}
          />
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="Osnovna plata"
              height='medium'
              placeholder="Osnovna plata"
              validationRules={register('baseSalary')}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <Input
              type='number'
              label="Garantovani dodaci"
              height='medium'
              placeholder="Garantovani dodaci"
              validationRules={register('guaranteedAllowances')}
              onChange={() => null}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="w-1/2">
            <Input
              type='number'
              label="Godišnji bonus"
              height='medium'
              placeholder="Godišnji bonus"
              validationRules={register('annualBonus')}
              onChange={() => null}
            />
          </div>
          <div className="w-1/2">
            <CheckBox
              title="Admin"
              size="large"
              value={getValues('keyUser')}
              textClassNames="font-medium"
              onChange={keyUserHandler}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
        <Button
          bg="white"
          className="w-full flex items-center justify-center gap-x-2"
          size="regular"
          onClick={closeForm}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-full gap-x-2 relative"
          size="regular"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Dodaj
          {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
        </Button>
      </div>
    </div>
  );
};

export default AddSingleUserForm;