import http from 'libs/http';

class ClientApi {
  getClients = (url: string) => {
    return http.get(url);
  };

  addClient = (payload: any) => {
    return http.post('/v2/admin/companies/', payload);
  };

  editClient = (clientId: number, payload: any) => {
    return http.patch(`/v2/admin/companies/${clientId}/`, payload);
  };

  deleteClient = (clientId: number) => {
    return http.patch(`/v2/admin/companies/${clientId}/`, { hide: true });
  };

  exportClients = () => {
    return http.get(`/v2/admin/companies/export/`, { responseType: "blob" });
  };
}


export default ClientApi;