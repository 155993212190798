import React, {useEffect, useState} from 'react';
import Input from 'components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { providerActions } from 'modules/provider/services/provider.store';
import { faArrowDownAZ, faArrowDownZA, faGrip, faListUl } from '@fortawesome/free-solid-svg-icons';

const countryOptions = [
  { label: 'SRB', value: 'sER', key: 1 },
  { label: 'BIH', value: 'bIH', key: 2 },
  { label: 'MNE', value: 'mNE', key: 3 },
]


const ProviderFilters = () => {

  const dispatch: any = useDispatch();
  const [country, setCountry] = useState<any>(countryOptions[0]);
  const filters: any = useSelector((state: any) => state.providerStore.filters, shallowEqual);

  const setSearchString = (payload: string) => {
    dispatch(providerActions.setSearchString(payload));
  };

  const setCountryHandler = (option: any): void => {
    console.log(option, 'test')
    dispatch(providerActions.setCountry(option));
  };

  const setPreviewTypeHandler = (option: any): void => {
    dispatch(providerActions.setPreviewType(option));
  };

  const setOrderingHandler = async (payload: string): Promise<any> => {
    await dispatch(providerActions.setCount(0));
    await dispatch(providerActions.setNextPage(null));
    dispatch(providerActions.setOrdering(payload));
  };

  const getCountry = () => {
    const country: any = countryOptions.find((c: any) => c.key === filters.country);
    setCountry(country);
  };

  useEffect(() => {
    getCountry();
    console.log(filters.country, 'asd')
  }, [filters.country]);



  return(
    <section className="mt-6 flex items-center justify-between">
      <div className="flex items-center gap-x-3">
        <div className="relative mx-auto lg:mx-0 shadow-lg flex items-center justify-between font-medium bg-white rounded-lg w-[258px] cursor-pointer select-none">
          <div className={`absolute ${filters.country === countryOptions[0].key && 'left-0'} ${filters.country === countryOptions[1].key && 'transform translate-x-[100%]'} ${filters.country === countryOptions[2].key && 'transform translate-x-[200%]'} top-0 bg-base-500 w-[86px] h-full rounded-lg animation duration-200 ease-in-out`}></div>
          {countryOptions.map((c: any) =>
            <p
              key={c.value}
              onClick={() => setCountryHandler(c.key)}
              className={`${country.value === c.value && 'text-white'} relative w-[86px] text-center rounded-full h-9 flex items-center justify-center animation duration-300 ease-in-out`}>
              { c.label }
            </p>
          )}
        </div>
        <div className="lg:w-[412px]">
          <Input
            type='text'
            isSearch
            height='regular'
            debounceTime={400}
            placeholder=" Pretraži po nazivu provajdera"
            onChange={setSearchString}
          />
        </div>
      </div>
      <div className="flex items-center justify-center gap-x-2">
        <div className="flex items-center justify-center gap-x-2">
          <div
            className="relative w-full z-0 flex justify-end items-center rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
            <button
              onClick={() => setOrderingHandler('+')}
              type="button"
              className={`h-10 w-full md:w-9 rounded-l-md border border-gray-300 ${filters.ordering === '+' ? 'bg-base-500 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faArrowDownAZ}/>
            </button>
            <button
              onClick={() => setOrderingHandler('-')}
              type="button"
              className={`h-10 w-full md:w-9 items-center mx-auto rounded-r-md border border-gray-300 ${filters.ordering === '-' ? 'bg-base-500 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faArrowDownZA}/>
            </button>
          </div>
          <div
            className="relative z-0 hidden md:flex justify-end items-end rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
            <button
              onClick={() => setPreviewTypeHandler('grid')}
              type="button"
              className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-l-md border border-gray-300 ${filters.previewType === 'grid' ? 'bg-base-500 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faGrip}/>
            </button>
            <button
              onClick={() => setPreviewTypeHandler('list')}
              type="button"
              className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-r-md border border-gray-300 ${filters.previewType === 'list' ? 'bg-base-500 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faListUl}/>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProviderFilters;