import React, { useEffect, useState, useRef } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  employeeActions,
  getEmployees,
  deleteEmployee,
  importUsers, deactivateEmployee, updateBudgets,
} from 'modules/employee/services/employee.store';
import {
  faUserPlus,
  faFileImport,
  faDownload,
  faArrowDownAZ,
  faArrowDownZA,
  faGrip,
  faUserGroup,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { modalActions } from 'store/modal/modal.store';
import AddSingleUserForm from 'modules/employee/UI/AddSingleUserForm';
import GridView from 'modules/employee/UI/GridView';
import ListView from 'modules/employee/UI/ListView';
import EditSingleUserForm from "modules/employee/UI/EditSingleUserForm";
import {capitalize} from 'lodash';
import { ConfirmDeleteDialog } from 'components/Dialogs';
import { ElementLoader } from 'components/Loaders';
import http from 'libs/http';
import DeactivatePrompt from 'modules/employee/UI/DeactivatePrompt';
import SearchableDropdown from 'components/SearchableDropdown';
import PreviewEmployee from 'modules/employee/UI/PreviewEmployee';
import {useParams} from 'react-router-dom';
import PurchaseBenefit from 'modules/employee/UI/PurchaseBenefit';
import {Tooltip} from 'react-tooltip';


const filterByOptions = [
  // { value: '', label: 'Sve' },
  { value: 'admin', label: 'Prikaži admine' },
  { value: 'inactive', label: 'Prikaži neaktivne' },
];

const sortByOptions = [
  // { value: '', label: 'Sve' },
  { value: 'first_name', label: 'Imenu' },
  { value: 'last_name', label: 'Prezimenu' },
  { value: 'id', label: 'ID korisnika' },
];

const Employees = () => {

  const dispatch: any = useDispatch();
  const { clientId } = useParams();
  const importRef = useRef<any>(null);
  const updateRef = useRef<any>(null);
  const globalLoading: any = useSelector((state: any) => state.loaderStore.loading);
  const employees: any = useSelector((state: any) => state.employeeStore.employees);
  const searchString: any = useSelector((state: any) => state.employeeStore.searchString);
  const ordering: any = useSelector((state: any) => state.employeeStore.ordering);
  const nextPage: any = useSelector((state: any) => state.employeeStore.nextPage);
  const count: any = useSelector((state: any) => state.employeeStore.count);
  const [previewType, setPreviewType] = useState('grid');
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState('');
  const [sortBy, setSortBy] = useState(sortByOptions[2]);

  const openAddEmployeeForm = () => {
    let modalContent: React.JSX.Element = (
      <AddSingleUserForm clientId={clientId} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Dodaj korisnika',
          size: 'regular',
        },
      })
    );
  };

  const openPurchaseBenefitModal = (e: any, employee: any) => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <PurchaseBenefit employee={employee} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: `Kupovina za korisnika: ${employee.firstName} ${employee.lastName} `,
          size: 'extra-large',
          wrapperClassNames: 'overflow-y-hidden'
        },
      })
    );
  };

  const openPreviewEmployee = (e: any, employee: any) => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <PreviewEmployee employee={employee} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: `Profil`,
          size: 'regular',
        },
      })
    );
  };

  const openEditEmployeeForm = (e: any, employee: any, isPreview: boolean) => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <EditSingleUserForm employee={employee} isPreview={isPreview} clientId={clientId} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: `${isPreview ? 'Pregled' : 'Ažuriraj'}   korisnika ${capitalize(employee.firstName)} ${capitalize(employee.lastName)}`,
          size: 'regular',
        },
      })
    );
  };

  const openDeletePrompt = (e: any, employeeId: number): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <ConfirmDeleteDialog
        title="Izbriši korisnika"
        description="Da li zaista želiš obrisati korisnika? Svi podaci će biti trajno uklonjeni sa servera i korisnik se više neće prikazivati na listi. Ova akcija se ne može poništiti."
        onDelete={() => deleteEmployeeHandler(employeeId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };

  const openDeactivatePrompt = (e: any, employeeId: number): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <DeactivatePrompt
        title="Deaktiviraj korisnika"
        description=" Da li zaista želiš deaktivirati korisnika? Deaktiviran korisnik više nema mogućnost pristupa HoB nalogu. Ova akcija se ne može poništiti osim kontaktiranjem HoB podrške."
        onDeactivate={() => deactivateEmployeeHandler(employeeId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };

  const deactivateEmployeeHandler = async (employeeId: number): Promise<void> => {
    try {
      await dispatch(deactivateEmployee(employeeId, clientId))
      dispatch(modalActions.closeModal());
    } catch (e: any) {
      if (e.status !== 500) {
        toast.error(e.data);
      } else {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const deleteEmployeeHandler = async (employeeId: number): Promise<void> => {
    try {
      await dispatch(deleteEmployee(employeeId, clientId))
      dispatch(modalActions.closeModal());
    } catch (e: any) {
      if (e.status !== 500) {
        toast.error(e.data);
      } else {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const importUsersHandler = async (e: any): Promise<any> => {
    let payload = new FormData();
    payload.append('file', e.target.files[0]);
    try {
      await dispatch(importUsers(payload));
    } catch (e: any) {
      console.log(e, 'err');
      toast.error('Nešto je poslo po zlu!')
    }
  }

  const updateUsersHandler = async (e: any): Promise<any> => {
    let payload = new FormData();
    payload.append('file', e.target.files[0]);
    try {
      await dispatch(updateBudgets(clientId, payload));
    } catch (e: any) {
      console.log(e, 'err');
      toast.error('Nešto je poslo po zlu!')
    }
  }

  const exportUsersHandler = () => {
    setLoading(true);
    let url = `v2/admin/employees/export?search=${searchString}`
    http({
      url: url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "korisnici.xlsx");
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });
    setLoading(false);
  }

  const setSearchString = (payload: string) => {
    dispatch(employeeActions.setSearchString(payload));
  }

  const filterByHandler = (option: any): void => {
    setFilterBy(option);
    dispatch(employeeActions.setFilterBy(option.value));
  }

  const sortByHandler = (option: any): void => {
    setSortBy(option);
    dispatch(employeeActions.setSortBy(option.value));
  }

  const setOrderingHandler = async (payload: string): Promise<any> => {
    await dispatch(employeeActions.setCount(0));
    await dispatch(employeeActions.setNextPage(null));
    dispatch(employeeActions.setOrdering(payload));
  }

  useEffect(() => {
    sortByHandler(sortByOptions[2]);
  }, []);

  useEffect(() => {
    fetchMoreEmployees(true);
  }, [searchString, ordering, filterBy, sortBy]);

  const resetFilters = () => {
    dispatch(employeeActions.setCount(0));
    dispatch(employeeActions.setNextPage(null));
    dispatch(employeeActions.setFilterBy(''));
    dispatch(employeeActions.setSearchString(''));
    dispatch(employeeActions.setSortBy(sortByOptions[2].value));
    dispatch(employeeActions.resetEmployees());
  };

  useEffect(() => {
    resetFilters();
    return (): void => {
      resetFilters();
    }
  }, []);


  const fetchMoreEmployees = async (resetData: boolean): Promise<void> => {
    try {
      await dispatch(getEmployees(resetData, clientId));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const nextPageRef = useRef(nextPage);
  const countRef = useRef(count);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === employees.length;
  }, [count, employees]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreEmployees(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openImportPopup = () => {
    importRef.current.click();
  };

  const openUpdatePopup = () => {
    updateRef.current.click();
  };

  return (
    <>
      <section className="flex items-center justify-between">
        <div className="flex items-center justify-end gap-x-3">
          <Tooltip id="import"/>
          <div data-tooltip-id="import"
               data-tooltip-content="Uvezi korisnike (.xlsx)"
               data-tooltip-class-name="z-40"
          >
            <input onChange={importUsersHandler} hidden type="file" ref={importRef} />
            <Button
              bg="outline-primary"
              className="lg:w-40 flex items-center justify-center gap-x-2"
              size="regular"
              onClick={openImportPopup}
            >
              <FontAwesomeIcon icon={faFileImport}/>
              <p>Import (.xlsx)</p>
            </Button>
          </div>
          <div>
            <Button
              bg="primary"
              className="lg:w-48 flex items-center justify-center gap-x-2"
              size="regular"
              onClick={openAddEmployeeForm}
            >
              <FontAwesomeIcon icon={faUserPlus}/>
              <p>Dodaj korisnika</p>
            </Button>

          </div>
        </div>
        <div className="flex items-center justify-end gap-x-3">
          <div>
            {/*<input onChange={importUsersHandler} hidden type="file" ref={importRef} />*/}
            <Button
              bg="outline-primary"
              className="lg:w-48 flex items-center justify-center gap-x-2"
              size="regular"
              disabled={true}
              onClick={() => null}
            >
              <FontAwesomeIcon icon={faUserGroup}/>
              <p>Team Building</p>
            </Button>
          </div>
          <div>
            <input onChange={updateUsersHandler} hidden type="file" ref={updateRef} />
            <Button
              bg="outline-primary"
              className="lg:w-60 flex items-center justify-center gap-x-2"
              size="regular"
              onClick={openUpdatePopup}
            >
              <FontAwesomeIcon icon={faFileImport}/>
              <p>Ažuriraj korisnike (.xlsx)</p>
            </Button>
          </div>

          <Tooltip id="export"/>
          <div data-tooltip-id="export"
               data-tooltip-content="Preuzmi korisnike (.xlsx)"
               data-tooltip-class-name="z-40"
          >
            <Button
              bg="outline-primary"
              className="lg:w-40 flex items-center justify-center gap-x-2"
              size="regular"
              disabled={loading}
              onClick={exportUsersHandler}
            >
              <FontAwesomeIcon icon={faDownload}/>
              <p>Export (.xlsx)</p>
            </Button>
          </div>
        </div>

      </section>
      <section className="mt-6 flex items-center justify-between">
        <div className="flex items-center gap-x-3">
          <div className="lg:w-[412px]">
            <Input
              type='text'
              isSearch
              height='regular'
              debounceTime={400}
              placeholder=" Pretraži po ID korisnika ili imenu i prezimenu"
              onChange={setSearchString}
            />
          </div>
          <div className="w-56">
            <SearchableDropdown
              value={filterBy}
              idField="value"
              valueToShow="label"
              placeholder="Filtriraj po"
              options={filterByOptions}
              onClear={() => filterByHandler('')}
              onChange={(option: any) => filterByHandler(option)}
            />
          </div>
        </div>
        <div className="flex items-center justify-center gap-x-2">
          <div className="w-44">
            <SearchableDropdown
              value={sortBy}
              idField="value"
              valueToShow="label"
              placeholder="Sortiraj po"
              options={sortByOptions}
              onClear={() => sortByHandler('')}
              onChange={(option: any) => sortByHandler(option)}
            />
          </div>
          <div className="flex items-center justify-center gap-x-2">
            <div
              className="relative w-full z-0 flex justify-end items-center rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
              <button
                onClick={() => setOrderingHandler('+')}
                type="button"
                className={`h-10 w-full md:w-9 rounded-l-md border border-gray-300 ${ordering === '+' ? 'bg-base-500 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
                <FontAwesomeIcon icon={faArrowDownAZ}/>
              </button>
              <button
                onClick={() => setOrderingHandler('-')}
                type="button"
                className={`h-10 w-full md:w-9 items-center mx-auto rounded-r-md border border-gray-300 ${ordering === '-' ? 'bg-base-500 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
                <FontAwesomeIcon icon={faArrowDownZA}/>
              </button>
            </div>
            <div
              className="relative z-0 hidden md:flex justify-end items-end rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
              <button
                onClick={() => setPreviewType('grid')}
                type="button"
                className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-l-md border border-gray-300 ${previewType === 'grid' ? 'bg-base-500 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
                <FontAwesomeIcon icon={faGrip}/>
              </button>
              <button
                onClick={() => setPreviewType('list')}
                type="button"
                className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-r-md border border-gray-300 ${previewType === 'list' ? 'bg-base-500 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
                <FontAwesomeIcon icon={faListUl}/>
              </button>
            </div>
          </div>
        </div>
      </section>

      {previewType === 'grid' && employees.length > 0 &&
          <GridView
              employees={employees}
              onPreview={(e: any, employee: number) => openPreviewEmployee(e, employee)}
              onPurchase={(e: any, employee: any) => openPurchaseBenefitModal(e, employee)}
              onEdit={(e: any, employee: number) => openEditEmployeeForm(e, employee, false)}
              onDelete={(e: any, employeeId: number) => openDeletePrompt(e, employeeId)}
              onDeactivate={(e: any, employeeId: number) => openDeactivatePrompt(e, employeeId)}
          />
      }
      {previewType === 'list' && employees.length > 0 &&
          <ListView
              employees={employees}
              onPreview={(e: any, employee: number) => openPreviewEmployee(e, employee)}
              onPurchase={(e: any, employee: any) => openPurchaseBenefitModal(e, employee)}
              onEdit={(e: any, employee: any) => openEditEmployeeForm(e, employee, false)}
              onDelete={(e: any, employeeId: number) => openDeletePrompt(e, employeeId)}
              onDeactivate={(e: any, employeeId: number) => openDeactivatePrompt(e, employeeId)}
          />
      }

      {loading && !globalLoading &&
          <div className="relative h-[35vh] lg:h-[55vh] rounded-lg my-9 flex items-center justify-center text-gray-700">
              <ElementLoader color="primary" size="regular" classNames="flex items-center justify-center"/>
          </div>
      }

      {!loading && employees.length === 0 &&
          <div
              className="h-[35vh] lg:h-[55vh] rounded-lg my-9 font-medium flex items-center justify-center text-gray-700">
              Nema podataka.
          </div>
      }
    </>
  )
    ;
};

export default Employees;