import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { authActions } from 'modules/auth/services/auth.store';
import { Route, Routes, useLocation, Outlet, Navigate, NavLink } from 'react-router-dom';

import Login from 'pages/login';
// import Contact from 'pages/contact';
// import Employees from 'pages/employees';
import Clients from 'pages/clients';
import Analytics from 'pages/analytics';
import Bookings from 'pages/bookings';
import Providers from 'pages/providers';
import Employees from 'pages/employees';
import Downloads from 'pages/downloads';
// import Suggestions from 'pages/suggestions';
// import Restrictions from 'pages/restrictions';
import Transactions from 'pages/transactions';
import Subscriptions from 'pages/subscriptions';
import Suggestions from 'pages/suggestions';
import Restrictions from 'pages/restrictions';
import FixedBenefits from 'pages/fixedBenefits';
import Notifications from 'pages/notifications';
import Reimbursements from 'pages/reimbursements';
import PageNotFound from 'components/PageNotFound';
import ProviderBenefits from 'pages/providerBenefits';
import ProviderAdministrators from 'pages/providerAdministrators';
import BenefitPreview from 'pages/providerBenefits/BenefitPreview';


const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const tokenStore: any = useSelector((state: any) => state.authStore.token);
  const token = localStorage.getItem('token') || tokenStore;
  const user = localStorage.getItem('user');

  const tokenHandler = () => {
    if(token) dispatch(authActions.setToken(token));
    else dispatch(authActions.logOut());
  }

  useEffect(() => {
    window.addEventListener('storage', () => {
      tokenHandler();
    })
  }, []);

  useEffect(() => {
    tokenHandler();
  }, [location.pathname]);

  return (
    token && user ? <Outlet /> : <Navigate to="/login" />
  );
};


const Router = () => {
  return (
    <Routes>
      {/* OPEN ROUTES HERE */}
      <Route path="/login" element={<Login />} />

      <Route element={<ProtectedRoutes />}>
        {/* PROTECTED ROUTES HERE */}
        <Route path="/" element={<Analytics />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/providers" element={<Providers />} />
        <Route path="/demo-bookings" element={<Bookings />} />
        <Route path="/suggestions" element={<Suggestions />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/benefits/:id" element={<BenefitPreview />} />
        <Route path="/reimbursements" element={<Reimbursements />} />
        <Route path="/clients/:clientId/employees" element={<Employees />} />
        <Route path="/providers/:providerId" element={<ProviderBenefits />} />
        <Route path="/clients/:clientId/restrictions" element={<Restrictions />} />
        <Route path="/clients/:clientId/fixed-benefits" element={<FixedBenefits />} />
        <Route path="/providers/:providerId/administrators" element={<ProviderAdministrators />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};




export default Router;