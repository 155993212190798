import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ReimbursementApi from './reimbursement.api';
import { loaderActions } from 'store/loader/loader.store';


const api: ReimbursementApi = new ReimbursementApi();

interface ReimbursementState {
  reimbursements: any[];
  nextPage: number | null;
  searchString: string;
  ordering: string;
  count: string | number;
  dateFrom: string;
  dateTo: string;
  status: string;
}

const initialState: ReimbursementState = {
  reimbursements: [],
  nextPage: null,
  searchString: '',
  ordering: '+',
  count: -1,
  dateFrom: '',
  dateTo: '',
  status: '',
};


const reimbursementSlice: any = createSlice({
  name: 'reimbursement',
  initialState,
  reducers: {
    setReimbursements: (state: any, action: PayloadAction<any, any>): any => {
      const rawReimbursements: any[] = [...state.reimbursements, ...action.payload];

      const uniqueReimbursementsMap: Map<any, any> = new Map();

      rawReimbursements.forEach(reimbursement => {
        if (reimbursement && reimbursement.id) {
          uniqueReimbursementsMap.set(reimbursement.id, reimbursement);
        }
      });
      state.reimbursements = Array.from(uniqueReimbursementsMap.values());
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.count = action.payload;
    },
    setSearchString: (state: any, action: PayloadAction<string, any>): any => {
      state.searchString = action.payload;
    },
    setStatus: (state: any, action: PayloadAction<string, any>): any => {
      state.status = action.payload;
    },
    setDateFrom: (state: any, action: PayloadAction<string, any>): any => {
      state.dateFrom = action.payload;
    },
    setDateTo: (state: any, action: PayloadAction<string, any>): any => {
      state.dateTo = action.payload;
    },
    setOrdering: (state: any, action: PayloadAction<string, any>): any => {
      state.ordering = action.payload;
    },
    resetReimbursement: (state: any): any => {
      state.reimbursements = [];
    },
  },
});

export const statusHandler = (status: any) => {
  switch (status) {
    case true:
      return `&status=True`;
    case false:
      return '&status=False';
    case null:
      return '&status=null';
    default:
      return '';
  }
}


export const getReimbursements = (resetData: boolean = false) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage = getState().reimbursementStore.nextPage;
      const searchString = getState().reimbursementStore.searchString;
      const dateFrom = getState().reimbursementStore.dateFrom;
      const dateTo = getState().reimbursementStore.dateTo;
      const status = getState().reimbursementStore.status;

      let url: string = `/v2/admin/refunds/?search=${searchString}`;
      if (dateFrom && resetData) {
        url += `&created_from=${dateFrom}`
      }

      if (dateTo && resetData) {
        url += `&created_to=${dateTo}`
      }

      if ((status === false || status === true || status === null) && resetData) {
        // url += `&status=${status}`
        url += statusHandler(status);
      }

      // if (searchString || dateFrom || dateFrom || status) dispatch(reimbursementActions.resetReimbursement());

      if (!resetData && nextPage) {
        url = nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getReimbursements(url);
      dispatch(reimbursementActions.setNextPage(data.next));
      dispatch(reimbursementActions.setCount(data.count));
      dispatch(reimbursementActions.setReimbursements(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const acceptSingleReimbursement = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.acceptSingleReimbursement(payload);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

// TODO: when backend done api
// export const acceptReimbursements = (payload: any) => {
//   return async (dispatch: any, getState: any): Promise<any> => {
//     try {
//       const { data } = await api.acceptReimbursements(payload);
//       return Promise.resolve(data);
//     } catch (e: any) {
//       return Promise.reject(e);
//     }
//   }
// }

export const declineSingleReimbursement = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.declineSingleReimbursement(payload);
      return Promise.resolve(data);
    } catch (e: any) {
      console.log(e, 'ERROR DECLINE')
      return Promise.reject(e);
    }
  }
}

// TODO: when backend done api
// export const declineReimbursements = (payload: any) => {
//   return async (dispatch: any, getState: any): Promise<any> => {
//     try {
//       const { data } = await api.declineReimbursements(payload);
//       return Promise.resolve(data);
//     } catch (e: any) {
//       return Promise.reject(e);
//     }
//   }
// }



export const reimbursementActions: any = reimbursementSlice.actions;


export default reimbursementSlice.reducer;
