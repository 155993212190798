import React, {useEffect, useState} from 'react';

import Button from 'components/Button';
import { toast } from 'react-toastify';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getSuggestions, markAsRead, suggestionsActions} from 'modules/suggestions/services/suggestions.store';
import SearchableDropdown from 'components/SearchableDropdown';
import CheckBox from 'components/Checkbox';
import {formatDate} from 'libs/datetime';
import {useParams} from 'react-router-dom';

const FILTER_OPTIONS = [
  { value: true, label: 'Pročitane' },
  { value: false, label: 'Nepročitane' },
];


const Suggestions = () => {

  const dispatch: any = useDispatch();
  const { clientId } = useParams();
  const suggestions: any = useSelector((state: any) => state.suggestionsStore.suggestions, shallowEqual);
  const status: any = useSelector((state: any) => state.suggestionsStore.status, shallowEqual);
  const [selectedIds, setSelectedIds] = useState<any>([]);


  const markAsReadHandler  = async (ids: number[]) => {
    try {
      const payload = {
        ids,
        reviewed: true,
      }
      await dispatch(markAsRead(payload, clientId));
      setSelectedIds([]);
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };

  const checkAllUnresolvedRefunds = (value: boolean) => {
    console.log('usli', value)
    let selected: any = [];

    if (value) {
      selected = [];
      suggestions.forEach((s: any) => {
        if (!s.reviewed) selected.push(s.id);
      })
      setSelectedIds(selected);
    } else {
      selected = [];
      setSelectedIds([]);
    }
  };

  const selectedItemsHandler = (id: number) => {
    let selectedItems = [...selectedIds];

    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter((item: number) => item !== id);
    } else {
      selectedItems = [...selectedItems, id];
    }

    setSelectedIds(selectedItems);
  };

  const checkIsSelected = (id: any): any => {
    return selectedIds.includes(id);
  };

  const resetSuggestions = async () => {
    await dispatch(suggestionsActions.setCount(0));
    await dispatch(suggestionsActions.resetSuggestions());
    await dispatch(suggestionsActions.setNextPage(null));
  }

  useEffect(() => {
    resetSuggestions();
    dispatch(getSuggestions(true));
  }, [status]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        dispatch(getSuggestions(false));
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const statusHandler = (status: any) => {
    dispatch(suggestionsActions.setStatus(status));
  }

  const getNotReadCount = () => {
    const notReviewed = suggestions.filter((s: any) => !s.reviewed);
    const count: number = notReviewed.length;
    return count;
  }

  useEffect(() => {
    getNotReadCount();
  }, [suggestions]);

  console.log(suggestions, 'suggestions')


  return(
    <div>
      <div className="flex items-center justify-between">
        <div className="w-44">
          <SearchableDropdown
            value={status}
            idField="value"
            valueToShow="label"
            placeholder="Status"
            options={FILTER_OPTIONS}
            onClear={() => statusHandler(null)}
            onChange={(status: any) => statusHandler(status)}
          />
        </div>
        <div className="flex items-center gap-x-3">
          {selectedIds.length > 0 &&
            <Button
              bg="primary"
              className="w-56 text-sm tracking-tighter"
              size="small"
              onClick={() => markAsReadHandler(selectedIds)}
            >
              Označi selektovane kao pročitano
            </Button>
          }
          <CheckBox
            size="medium"
            title="Označi sve"
            // placeholder={false}
            value={getNotReadCount() === selectedIds.length}
            wrapperClassNames="flex items-center"
            onChange={(val: boolean) => checkAllUnresolvedRefunds(val)}
          />
        </div>
      </div>
      {suggestions.length > 0 &&
          <section className="bg-white rounded-xl border overflow-hidden mt-4">
            {suggestions.map((s: any) =>
              <div key={suggestions.id} className={`flex items-center gap-x-5 py-8 border-b hover:bg-gray-100/50 ${!s.reviewed && 'bg-gradient-to-r from-gray-50 via-gray-50 to-base-500/10'}`}>
                <div className="w-96 pl-4 text-base-600 font-medium">
                  <p className="font-black text-black">{s.author.firstName} {s.author.lastName}</p>
                  <p>{s.author.email ? s.author.email : '/'}</p>
                  <p className="text-gray-400 text-sm font-medium">{formatDate(s.createdAt, 'DD.MM.YYYY.')}</p>
                </div>
                <div className="w-7/12 pl-5 border-l font-medium text-black/85" dangerouslySetInnerHTML={{ __html: s.text }}></div>
                <div className="w-40 mr-3">
                  {!s.reviewed &&
                    <Button
                      bg="primary"
                      className="w-full text-sm tracking-tighter"
                      size="small"
                      onClick={() => markAsReadHandler([s.id])}
                    >
                      Označi kao pročitano
                    </Button>
                  }
                </div>
                <div className="w-20 ml-2 h-full flex items-center justify-center">
                  {!s.reviewed &&
                    <CheckBox
                      size="meidium"
                      value={() => checkIsSelected(s.id)}
                      wrapperClassNames="flex items-center justify-center transform -translate-x-12"
                      onChange={(checked: boolean) => selectedItemsHandler(s.id)}
                    />
                  }
                </div>
              </div>
            )}
          </section>
      }
      {suggestions.length === 0 &&
        <div className="h-[calc(100vh-190px)] text-gray-600 flex items-center justify-center border rounded-lg">
          <div className="text-center space-y-4">
            <FontAwesomeIcon icon={faBullhorn} className="text-4xl"/>
            <p className="font-medium">Nema pronađenih sugestija!</p>
          </div>
        </div>
      }
    </div>
  );
};

export default Suggestions;