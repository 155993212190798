import http from 'libs/http';


class NotificationApi {
  sendNotification = (payload: any) => {
    return http.post('v2/admin/notifications/', payload);
  };

  uploadImage = (payload: any) => {
    return http.post('v2/admin/notification-images/', payload);
  };

  deleteImage = (imageId: number) => {
    return http.delete(`v2/admin/notification-images/${imageId}`);
  };

  uploadAttachments = (payload: any) => {
    return http.post('v2/admin/notification-attachments/', payload);
  };

  deleteAttachment = (id: any) => {
    return http.delete(`v2/admin/notification-attachments/${id}`);
  };
}


export default NotificationApi;