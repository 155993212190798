import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { modalActions } from 'store/modal/modal.store';
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ConfirmDenyDialogProps {
  title: string;
  description: string;
  onDelete: () => void;
}

const ConfirmDeleteDialog = ({ title, description, onDelete }: ConfirmDenyDialogProps) => {

  const dispatch: any = useDispatch();
  const handleClose = () => {
    // e.stopPropagation();
    dispatch(modalActions.closeModal());
  }

  return (
    <div>
      <section className="py-5 text-gray-600 text-center flex items-center justify-center">
        <div>
          <div className="flex items-center justify-center bg-red-600 rounded-full mx-auto h-10 w-10">
            <FontAwesomeIcon icon={faTrashCan} className="text-xl text-white" />
          </div>
          {title &&
              <p className="text-lg font-bold mt-2">{ title }</p>
          }
        </div>
      </section>
      {description &&
          <section className="text-center text-gray-600 border-y p-4">
              <p>{ description }</p>
          </section>
      }
      <section className="flex items-center justify-center gap-x-6 py-5">
        <Button
          bg="primary"
          className="w-36"
          size="regular"
          onClick={handleClose}
        >
          Otkaži
        </Button>
        <Button
          bg="warning"
          className="w-36"
          size="regular"
          onClick={onDelete}
        >
          Izbriši
        </Button>
      </section>
    </div>
  );
};

export default ConfirmDeleteDialog;
