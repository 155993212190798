import http from 'libs/http';


class AnalyticsApi {
  getBudgets = () => {
    return http.get('/v2/admin/budgets/');
  };
}


export default AnalyticsApi;