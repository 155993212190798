import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEnvelope,
  faPencil,
  faPhone,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "react-tooltip";
import {useNavigate} from 'react-router-dom';
import {countryOptions} from 'libs/countryOptions';

interface ListViewProps {
  clients: any[];
  onEdit: (e: any, client: any) => void;
  onCopy: (e: any, client: any) => void;
  onDelete: (e: any, clientId: any) => void;
}

const ClientsListView = ({ clients, onEdit, onDelete, onCopy}: ListViewProps) => {

  const navigate = useNavigate();

  const getCountry = (country: any) => {
    const target: any = countryOptions.find((c: any) => c.key === country);
    return target.label;
  };

  return (
    <ul className={`my-9 space-y-4 w-full`}>
      {clients && clients.length > 0 && clients.map((client: any) => (
        <li  key={`${client.id}-client-list`}
             onClick={() => navigate(`/clients/${client.id}/employees`)}
            className="bg-white hover:bg-gray-50 flex items-center justify-between gap-x-4 shadow-sm rounded-lg px-5 py-4 transform hover:scale-[1.02] cursor-pointer animation duration-200 ease-in-out">
          <div className="w-4/12 flex items-center justify-start gap-x-3">
            <div className="w-14">
              {client.picture &&
                <img
                  className="inline-block h-14 w-14 rounded-full"
                  src={client.picture}
                  alt={client.name}
                />
              }
              {!client.picture &&
                <span className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-400 uppercase">
                  <span className="text-lg font-medium leading-none text-white tracking-wide">{client.name.slice(0, 1)}</span>
                </span>
              }
            </div>
            <div className="truncate">
              <Tooltip id={`${client.id}`} />
              <p
                data-tooltip-id={client.id}
                data-tooltip-content={client.name}
                className={`capitalize font-medium truncate`}>
                {client.name}
              </p>
              <p>ID: {client.id}</p>
            </div>
          </div>
          <div className="w-4/12 flex items-center gap-x-2">
            <FontAwesomeIcon className="text-gray-500" icon={faEnvelope}/>
            <p>{client.email ? client.email : '-'}</p>
          </div>
          <div className={`w-2/12 flex items-center gap-x-2`}>
            <FontAwesomeIcon className="text-gray-500" icon={faPhone}/>
            <p>{client.phone ? client.phone : '-'}</p>
          </div>
          <div className="w-2/12 flex items-center justify-center">
            <span className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-gray-400 text-white ring-gray-50/20 px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset`}>
              {getCountry(client.country)}
            </span>
          </div>
          <div className="w-2/12 flex items-center justify-end gap-x-2">
            <button
              onClick={(e: any) => onEdit(e, client)}
              data-tooltip-id="edit"
              data-tooltip-content="Izmeni"
              className={`h-9 w-9 rounded-full flex items-center justify-center bg-base-500 hover:bg-base-600 text-white animation duration-200 ease-in-out`}>
              <Tooltip id="edit"/>
              <FontAwesomeIcon icon={faPencil}/>
            </button>
            <button
              data-tooltip-id="copy"
              data-tooltip-content="Kopiraj"
              onClick={(e: any) => onCopy(e, client)}
              className={`h-9 w-9 rounded-full flex items-center justify-center bg-yellow-500 hover:bg-yellow-500 text-white animation duration-200 ease-in-out`}>
              <Tooltip id="copy"/>
              <FontAwesomeIcon icon={faCopy}/>
            </button>
            <button
              onClick={(e: any) => onDelete(e, client.id)}
              data-tooltip-id="delete"
              data-tooltip-content="Izbriši"
              className={`h-9 w-9 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-500 text-white animation duration-200 ease-in-out`}>
              <Tooltip id="delete"/>
              <FontAwesomeIcon icon={faTrashCan}/>
            </button>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default ClientsListView;