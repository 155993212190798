import React, { useEffect, useState, useRef } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { modalActions } from 'store/modal/modal.store';
import { ConfirmDeleteDialog } from 'components/Dialogs';
import AdminsListView from 'modules/provider/UI/AdminsListView';
import AdminsGridView from 'modules/provider/UI/AdminsGridView';
import { ElementLoader } from 'components/Loaders';
import {
  deleteAdministrator,
  deleteProvider,
  exportXlsx,
  getProviderAdministrators,
  providerActions
} from 'modules/provider/services/provider.store';
import AdminFilters from 'modules/provider/UI/AdminFilters';
import {useParams} from 'react-router-dom';
import AddEditAdministratorForm from 'modules/provider/UI/AddEditAdministratorForm';



const ProviderAdministrators = () => {

  const dispatch: any = useDispatch();
  const { providerId } = useParams();
  const importRef = useRef<any>(null);
  const globalLoading: any = useSelector((state: any) => state.loaderStore.loading);
  const administrators: any = useSelector((state: any) => state.providerStore.administrators, shallowEqual);
  const filters: any = useSelector((state: any) => state.providerStore.filters, shallowEqual);
  const pagination: any = useSelector((state: any) => state.providerStore.pagination, shallowEqual);
  const [previewType, setPreviewType] = useState('grid');
  const [loading, setLoading] = useState(false);

  const openAddEditAdministratorForm = (e: any, administrator: any): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <AddEditAdministratorForm administrator={administrator} providerId={providerId} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: `${!administrator ? 'Dodaj' : 'Ažuriraj'} administratora ${!administrator ? '' : `: ${administrator.firstName} ${administrator.lastName}`}`,
          size: 'small',
        },
      })
    );
  };

  const openDeletePrompt = (e: any, providerId: number): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <ConfirmDeleteDialog
        title="Izbriši administratora"
        description="Da li zaista želiš obrisati administratora? Svi podaci će biti trajno uklonjeni sa servera i administrator se više neće prikazivati na listi. Ova akcija se ne može poništiti."
        onDelete={() => deleteAdministratorHandler(providerId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };


  const deleteAdministratorHandler = async (adminId: number): Promise<void> => {
    try {
      await dispatch(deleteAdministrator(adminId, providerId))
      dispatch(modalActions.closeModal());
    } catch (e: any) {
      if (e.status !== 500) {
        toast.error(e.data);
      } else {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const exportXlsxHandler = async () => {
    try {
      const response = await dispatch(exportXlsx());
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "HOB-provider-benefits.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (e: any) {
      console.log(e, 'err');
    }
  };



  useEffect(() => {
    fetchMoreAdministrators(true);
  }, [filters.searchString]);

  const resetFilters = () => {
    dispatch(providerActions.setCount(0));
    dispatch(providerActions.setNextPage(null));
    dispatch(providerActions.setSearchString(''));
    dispatch(providerActions.resetProviders());
  };

  useEffect(() => {
    resetFilters();
    return (): void => {
      resetFilters();
    }
  }, []);


  const fetchMoreAdministrators = async (resetData: boolean): Promise<void> => {
    try {
      await dispatch(getProviderAdministrators(resetData, providerId));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const nextPageRef = useRef(pagination.nextPage);
  const countRef = useRef(pagination.count);

  useEffect(() => {
    nextPageRef.current = pagination.nextPage;
  }, [pagination.nextPage]);

  useEffect(() => {
    countRef.current = pagination.count === administrators.length;
  }, [pagination.count, administrators]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreAdministrators(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openImportPopup = () => {
    importRef.current.click();
  };

  return (
    <>
      <section className="flex items-center justify-between">
        <div>
          <Button
            bg="primary"
            className="lg:w-64 flex items-center justify-center gap-x-2"
            size="regular"
            onClick={(e: any) => openAddEditAdministratorForm(e, null)}
          >
            <FontAwesomeIcon icon={faPlus}/>
            <p>Dodaj administratora</p>
          </Button>

        </div>
      </section>

      <AdminFilters />

      {filters.previewType === 'grid' && administrators.length > 0 &&
        <AdminsGridView
          administrators={administrators}
          onEdit={(e: any, admin: any) => openAddEditAdministratorForm(e, admin)}
          onDelete={(e: any, adminId: number) => openDeletePrompt(e, adminId)}
        />
      }
      {filters.previewType === 'list' && administrators.length > 0 &&
        <AdminsListView
          administrators={administrators}
          onEdit={(e: any, admin: any) => openAddEditAdministratorForm(e, admin)}
          onDelete={(e: any, adminId: number) => openDeletePrompt(e, adminId)}
        />
      }

      {loading && !globalLoading &&
        <div className="relative h-[35vh] lg:h-[55vh] rounded-lg my-9 flex items-center justify-center text-gray-700">
          <ElementLoader color="primary" size="regular" classNames="flex items-center justify-center"/>
        </div>
      }

      {!loading && administrators.length === 0 &&
        <div
          className="h-[35vh] lg:h-[55vh] rounded-lg my-9 font-medium flex items-center justify-center text-gray-700">
          Nema podataka.
        </div>
      }
    </>
  )
    ;
};

export default ProviderAdministrators;