import React, { useRef, useState } from 'react';
import http, {setHeaders} from 'libs/http';
import { toast } from 'react-toastify';
import Input from 'components/Input';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import TextEditor from 'components/TextEditor';
import { ElementLoader } from 'components/Loaders';
import { modalActions } from 'store/modal/modal.store';
import { useDispatch, useSelector } from 'react-redux';
import {faPlus, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BenefitDocumentInput from 'modules/benefit/UI/BenefitDocumentInput';
import { addFixedBenefit, deleteImage, getFixedBenefits, uploadImages } from 'modules/benefit/services/benefit.store';

interface AddFixedBenefitFormProps {
  clientId: any;
}

const AddFixedBenefitsForm = ({ clientId }: AddFixedBenefitFormProps) => {

  console.log(clientId, 'client id')

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      description: '',
      instructions: '',
      limitations: '',
    },
  });

  const dispatch: any = useDispatch();
  const user: any = useSelector((state: any) => state.authStore.user);
  const [loading, setLoading] = useState(false);
  const [promoMaterial, setPromoMaterial] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const importRef = useRef<any>(null);
  const importImageRef = useRef<any>(null);
  const [description, setDescription] = useState<any>('');
  const [instructions, setInstructions] = useState<any>('');
  const [limitations, setLimitations] = useState<any>('');

  const onSubmit = async (data: any) => {
    let payload: any = {};

    payload.fixed = true;
    payload.provider = clientId;
    if (data.name) payload.name = data.name;
    if (limitations) payload.limitations = limitations;
    if (description) payload.description =  description;
    if (instructions) payload.instructions = instructions;

    if (promoMaterial.length > 0) {
      payload.promo_material = promoMaterial.map((e: any) => e.id).join(',');
    }

    if (images.length > 0) {
      payload.pictures = [...images];
    }

    const formData: FormData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    if (isValid) {
      try {
        const data = await dispatch(addFixedBenefit(formData));
        uploadImagesHandler(data.id);
        dispatch(modalActions.closeModal());
        toast.success('Uspešno ste dodali fiksni benefit!');
      } catch (e: any) {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const openUploadPrompt = (): void => {
    importRef.current.click();
  }

  const openUploadImagePrompt = (): void => {
    importImageRef.current.click();
  }

  const setImageHandler = async (e: any): Promise<any> => {
    const selectedImages = [...images];
    selectedImages.push({
      position: 0,
      image: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0]
    });
    setImages(selectedImages);
  }

  const uploadImagesHandler = async (benefitId: number): Promise<any> => {
    for (const img of images) {
      let payload: FormData = new FormData();
      payload.append('image', img.file);
      payload.append('position', '0');
      payload.append('benefit', String(benefitId));

      try {
        await setHeaders({ 'content-type': 'multipart/form-data' });
        await dispatch(uploadImages(payload));
        await setHeaders({ 'content-type': 'application/json' });
      } catch (e: any) {
        console.log(e, 'ERR');
      }
    }
    dispatch(getFixedBenefits(true, clientId));
  }

  const uploadPromoHandler = async (e: any): Promise<any> => {
    let req = new FormData()
    req.append('title', 'promo');
    req.append('file', e.target.files[0]);

    try {
      setHeaders({ 'content-type': 'multipart/form-data' });
      const { data } = await http.post('v2/admin/promo/', req);
      setHeaders({ 'content-type': 'application/json' });
      const promo = [...promoMaterial, data];
      setPromoMaterial(promo);
    } catch (e: any) {
      toast.error(e.data.detail);
    }
  }

  const deletePromoHandler = async (promoId: number): Promise<any> => {
    try {
      await http.delete(`v2/admin/promo/${promoId}/`);
      const promo = promoMaterial.filter((e: any) => e.id != promoId);
      setPromoMaterial(promo);
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };

  const deleteImageHandler = async (image: any): Promise<any> => {
    const imgs = images.filter((e: any) => e.image !== image);
    setImages(imgs);
  }

  const descriptionHandler = (e: any): void => {
    setDescription(e);
  }

  const limitationsHandler = (e: any): void => {
    setLimitations(e);
  }

  const instructionsHandler = (e: any): void => {
    setInstructions(e);
  }

  const closeForm = () => {
    dispatch(modalActions.closeModal());
  }

  return (
    <div className="relative">
      <div className="p-7 space-y-7">
        <div>
          <Input
            type='text'
            label="Naziv"
            autoFocus
            height='medium'
            placeholder="Unesi naziv"
            isTouched={touchedFields.name}
            error={errors.name}
            validationRules={register('name', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div className="h-60">
          <TextEditor
            label="Opis"
            onChange={descriptionHandler}
          />
        </div>
        <div className="h-64 pt-2">
          <TextEditor
            label="Instrukcije"
            onChange={instructionsHandler}
          />
        </div>
        <div className="h-64">
          <TextEditor
            label="Ograničenja"
            onChange={limitationsHandler}
          />
        </div>
        <div className="flex items-end gap-x-5">
          <div>
            <label className="w-10 block text-gray-700 text-sm font-bold mb-1">
              Prilog
            </label>
            <div className="flex items-center gap-x-6">
              {promoMaterial.map((p: any) =>
                <BenefitDocumentInput key={p.id} promo={p} onDelete={() => deletePromoHandler(p.id)}/>
              )}
            </div>
          </div>
          <div className="pt-3">
            <input onChange={uploadPromoHandler} hidden type="file" ref={importRef}/>
            <Button
              bg="outline-primary"
              className="w-8 flex items-center justify-center gap-x-2"
              size="small"
              onClick={openUploadPrompt}
            >
              <FontAwesomeIcon icon={faPlus}/>
            </Button>
          </div>
        </div>
        <div className="flex items-end gap-x-5">
          <div>
            <label className="w-10 block text-gray-700 text-sm font-bold mb-1">
              Slike
            </label>
            <div className="flex flex-wrap items-center gap-4">
              {images.map((img: any) =>
                <div key={img.image} className="flex items-end gap-x-2">
                  <img src={img.image} alt="" className="h-16 rounded-lg"/>
                  <div className="flex items-center">
                    <Button
                      bg="outline-warning"
                      className="w-8 mt-0.5 flex items-center justify-center gap-x-2"
                      size="small"
                      onClick={() => deleteImageHandler(img.image)}
                    >
                      <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                  </div>
                </div>
            )}
          </div>
        </div>
        <div>
          <div className="pt-3">
            <input onChange={setImageHandler} hidden type="file" ref={importImageRef}/>
            <Button
              bg="outline-primary"
              className="w-8 flex items-center justify-center gap-x-2"
              size="small"
              onClick={openUploadImagePrompt}
            >
              <FontAwesomeIcon icon={faPlus}/>
            </Button>
          </div>
        </div>
        </div>
      </div>
      <div
        className="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
        <Button
          bg="white"
          className="w-full flex items-center justify-center gap-x-2"
          size="regular"
          onClick={closeForm}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-full gap-x-2 relative"
          size="regular"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Dodaj
          {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
        </Button>
      </div>
    </div>
  );
};

export default AddFixedBenefitsForm;