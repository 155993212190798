import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { loaderActions } from 'store/loader/loader.store';
import SubscriptionApi from 'modules/subscription/services/subscription.api';


const api: SubscriptionApi = new SubscriptionApi();


interface SubscriptionState {
  subscriptions: any[];
}

const initialState: SubscriptionState = {
  subscriptions: [],
};


const subscriptionSlice: any = createSlice({
  name: 'subscription',
  initialState,
  reducers: {

  },
});


export const exportSubscriptions = (): any => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.exportSubscriptions();
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};




export const subscriptionActions: any = subscriptionSlice.actions;

export default subscriptionSlice.reducer;