import React, {useEffect, useState, forwardRef, useRef} from 'react';
import DatePicker from 'react-datepicker';
import { faCircleXmark, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DatepickerProps {
  placeholder?: string;
  value?: any;
  format?: string;
  disabled?: boolean;
  label?: string;
  classNames?: string;
  onChange: (date: any) => void;
}

const CustomInput = forwardRef<HTMLInputElement, { value: string; label?:string; disabled?: boolean; onClick: () => void; onClear: () => void; onChange?: (e: any) => void; placeholderText?: string }>(({ value, onClick, disabled, onClear, onChange, placeholderText }, ref) => (
  <div className="relative">
    <div className="absolute left-3 top-0 z-30 h-full flex items-center">
      <FontAwesomeIcon icon={faCalendar} className="text-gray-300" />
    </div>
    <input
      type="text"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
      placeholder={placeholderText}
      className={`w-full pl-9 h-10 z-20 relative rounded-lg border ${disabled ? 'bg-stone-50' : 'hover:border-base-500 focus:ring-2 focus:ring-base-500'} focus:outline-0 animation duration-200 ease-in-out`}
    />
    {value && !disabled && (
      <button
        onClick={() => onClear()}
        className="absolute right-2 top-2 text-red-600 z-20"
      >
        <FontAwesomeIcon icon={faCircleXmark} />
      </button>
    )}
  </div>
));


const Datepicker = ({ value, label, classNames, format, disabled, placeholder, onChange }: DatepickerProps) => {
  const [date, setDate] = useState<any>('');
  const datePickerRef = useRef<any>(null);

  const onChangeHandler = (date: string) => {
    setDate(date);
    onChange(date);
  }

  const enterHandler = async (e: any) => {
    if (e.keyCode === 13) {
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', enterHandler);

    return (): void => {
      window.removeEventListener('keypress', enterHandler);
    }
  }, []);

  useEffect(() => {
    if (value) setDate(value);
  }, [value]);

  return (
    <div className="relative">
      {label &&
        <label className="block text-gray-700 text-sm font-bold mb-1">
          { label }
        </label>
      }
      <DatePicker
        ref={datePickerRef}
        selected={date}
        dateFormat={format}
        icon={true}
        disabled={disabled}
        onSelect={(value: any) => onChangeHandler(value)}
        onChange={(value: any) => onChangeHandler(value)}
        customInput={
          <CustomInput
            onClick={() => null}
            value={date ? date : ''}
            disabled={disabled}
            onClear={() => onChangeHandler('')}
            placeholderText={placeholder}
          />
        }
      />
    </div>
  );
};

export default Datepicker;