import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import NewNotificationForm from 'modules/notification/UI/NewNotificationForm';

const countryOptions = [
  { label: 'SRB', name: 'Srbija', value: 'sER', key: 1 },
  { label: 'BIH', name: 'Bosna i Hercegovina', value: 'bIH', key: 2 },
  { label: 'MNE', name: 'Crna Gora', value: 'mNE', key: 3 },
]

const Notifications = () => {

  const dispatch: any = useDispatch();
  const filters: any = useSelector((state: any) => state.clientStore.filters, shallowEqual);

  return(
    <div>
      <NewNotificationForm />
    </div>
  );
};

export default Notifications;