import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ProviderApi from './provider.api';
import { loaderActions } from 'store/loader/loader.store';

const api: ProviderApi = new ProviderApi();

interface ProviderState {
  providers: any[];
  selectedProvider: any;
  company: any;
  administrators: any[];
  pagination: any;
  filters: any;
}

const initialState: ProviderState = {
  providers: [],
  selectedProvider: null,
  company: null,
  administrators: [],
  pagination: {
    nextPage: null,
    count: -1,
  },
  filters: {
    searchString: '',
    country: 1,
    ordering: '-',
    previewType: 'grid',
  }
};

const providerSlice: any = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    setProviders: (state: any, action: PayloadAction<any, any>): any => {
      const rawProviders = [...state.providers, ...action.payload];
      const uniqueProvidersMap = new Map();
      rawProviders.forEach(provider => {
        uniqueProvidersMap.set(provider.id, provider);
      });
      state.providers = Array.from(uniqueProvidersMap.values());
    },
    setAdministrators: (state: any, action: PayloadAction<any, any>): any => {
      const rawAdministrators = [...state.administrators, ...action.payload];
      const uniqueAdministratorsMap = new Map();
      rawAdministrators.forEach(administrator => {
        uniqueAdministratorsMap.set(administrator.id, administrator);
      });
      state.administrators = Array.from(uniqueAdministratorsMap.values());
    },
    setCompany: (state: any, action: PayloadAction<any, any>): any => {
      state.company = action.payload;
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.pagination.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.pagination.count = action.payload;
    },
    setSearchString: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.searchString = action.payload;
    },
    setCountry: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.country = action.payload;
    },
    setOrdering: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.ordering = action.payload;
    },
    setPreviewType: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.previewType = action.payload;
    },
    resetProviders: (state: any): any => {
      state.providers = [];
    },
    setSelectedProvider: (state: any, action: PayloadAction<string, any>): any => {
      state.selectedProvider = action.payload;
    },
    resetAdministrators: (state: any): any => {
      state.administrators = [];
    },
  },
});


export const getProviders = (resetData: boolean) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const filters = await getState().providerStore.filters;
      const pagination = await getState().providerStore.pagination;

      let url: string = `/v2/admin/providers/?country=${filters.country}&search=${filters.searchString}`;

      if (resetData) dispatch(providerActions.resetProviders())

      if (!resetData && pagination.nextPage) {
        url = pagination.nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getProviders(url);
      dispatch(providerActions.setNextPage(data.next));
      dispatch(providerActions.setCount(data.count));
      dispatch(providerActions.setProviders(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const getClientProviders = (resetData: boolean, clientId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const filters = await getState().providerStore.filters;
      const pagination = await getState().providerStore.pagination;

      let url: string = `/v2/admin/providers/?providers=${clientId}&search=${filters.searchString}`;

      if (resetData) dispatch(providerActions.resetProviders())

      if (!resetData && pagination.nextPage) {
        url = pagination.nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getProviders(url);
      dispatch(providerActions.setNextPage(data.next));
      dispatch(providerActions.setCount(data.count));
      dispatch(providerActions.setProviders(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const addProvider = (payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(false));
      const { data } = await api.addProvider(payload);
      dispatch(providerActions.resetProviders());
      dispatch(providerActions.setNextPage(0));
      dispatch(getProviders(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const editProvider = (providerId: number, payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.editProvider(providerId, payload);
      dispatch(providerActions.resetProviders());
      dispatch(providerActions.setNextPage(0));
      dispatch(getProviders(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const deleteProvider = (providerId: number) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.deleteProvider(providerId);
      dispatch(providerActions.resetProviders());
      dispatch(providerActions.setNextPage(0));
      dispatch(getProviders(true));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const exportXlsx = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      // dispatch(loaderActions.setLoading(true));
      const { data } = await api.exportXlsx();
      // dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const getProviderAdministrators = (resetData: boolean, providerId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const filters = await getState().providerStore.filters;
      const pagination = await getState().providerStore.pagination;

      let url: string = `/v2/admin/provider-administrators/?company=${providerId}&search=${filters.searchString}`;

      if (resetData) dispatch(providerActions.resetAdministrators())

      if (!resetData && pagination.nextPage) {
        url = pagination.nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getAdministrators(url);
      dispatch(providerActions.setNextPage(data.next));
      dispatch(providerActions.setCount(data.count));
      dispatch(providerActions.setAdministrators(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const addAdministrator = (payload: any, providerId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(false));
      const { data } = await api.addAdministrator(payload);
      dispatch(providerActions.resetAdministrators());
      dispatch(providerActions.setNextPage(0));
      dispatch(getProviderAdministrators(true, providerId));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const editAdministrator = (payload: any, adminId: any, providerId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(false));
      const { data } = await api.editAdministrator(payload, adminId);
      dispatch(providerActions.resetAdministrators());
      dispatch(providerActions.setNextPage(0));
      dispatch(getProviderAdministrators(true, providerId));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const deleteAdministrator = (adminId: number, providerId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.deleteAdministrator(adminId);
      dispatch(providerActions.resetAdministrators());
      dispatch(providerActions.setNextPage(0));
      dispatch(getProviderAdministrators(true, providerId));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
};

export const getCompany = (clientId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.getCompany(clientId);
      dispatch(providerActions.setCompany(data));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}


export const configureCompany = (payload: any, clientId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      // const companyId = getState().authStore.user.company.id;
      const { data } = await api.configureCompany(clientId, payload);
      dispatch(providerActions.setCompany(data));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}




export const providerActions: any = providerSlice.actions;

export default providerSlice.reducer;
