import React, {useEffect, useState} from 'react';

import Button from 'components/Button';
import { toast } from 'react-toastify';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { faUserCircle, faEnvelope, faPhone, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBookings, markAsRead, bookingActions } from 'modules/booking/services/booking.store';
import SearchableDropdown from 'components/SearchableDropdown';
import CheckBox from 'components/Checkbox';
import {formatDate} from 'libs/datetime';
import {useParams} from 'react-router-dom';
import {faCircleXmark} from '@fortawesome/free-regular-svg-icons';
import {Tooltip} from 'react-tooltip';

const FILTER_OPTIONS = [
  { value: true, label: 'Pročitane' },
  { value: false, label: 'Nepročitane' },
];


const Bookings = () => {

  const dispatch: any = useDispatch();
  const { clientId } = useParams();
  const bookings: any = useSelector((state: any) => state.bookingStore.bookings, shallowEqual);
  const status: any = useSelector((state: any) => state.bookingStore.status, shallowEqual);
  const [selectedIds, setSelectedIds] = useState<any>([]);


  const markAsReadHandler  = async (ids: number[]) => {
    try {
      const payload = {
        ids,
        previewed: true,
      }
      await dispatch(markAsRead(payload, clientId));
      setSelectedIds([]);
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };

  const checkAllUnresolvedRefunds = (value: boolean) => {
    console.log('usli', value)
    let selected: any = [];

    if (value) {
      selected = [];
      bookings.forEach((b: any) => {
        if (!b.previewed) selected.push(b.id);
      })
      setSelectedIds(selected);
    } else {
      selected = [];
      setSelectedIds([]);
    }
  };

  const selectedItemsHandler = (id: number) => {
    let selectedItems = [...selectedIds];

    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter((item: number) => item !== id);
    } else {
      selectedItems = [...selectedItems, id];
    }

    setSelectedIds(selectedItems);
  };

  const checkIsSelected = (id: any): any => {
    return selectedIds.includes(id);
  };

  const resetBookings = async () => {
    await dispatch(bookingActions.setCount(0));
    await dispatch(bookingActions.resetBookings());
    await dispatch(bookingActions.setNextPage(null));
  }

  useEffect(() => {
    resetBookings();
    dispatch(getBookings(true));
  }, [status]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        dispatch(getBookings(false));
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const statusHandler = (status: any) => {
    dispatch(bookingActions.setStatus(status));
  }

  const getNotReadCount = () => {
    const notReviewed = bookings.filter((b: any) => !b.previewed);
    const count: number = notReviewed.length;
    return count;
  }

  useEffect(() => {
    getNotReadCount();
  }, [bookings]);

  console.log(bookings, 'bookings')


  return(
    <div className="pb-10">
      <div className="flex items-center justify-between">
        <div className="w-44">
          <SearchableDropdown
            value={status}
            idField="value"
            valueToShow="label"
            placeholder="Status"
            options={FILTER_OPTIONS}
            onClear={() => statusHandler(null)}
            onChange={(status: any) => statusHandler(status)}
          />
        </div>
        <div className="flex items-center gap-x-3">
          {selectedIds.length > 0 &&
            <Button
              bg="primary"
              className="w-56 text-sm tracking-tighter"
              size="small"
              onClick={() => markAsReadHandler(selectedIds)}
            >
              Označi selektovane kao pročitano
            </Button>
          }
          <CheckBox
            size="medium"
            title="Označi sve"
            // placeholder={false}
            value={getNotReadCount() === selectedIds.length}
            wrapperClassNames="flex items-center"
            onChange={(val: boolean) => checkAllUnresolvedRefunds(val)}
          />
        </div>
      </div>
      {bookings.length > 0 &&
        <section className="overflow-hidden mt-4 grid grid-cols-3 gap-8">
          {bookings.map((b: any) =>
            <div key={b.id} className={`divide-y space-y-2 rounded-lg border bg-white hover:bg-gray-50 ${!b.previewed && 'bg-gradient-to-r from-gray-50 via-gray-50 to-base-500/10'}`}>
              <section className="p-4 flex items-end justify-between">
                <div className="space-y-0.5">
                  <p className="">Kompanija</p>
                  <p className="font-bold">{b.companyName}</p>
                  <p className="font-bold">{b.numberOfEmployees} <span className="font-normal">zaposlenih</span></p>
                </div>
                <div className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-green-50 text-green-700 ring-green-600/20 px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                  { b.country }
                </div>
              </section>
              <section className="p-4 text-gray-500 space-y-1">
                <div className="flex items-center gap-x-2">
                  <FontAwesomeIcon icon={faUserCircle} />
                  <p>{ b.firstName } { b.lastName }, {b.jobTitle}</p>
                </div>
                <div className="flex items-center gap-x-2">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <p>{ b.email }</p>
                </div>
                <div className="flex items-center gap-x-2">
                  <FontAwesomeIcon icon={faPhone} />
                  <p>{ b.phone }</p>
                </div>
              </section>
              <section className="p-4 truncate ">
                <p
                  className="truncate"
                  data-tooltip-id="note"
                  data-tooltip-content={b.note}
                >
                  <Tooltip id="note"/>
                  <b>Komentar: </b> { b.note ? b.note : '/' }
                </p>
              </section>
              {!b.previewed &&
                <section className="flex items-center justify-between p-4">
                  <div className="w-1/2 mr-3">

                    <Button
                      bg="primary"
                      className="w-full text-sm tracking-tighter"
                      size="small"
                      onClick={() => markAsReadHandler([b.id])}
                    >
                      Označi kao pročitano
                    </Button>
                  </div>
                  <div className="h-full flex items-center justify-center">
                    <CheckBox
                      size="meidium"
                      value={() => checkIsSelected(b.id)}
                      wrapperClassNames="flex items-center justify-center"
                      onChange={(checked: boolean) => selectedItemsHandler(b.id)}
                    />
                  </div>
                </section>
              }
            </div>
            // <div key={b.id} className={`flex items-center justify-between gap-x-5 py-5 border-b hover:bg-gray-100/50 ${!b.previewed && 'bg-gradient-to-r from-gray-50 via-gray-50 to-base-500/10'}`}>
            //   <div className="w-6/12 pl-5 tracking-wider space-y-0.5">
            //     <p>Ime i prezime: <b>{ b.firstName } { b.lastName }</b></p>
            //     <p>Kompanija: <b>{ b.companyName }</b></p>
            //     <p>Pozicija: <b>{ b.jobTitle }</b></p>
            //     <p>Broj zaposlenih: <b>{ b.numberOfEmployees }</b></p>
            //     <p>Država: <b>{ b.country }</b></p>
            //     <p>Email: <b>{ b.email }</b></p>
            //     <p>Telefon: <b>{ b.phone }</b></p>
            //   </div>
            //   <div className="flex items-center gap-x-8">
            //     <div className="w-40 mr-3">
            //       {!b.previewed &&
            //         <Button
            //           bg="primary"
            //           className="w-full text-sm tracking-tighter"
            //           size="small"
            //           onClick={() => markAsReadHandler([b.id])}
            //         >
            //           Označi kao pročitano
            //         </Button>
            //       }
            //     </div>
            //     <div className="w-20 h-full flex items-center justify-center">
            //       {!b.previewed &&
            //         <CheckBox
            //           size="meidium"
            //           value={() => checkIsSelected(b.id)}
            //           wrapperClassNames="flex items-center justify-center transform -translate-x-12"
            //           onChange={(checked: boolean) => selectedItemsHandler(b.id)}
            //         />
            //       }
            //     </div>
            //   </div>
            // </div>
          )}
        </section>
      }
      {bookings.length === 0 &&
        <div className="h-[calc(100vh-190px)] text-gray-600 flex items-center justify-center border rounded-lg">
          <div className="text-center space-y-4">
            <FontAwesomeIcon icon={faBullhorn} className="text-4xl"/>
            <p className="font-medium">Nema pronađenih sugestija!</p>
          </div>
        </div>
      }
    </div>
  );
};

export default Bookings;