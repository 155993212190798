import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPencil,
  faPhone,
  faShoppingBag,
  faTrashCan,
  faUser,
  faUserXmark
} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "react-tooltip";

interface ListViewProps {
  employees: any[];
  onEdit: (e: any, employee: any) => void;
  onPreview: (e: any, employee: any) => void;
  onPurchase: (e: any, employee: any) => void;
  onDelete: (e: any, employeeId: any) => void;
  onDeactivate: (e: any, employeeId: any) => void;
}

const ListView = ({ employees, onEdit, onDelete, onPreview, onDeactivate, onPurchase }: ListViewProps) => {

  const getNameInitials = (firstName: string, lastName: string): any => {
    if (firstName && lastName) return firstName.slice(0, 1) + lastName.slice(0, 1);
    if (firstName && !lastName) return firstName.slice(0, 1);
    if (!firstName && lastName) return lastName.slice(0, 1);
    if (!firstName && !lastName) return '';
  }

  return (
    <ul className={`my-9 space-y-4 w-full`}>
      {employees && employees.length > 0 && employees.map((employee: any) => (
        <li onClick={(e: any) => onPreview(e, employee)} key={employee.id}
            className="bg-white hover:bg-gray-50 flex items-center justify-between gap-x-2 shadow-sm rounded-lg p-5 transform hover:scale-[1.02] cursor-pointer animation duration-200 ease-in-out">
          <div className="w-3/12 flex items-center justify-start gap-x-3">
            <div className="w-14">
              {employee.profilePicture &&
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={employee.profilePicture}
                  alt={employee.firstName}
                />
              }
              {!employee.profilePicture &&
                <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-400 uppercase">
                  <span className="text-lg font-medium leading-none text-white tracking-wide">{getNameInitials(employee.firstName, employee.lastName)}</span>
                </span>
              }
            </div>
            <div>
              <p className={`capitalize font-medium`}>{employee.firstName} {employee.lastName}</p>
              <p>ID: {employee.id}</p>
            </div>
          </div>
          <div className="w-4/12 flex items-center gap-x-2">
            <FontAwesomeIcon className="text-gray-500" icon={faEnvelope}/>
            <p>{employee.email ? employee.email : '-'}</p>
          </div>
          <div className={`w-2/12 flex items-center gap-x-2`}>
            <FontAwesomeIcon className="text-gray-500" icon={faPhone}/>
            <p>{employee.phone ? employee.phone : '-'}</p>
          </div>
          <div className="w-2/12 flex items-center justify-center">
            {employee.isActive &&
                <span className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full ${employee.isCompanyAdmin ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'} px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                    {employee.isCompanyAdmin && employee.isActive ? 'Admin' : 'Korisnik'}
                  </span>
            }
            {!employee.isActive &&
                <span className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-gray-400 text-white ring-gray-50/20 px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                    Neaktivan
                  </span>
            }
          </div>
          <div className="w-2/12 flex items-center justify-end gap-x-2">
            <button
              onClick={(e: any) => onPurchase(e, employee)}
              disabled={!employee.isActive}
              data-tooltip-id="purchase"
              data-tooltip-content="Kupovina"
              className={`h-9 w-9 rounded-full flex items-center justify-center ${employee.isActive ? 'bg-green-600 hover:bg-green-500' : 'bg-gray-400'} text-white animation duration-200 ease-in-out`}>
              <Tooltip id="purchase"/>
              <FontAwesomeIcon icon={faShoppingBag} />
            </button>
            <button
              onClick={(e: any) => onEdit(e, employee)}
              disabled={!employee.isActive}
              data-tooltip-id="edit"
              data-tooltip-content="Izmeni"
              className={`h-9 w-9 rounded-full flex items-center justify-center ${employee.isActive ? 'bg-base-500 hover:bg-base-600' : 'bg-gray-400'} text-white animation duration-200 ease-in-out`}>
              <Tooltip id="edit"/>
              <FontAwesomeIcon icon={faPencil}/>
            </button>
            <button
              onClick={(e: any) => onDeactivate(e, employee.id)}
              data-tooltip-id="deactivate"
              data-tooltip-content="Deaktiviraj"
              disabled={!employee.isActive}
              className={`h-9 w-9 rounded-full flex items-center justify-center ${employee.isActive ? 'bg-yellow-600 hover:bg-yellow-500' : 'bg-gray-400'} text-white animation duration-200 ease-in-out`}>
              <Tooltip id="deactivate"/>
              <FontAwesomeIcon icon={faUserXmark}/>
            </button>
            <button
              onClick={(e: any) => onDelete(e, employee.id)}
              data-tooltip-id="delete"
              data-tooltip-content="Izbriši"
              className={`h-9 w-9 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-500 text-white animation duration-200 ease-in-out`}>
              <Tooltip id="delete"/>
              <FontAwesomeIcon icon={faTrashCan}/>
            </button>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default ListView;