import http from 'libs/http';


class SubscriptionApi {
  exportSubscriptions = () => {
    return http.get('/v2/admin/subscriptions/export/', { responseType: 'blob' });
  };
}


export default SubscriptionApi;