import React from 'react';
import Button from 'components/Button';
import { exportSubscriptions } from 'modules/subscription/services/subscription.store';
import {useDispatch} from 'react-redux';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Subscriptions = () => {

  const dispatch = useDispatch();

  const exportSubscriptionsHandler = async (): Promise<void> => {
    try {
      const response: any = await dispatch(exportSubscriptions());
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "HOB-subscriptions.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (e: any) {
      console.log(e, 'err');
    }
  };

  return(
    <div className="w-64">
      <Button
        bg="primary"
        className="w-full flex items-center justify-center gap-x-2"
        size="regular"
        onClick={exportSubscriptionsHandler}
      >
        <FontAwesomeIcon icon={faDownload}/>
        Preuzmi listu pretplata
      </Button>
    </div>
  );
};

export default Subscriptions;