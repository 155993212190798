import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { modalActions } from 'store/modal/modal.store';
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ConfirmMessageDialogProps {
  title: string;
  description: string;
  onConfirm: () => void;
}

const ConfirmMessagePrompt = ({ title, description, onConfirm }: ConfirmMessageDialogProps) => {

  const dispatch: any = useDispatch();
  const handleClose = () => {
    // e.stopPropagation();
    dispatch(modalActions.closeModal());
  }

  return (
    <div>
      <section className="py-5 text-gray-600 text-center flex items-center justify-center">
        <div>
          <div className="flex items-center justify-center bg-base-600 rounded-full mx-auto h-10 w-10">
            <FontAwesomeIcon icon={faEnvelope} className="text-xl text-white" />
          </div>
          {title &&
            <p className="text-lg font-bold mt-2">{ title }</p>
          }
        </div>
      </section>
      {description &&
        <section className="text-center text-gray-600 border-y p-4">
          <p>{ description }</p>
        </section>
      }
      <section className="flex items-center justify-center gap-x-6 py-5">
        <Button
          bg="outline-primary"
          className="w-36"
          size="regular"
          onClick={handleClose}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-36"
          size="regular"
          onClick={onConfirm}
        >
          Pošalji
        </Button>
      </section>
    </div>
  );
};

export default ConfirmMessagePrompt;
