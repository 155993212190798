import React, {useEffect, useState} from 'react';
import Input from 'components/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { providerActions } from 'modules/provider/services/provider.store';
import { faArrowDownAZ, faArrowDownZA, faGrip, faListUl } from '@fortawesome/free-solid-svg-icons';



const AdminFilters = () => {

  const dispatch: any = useDispatch();
  const filters: any = useSelector((state: any) => state.providerStore.filters, shallowEqual);

  const setSearchString = (payload: string) => {
    dispatch(providerActions.setSearchString(payload));
  };

  const setPreviewTypeHandler = (option: any): void => {
    dispatch(providerActions.setPreviewType(option));
  };

  return(
    <section className="mt-6 flex items-center justify-between">
      <div className="flex items-center gap-x-3">
        <div className="lg:w-[412px]">
          <Input
            type='text'
            isSearch
            height='regular'
            debounceTime={400}
            placeholder="Pretraži po imenu ili ID administratora"
            onChange={setSearchString}
          />
        </div>
      </div>
      <div className="flex items-center justify-center gap-x-2">
        <div className="flex items-center justify-center gap-x-2">
          <div
            className="relative z-0 hidden md:flex justify-end items-end rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
            <button
              onClick={() => setPreviewTypeHandler('grid')}
              type="button"
              className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-l-md border border-gray-300 ${filters.previewType === 'grid' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faGrip}/>
            </button>
            <button
              onClick={() => setPreviewTypeHandler('list')}
              type="button"
              className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-r-md border border-gray-300 ${filters.previewType === 'list' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faListUl}/>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminFilters;