import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faPencil,
  faTrashCan,
  faUserXmark,
  faCopy
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from 'react-tooltip';
import {useNavigate} from 'react-router-dom';
import {countryOptions} from 'libs/countryOptions';


interface GridViewProps {
  clients: any[];
  onEdit: (e: any, client: any) => void;
  onCopy: (e: any, client: any) => void;
  onDelete: (e: any, clientId: any) => void;
}

const ClientsGridView = ({ clients, onEdit, onDelete, onCopy }: GridViewProps) => {

  const navigate = useNavigate();

  const getCountry = (country: any) => {
    const target: any = countryOptions.find((c: any) => c.key === country);
    return target.label;
  };


  return (
    <ul role="list" className={`grid grid-cols-1 gap-7 sm:grid-cols-2 lg:grid-cols-3 my-9`}>
      {clients.map((client: any) => (
        <li
          key={`${client.id}-client-grid`}
          onClick={() => navigate(`/clients/${client.id}/employees`)}
          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow transform hover:scale-[1.03] cursor-pointer animation duration-200 ease-in-out">
          <div className="flex w-full items-center justify-between space-x-6 p-6 pb-4">
            <div className="truncate">
              <div className="flex items-center space-x-3">
                <h3 className={`truncate font-medium text-gray-900 capitalize`}>
                  {client.name}
                </h3>
                <span className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-gray-400 text-white ring-gray-50/20 px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                  {getCountry(client.country)}
                </span>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{client.position}</p>
              <div
                className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py0.5 text-sm font-medium text-yellow-800">
                <svg className="size-1.5 fill-yellow-500" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx="3" cy="3" r="3"/>
                </svg>
                ID: {client.id}
              </div>
              {/*<p className=" truncate text-sm text-gray-500">ID: {client.id}</p>*/}
            </div>
            <div>
              <div>
                {!client.picture &&
                  <span className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-400 uppercase">
                <span className="text-lg font-medium leading-none text-white tracking-wide">{client.name.slice(0, 1)}</span>
              </span>
                }
                {client.picture &&
                  <img
                    className="inline-block h-14 w-14 rounded-full"
                    src={client.picture}
                    alt={client.name}
                  />
                }
              </div>
              {/*<div className="flex justify-end mt-2">*/}
              {/*  <span*/}
              {/*    className="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-1.5 py0.5 text-sm font-medium text-red-800">*/}
              {/*    <svg className="size-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">*/}
              {/*      <circle cx="3" cy="3" r="3"/>*/}
              {/*    </svg>*/}
              {/*    {getCountry(client.country)}*/}
              {/*  </span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div>
            <div className="flex divide-x divide-gray-200">
              <div className="w-1/2 flex items-center divide-x py-1.5 text-sm -space-y-1">
                <div
                  data-tooltip-id="email"
                  data-tooltip-content={client.email}
                  className="w-1/2 h-10 flex items-center justify-center"
                >
                  <Tooltip id="email"/>
                  <a href={`mailto:${client.email}`}
                     className="inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent font-semibold text-gray-900">
                    <FontAwesomeIcon className="text-gray-400 text-xl" icon={faEnvelope}/>
                  </a>
                </div>
                <div
                  data-tooltip-id="phone"
                  data-tooltip-content={client.phone ? client.phone : 'Nema broja'}
                  className="w-1/2 h-10 flex items-center justify-center"
                >
                  <Tooltip id="phone"/>
                  <a
                    href={`tel:${client.phone}`}
                    className="inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent font-semibold text-gray-900"
                  >
                    <FontAwesomeIcon className="text-gray-400 text-xl" icon={faPhone}/>
                    {/*Call*/}
                  </a>
                </div>
              </div>
              <div className="w-1/2 flex items-center justify-around px-2.5">
                <button
                  onClick={(e: any) => onEdit(e, client)}
                  data-tooltip-id="edit"
                  data-tooltip-content="Izmeni"
                  className={`h-8 w-8 rounded-full flex items-center justify-center bg-base-500 hover:bg-base-600 text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="edit"/>
                  <FontAwesomeIcon icon={faPencil}/>
                </button>
                <button
                  data-tooltip-id="copy"
                  data-tooltip-content="Kopiraj"
                  onClick={(e: any) => onCopy(e, client)}
                  className={`h-8 w-8 rounded-full flex items-center justify-center bg-yellow-500 hover:bg-yellow-700 text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="copy"/>
                  <FontAwesomeIcon icon={faCopy}/>
                </button>
                <button
                  onClick={(e: any) => onDelete(e, client.id)}
                  data-tooltip-id="delete"
                  data-tooltip-content="Izbriši"
                  className={`h-8 w-8 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-700 text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="delete"/>
                  <FontAwesomeIcon icon={faTrashCan}/>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default ClientsGridView;