import http from 'libs/http';


class SuggestionsApi {
  getSuggestions = (url: string) => {
    return http.get(url);
  };

  getUnreadSuggestionsCount = () => {
    return http.get('/v2/admin/suggestions/?reviewed=false');
  };

  markAsRead = (payload: any) => {
    return http.patch('/v2/admin/suggestions/bulk-update/', payload);
  }
}


export default SuggestionsApi;