import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faPencil,
  faTrashCan,
  faUserXmark,
  faShoppingBag
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from 'react-tooltip';


const people = [
  {
    name: 'Jane Cooper',
    title: 'Regional Paradigm Technician',
    role: 'Admin',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  // More people...
]

interface GridViewProps {
  employees: any[];
  onEdit: (e: any, employee: any) => void;
  onPreview: (e: any, employee: any) => void;
  onPurchase: (e: any, employee: any) => void;
  onDelete: (e: any, employeeId: any) => void;
  onDeactivate: (e: any, employeeId: any) => void;
}

const GridView = ({ employees, onEdit, onDelete, onDeactivate, onPreview, onPurchase }: GridViewProps) => {

  const getNameInitials = (firstName: string, lastName: string): any => {
    if (firstName && lastName) return firstName.slice(0, 1) + lastName.slice(0, 1);
    if (firstName && !lastName) return firstName.slice(0, 1);
    if (!firstName && lastName) return lastName.slice(0, 1);
    if (!firstName && !lastName) return '';
  }

  return (
    <ul role="list" className={`grid grid-cols-1 gap-7 sm:grid-cols-2 lg:grid-cols-3 my-9`}>
      {employees.map((employee: any) => (
        <li onClick={(e: any) => onPreview(e, employee)} key={employee.email} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow transform hover:scale-[1.03] cursor-pointer animation duration-200 ease-in-out">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className={`truncate font-medium text-gray-900 capitalize`}>{employee.firstName} {employee.lastName}</h3>
                {employee.isActive &&
                  <span className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full ${employee.isCompanyAdmin ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'} px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                    {employee.isCompanyAdmin && employee.isActive ? 'Admin' : 'Korisnik'}
                  </span>
                }
                {!employee.isActive &&
                  <span className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-gray-400 text-white ring-gray-50/20 px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                    Neaktivan
                  </span>
                }
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{employee.position}</p>
              <p className=" truncate text-sm text-gray-500">ID: {employee.id}</p>
            </div>
            {!employee.profilePicture &&
                <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-400 uppercase">
                <span className="text-lg font-medium leading-none text-white tracking-wide">{getNameInitials(employee.firstName, employee.lastName)}</span>
              </span>
            }
            {employee.profilePicture &&
              <img
                className="inline-block h-12 w-12 rounded-full"
                src={employee.profilePicture}
                alt={employee.firstName}
              />
            }
          </div>
          <div>
            <div className="flex divide-x divide-gray-200">
              <div className="w-1/2 flex items-center divide-x py-1.5 text-sm -space-y-1">
                <div
                  data-tooltip-id="email"
                  data-tooltip-content={employee.email}
                  className="w-1/2 h-10 flex items-center justify-center"
                >
                  <Tooltip id="email"/>
                  <a href={`mailto:${employee.email}`}
                     className="inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent font-semibold text-gray-900">
                    <FontAwesomeIcon className="text-gray-400 text-xl" icon={faEnvelope}/>
                  </a>
                </div>
                <div
                  data-tooltip-id="phone"
                  data-tooltip-content={employee.phone ? employee.phone : 'Nema broja'}
                  className="w-1/2 h-10 flex items-center justify-center"
                >
                  <Tooltip id="phone"/>
                  <a
                    href={`tel:${employee.phone}`}
                    className="inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent font-semibold text-gray-900"
                  >
                    <FontAwesomeIcon className="text-gray-400 text-xl" icon={faPhone}/>
                    {/*Call*/}
                  </a>
                </div>
              </div>
              <div className="w-1/2 flex items-center justify-around px-2.5">
                <button
                  onClick={(e: any) => onPurchase(e, employee)}
                  data-tooltip-id="purchase"
                  data-tooltip-content="Kupovina"
                  disabled={!employee.isActive}
                  className={`h-8 w-8 rounded-full flex items-center justify-center ${employee.isActive ? 'bg-green-600 hover:bg-green-500' : 'bg-gray-400 pointer-events-none'} text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="purchase"/>
                  <FontAwesomeIcon icon={faShoppingBag}/>
                </button>
                <button
                  onClick={(e: any) => onEdit(e, employee)}
                  data-tooltip-id="edit"
                  data-tooltip-content="Izmeni"
                  disabled={!employee.isActive}
                  className={`h-8 w-8 rounded-full flex items-center justify-center ${employee.isActive ? 'bg-base-500 hover:bg-base-600' : 'bg-gray-400 pointer-events-none'} text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="edit"/>
                  <FontAwesomeIcon icon={faPencil}/>
                </button>
                <button
                  onClick={(e: any) => onDeactivate(e, employee.id)}
                  data-tooltip-id="deactivate"
                  data-tooltip-content="Deaktiviraj"
                  disabled={!employee.isActive}
                  className={`h-8 w-8 rounded-full flex items-center justify-center ${employee.isActive ? ' bg-yellow-600 hover:bg-yellow-700' : 'bg-gray-400 pointer-events-none'} text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="deactivate"/>
                  <FontAwesomeIcon icon={faUserXmark}/>
                </button>
                <button
                  onClick={(e: any) => onDelete(e, employee.id)}
                  data-tooltip-id="delete"
                  data-tooltip-content="Izbriši"
                  className={`h-8 w-8 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-700 text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="delete"/>
                  <FontAwesomeIcon icon={faTrashCan}/>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default GridView;
