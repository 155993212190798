import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faPencil,
  faTrashCan,
  faUserXmark,
  faCopy
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from 'react-tooltip';
import {useNavigate} from 'react-router-dom';


interface GridViewProps {
  administrators: any[];
  onEdit: (e: any, admin: any) => void;
  onDelete: (e: any, adminId: any) => void;
}

const AdminsGridView = ({ administrators, onEdit, onDelete }: GridViewProps) => {

  return (
    <ul role="list" className={`grid grid-cols-1 gap-7 sm:grid-cols-2 lg:grid-cols-3 my-9`}>
      {administrators.map((administrator: any) => (
        <li
          key={administrator.id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow transform hover:scale-[1.03] cursor-pointer animation duration-200 ease-in-out">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className={`truncate font-medium text-gray-900 capitalize`}>
                  {administrator.firstName} {administrator.lastName}
                </h3>
                <span className={`mt-0.5 inline-flex flex-shrink-0 items-center rounded-full bg-gray-400 text-white ring-gray-50/20 px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset`}>
                  {administrator.company}
                </span>
              </div>
              {/*<p className="mt-1 truncate text-sm text-gray-500">{provider.position}</p>*/}
              <p className=" truncate text-sm text-gray-500">ID: {administrator.id}</p>
            </div>
            {!administrator.picture &&
              <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-400 uppercase">
                <span className="text-lg font-medium leading-none text-white tracking-wide">{administrator.firstName.slice(0, 1)} {administrator.lastName.slice(0, 1)}</span>
              </span>
            }
            {administrator.picture &&
              <img
                className="inline-block h-12 w-12 rounded-full"
                src={administrator.picture}
                alt={administrator.email}
              />
            }
          </div>
          <div>
            <div className="flex divide-x divide-gray-200">
              <div className="w-1/2 flex items-center divide-x py-1.5 text-sm -space-y-1">
                <div
                  data-tooltip-id="email"
                  data-tooltip-content={administrator.email}
                  className="w-1/2 h-10 flex items-center justify-center"
                >
                  <Tooltip id="email"/>
                  <a href={`mailto:${administrator.email}`}
                     className="inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent font-semibold text-gray-900">
                    <FontAwesomeIcon className="text-gray-400 text-xl" icon={faEnvelope}/>
                  </a>
                </div>
                <div
                  data-tooltip-id="phone"
                  data-tooltip-content={administrator.phone ? administrator.phone : 'Nema broja'}
                  className="w-1/2 h-10 flex items-center justify-center"
                >
                  <Tooltip id="phone"/>
                  <a
                    href={`tel:${administrator.phone}`}
                    className="inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent font-semibold text-gray-900"
                  >
                    <FontAwesomeIcon className="text-gray-400 text-xl" icon={faPhone}/>
                    {/*Call*/}
                  </a>
                </div>
              </div>
              <div className="w-1/2 flex items-center justify-evenly px-2.5">
                <button
                  onClick={(e: any) => onEdit(e, administrator)}
                  data-tooltip-id="edit"
                  data-tooltip-content="Izmeni"
                  className={`h-8 w-8 rounded-full flex items-center justify-center bg-base-600 hover:bg-base-500 text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="edit"/>
                  <FontAwesomeIcon icon={faPencil}/>
                </button>
                <button
                  onClick={(e: any) => onDelete(e, administrator.id)}
                  data-tooltip-id="delete"
                  data-tooltip-content="Izbriši"
                  className={`h-8 w-8 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-700 text-white animation duration-200 ease-in-out`}>
                  <Tooltip id="delete"/>
                  <FontAwesomeIcon icon={faTrashCan}/>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default AdminsGridView;