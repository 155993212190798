import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import BookingApi from './booking.api';
import { loaderActions } from 'store/loader/loader.store';


const api: BookingApi = new BookingApi();


interface BookingState {
  bookings: any;
  nextPage: number | null;
  count: string | number;
  status: any;
  unreadBookingsCount: number;
}

const initialState: BookingState = {
  bookings: [],
  nextPage: null,
  count: -1,
  status: null,
  unreadBookingsCount: 0,
};


const bookingSlice: any = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookings: (state: any, action: PayloadAction<any, any>): any => {
      const rawBookings: any[] = [...state.bookings, ...action.payload];

      console.log(action.payload, 'payload')

      const uniqueBookingsMap: Map<any, any> = new Map();

      rawBookings.forEach(booking => {
        if (booking && booking.id) {
          uniqueBookingsMap.set(booking.id, booking);
        }
      });

      state.bookings = Array.from(uniqueBookingsMap.values());
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.count = action.payload;
    },
    resetBookings: (state: any): any => {
      state.bookings = [];
    },
    setStatus: (state: any, action: PayloadAction<any, any>): any => {
      state.status = action.payload;
    },
    setUnreadBookingsCount: (state: any, action: PayloadAction<any, any>): any => {
      state.unreadBookingsCount = action.payload;
    },
    decrementUnreadBookingsCount: (state: any, action: PayloadAction<any, any>): any => {
      state.unreadBookingsCount -= action.payload;
    },
    setBookingRead: (state: any, action: PayloadAction<any, any>): any => {
      const reviewedIds = action.payload;
      reviewedIds.forEach((reviewedId: any) => {
        state.bookings.forEach((b: any) => {
          if (b.id === reviewedId) b.reviewed = true;
        })
      })
    },
  },
});


export const getBookings = (resetData: boolean = false) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage = getState().bookingStore.nextPage;
      const status = getState().bookingStore.status;
      let url: string = `/v2/admin/book-demo/`;
      if (status && status.label) {
        url += `?previewed=${status.value}`;
      }

      if (nextPage && !resetData) {
        url = nextPage;
      }


      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getBookings(url);
      dispatch(bookingActions.setNextPage(data.next));
      dispatch(bookingActions.setCount(data.count));
      dispatch(bookingActions.setBookings(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const markAsRead = (payload: any, clientId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.markAsRead(payload);
      dispatch(bookingActions.setBookingRead(payload.ids));
      dispatch(bookingActions.decrementUnreadBookingsCount(payload.ids.length));
      dispatch(getBookings(true));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const getUnreadBookingsCount = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.getUnreadBookingsCount();
      dispatch(bookingActions.setUnreadBookingsCount(data.count));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};




export const bookingActions: any = bookingSlice.actions;

export default bookingSlice.reducer;