import { Checkbox } from '@headlessui/react'
import React, {useEffect, useState} from 'react'

interface CheckBoxProps {
  title?: string;
  size?: string;
  value?: any;
  disabled?: boolean;
  textClassNames?: string;
  wrapperClassNames?: string;
  onChange: (value: boolean) => void;
}

const   CheckBox = ({ title, size, value, disabled, textClassNames, wrapperClassNames, onChange }: CheckBoxProps) => {

  const [localValue, setLocalValue] = useState(false);

  const setValueHandler = () => {
    setLocalValue(!value)
    onChange(!value);
  }

  useEffect(() => {
    if (value) setLocalValue(value);
  }, [value]);

  const calculateSize = (size: any) => {
    switch (size) {
      case 'small':
        return 'size-6';
      case 'regular':
        return 'size-[38px]';
      case 'large':
        return 'size-10';
      default:
        return 'size-8';
    }
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  }

  return (
    <div onClick={stopPropagation} className={`select-none cursor-pointer group ${wrapperClassNames}`}>
      {/*<label className="block text-gray-700 text-sm font-bold mb-1 invisible">*/}
      {/*  Placeholder*/}
      {/*</label>*/}
      <div onClick={!disabled ? () => setValueHandler() : () => null} className={`flex items-center gap-x-2`}>
        <Checkbox
          checked={localValue}
          disabled={disabled}
          onChange={!disabled ? () => setValueHandler() : () => null}
          className={`group block ${calculateSize(size)} rounded-lg border bg-white data-[checked]:bg-base-500 ${!disabled && 'group-hover:border-base-500'}animation duration-300 ease-in-out`}
        >
          <svg className="stroke-white opacity-0 group-data-[checked]:opacity-100" viewBox="0 0 14 14" fill="none">
            <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </Checkbox>
        {title &&
          <div className={textClassNames}>
            {title}
          </div>
        }
      </div>
    </div>
  );
};

export default CheckBox;