import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import AnalyticsApi from './analytics.api';
import { setHeaders } from 'libs/http';
import { loaderActions } from 'store/loader/loader.store';


const api: AnalyticsApi = new AnalyticsApi();


interface AnalyticsState {
  budgets: any;
}

const initialState: AnalyticsState = {
  budgets: null,
};


const analyticsSlice: any = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setBudgets: (state: any, action: PayloadAction<AnalyticsState, any>): any => {
      state.budgets = action.payload;
    },
  },
});


export const getBudgets = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getBudgets();
      dispatch(loaderActions.setLoading(false));
      dispatch(analyticsActions.setBudgets(data));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
}




export const analyticsActions: any = analyticsSlice.actions;

export default analyticsSlice.reducer;
