import React, {useEffect, useState} from 'react';


import { useDispatch } from 'react-redux';
import {
  faChevronDown,
  faShop,
  faUserGear,
  faShopLock,
  faBuildingLock,
  faChartColumn,
  faMoneyBillTransfer,
  faArrowRightArrowLeft,
  faBarsProgress,
  faCommentDots,
  faAddressCard,
  faArrowRightFromBracket, faStore, faBuilding, faPenToSquare, faMoneyBill, faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import {NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import { authActions } from 'modules/auth/services/auth.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems, Switch } from '@headlessui/react';


const SubHeader = () => {

  const params: any = useParams();
  const location: any = useLocation();
  const navigate: any = useNavigate();

  // useEffect(() => {
  //   console.log(params, 'params')
  //   console.log(location, 'location')
  // }, [location, params]);

  if (location.pathname === '/') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faChartColumn} className="text-2xl mx-auto w-6" />
        <p>ANALITIKA</p>
      </div>
    )
  }

  if (location.pathname.includes('/providers')) {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faStore} className="text-2xl mx-auto w-6" />
        <p>BENEFIT PROVAJDERI</p>
      </div>
    )
  }

  if (location.pathname === '/clients') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faBuilding} className="text-2xl mx-auto w-6" />
        <p>KLIJENTI</p>
      </div>
    )
  }

  if (location.pathname.includes('/employees') || location.pathname.includes('/fixed-benefits') || location.pathname.includes('/restrictions')) {

    const clientId: string = location.pathname.split('/')[2];

    return (
      <div className="w-full text-white flex items-center cursor-pointer font-medium text-center">
        <div onClick={() => navigate(`/clients/${clientId}/employees`)} className={`w-1/3 flex items-center justify-center gap-x-2 bg-base-500 shadow rounded-l-lg h-[45px] hover:bg-base-600 animation duration-200 ease-in-out ${location.pathname.includes('/employees') && 'border-b-4 border-white'}`}>
          <FontAwesomeIcon icon={faBuilding} className="text-2xl w-6"/>
          <p>Korisnici</p>
        </div>
        <div onClick={() => navigate(`/clients/${clientId}/fixed-benefits`)} className={`w-1/3 flex items-center justify-center h-[45px] gap-x-2 bg-base-500 hover:bg-base-600 animation duration-200 ease-in-out ${location.pathname.includes('/fixed-benefits') && 'border-b-4 border-white'}`}>
          <FontAwesomeIcon icon={faBuildingLock} className="text-2xl w-6"/>
          <p>Fiksni benefiti</p>
        </div>
        <div onClick={() => navigate(`/clients/${clientId}/restrictions`)} className={`w-1/3 flex items-center justify-center gap-x-2 bg-base-500 shadow  rounded-r-lg h-[45px] hover:bg-base-600 animation duration-200 ease-in-out ${location.pathname.includes('/restrictions') && 'border-b-4 border-white'}`}>
          <FontAwesomeIcon icon={faShopLock} className="text-2xl w-6"/>
          <p>Ograničenja</p>
        </div>
      </div>
    )
  }

  if (location.pathname === '/transactions') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faArrowRightArrowLeft} className="text-2xl mx-auto w-6" />
        <p>KUPOVINE</p>
      </div>
    )
  }

  if (location.pathname === '/downloads') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faBarsProgress} className="text-2xl mx-auto w-6" />
        <p>PREUZIMANJA</p>
      </div>
    )
  }

  if (location.pathname === '/suggestions') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faCommentDots} className="text-2xl mx-auto w-6" />
        <p>SUGESTIJE</p>
      </div>
    )
  }

  if (location.pathname === '/reimbursements') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faMoneyBillTransfer} className="text-2xl mx-auto w-6" />
        <p>REFUNDACIJE</p>
      </div>
    )
  }

  if (location.pathname === '/contact') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faAddressCard} className="text-2xl mx-auto w-6" />
        <p>KONTAKT</p>
      </div>
    )
  }

  if (location.pathname === '/demo-bookings') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faPenToSquare} className="text-2xl mx-auto w-6" />
        <p>DEMO PRIJAVE</p>
      </div>
    )
  }

  if (location.pathname === '/notifications') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faEnvelope} className="text-2xl mx-auto w-6" />
        <p>PORUKE</p>
      </div>
    )
  }

  if (location.pathname === '/subscriptions') {
    return (
      <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
        <FontAwesomeIcon icon={faMoneyBill} className="text-2xl mx-auto w-6" />
        <p>PRETPLATE</p>
      </div>
    )
  }

  return (
    <div className="text-white flex items-center gap-x-2 pl-3 font-medium">
      <FontAwesomeIcon icon={faShop} className="text-2xl mx-auto w-6" />
      <p>HOB</p>
    </div>
  );
};

const Header = () => {

  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const user = useSelector((state: any) => state.authStore.user);
  const isOpenSidebar: boolean = useSelector((state: any) => state.sidebarStore.isOpen);
  const [isUser, setIsUser] = useState(false);

  const logOutHandler = (): void => {
    dispatch(authActions.logOut());
  }

  const getNameInitials = (firstName: string, lastName: string): any => {
    if (firstName && lastName) return firstName.slice(0, 1) + lastName.slice(0, 1);
    if (firstName && !lastName) return firstName.slice(0, 1);
    if (!firstName && lastName) return lastName.slice(0, 1);
    if (!firstName && !lastName) return '';
  }

  const goToAnalytics = (): void => {
    navigate('/');
  }

  const switchToUserView = (): void => {
    setIsUser(true);
    // @ts-ignore
    window.location.href = process.env.REACT_APP_USER_DOMEN_URL;
  }

  return (
    <header className="sticky top-0 bg-primary-50 pt-1 z-40">
      <div onClick={goToAnalytics}>
        <img onClick={goToAnalytics} src="/logo2.png" alt="logo" className={`fixed top-8 lg:top-7 z-30 w-24 h-12 xl:w-28 xl:h-14 ml-2 md:ml-3.5 lg:ml-3.5 xl:ml-5 cursor-pointer animation duration-300 ease-in-out ${isOpenSidebar ? 'transform translate-x-0 opacity-100' : 'transform -translate-x-40 opacity-0'}`} />
        <img onClick={goToAnalytics} src="/logo1.png" alt="logo" className={`fixed top-8 lg:top-7 z-30 w-12 h-12 md:w-12 md:h-12 ml-4 md:ml-9 xl:ml-11 cursor-pointer animation duration-300 ease-in-out ${!isOpenSidebar ? 'transform translate-x-0 opacity-100' : 'transform -translate-x-40 opacity-0'}`} />
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="relative h-[72px] bg-gradient-to-r from-base-500 to-base-600 mt-4 ml-[130px] lg:ml-[113px] mr-5 lg:mr-[40px] rounded-xl">
          <div className="h-full px-6 pt-4 flex items-start justify-end">
            <div className="flex items-start gap-x-3">
              <p className="text-white font-bold">{user.firstName} {user.lastName}</p>
              <Menu as="div">
                <MenuButton className="rounded-full focus:outline-none z-20 relative">
                  {user.picture &&
                      <div>
                          <img className="h-[36px] w-[36px] rounded-full border-2 border-white"
                               src={user.company.picture} alt="profile pic"/>
                          <FontAwesomeIcon icon={faChevronDown}
                                           className="text-xs text-white transform -translate-y-2"/>
                      </div>
                  }
                  {!user.picture &&
                      <>
                          <div
                              className="bg-gray-400 text-gray-50 overflow-hidden w-[34px] h-[34px] border border-white rounded-full flex items-center justify-center uppercase tracking-wide">
                            {getNameInitials(user.firstName, user.lastName)}
                          </div>
                          <FontAwesomeIcon icon={faChevronDown}
                                           className="text-xs text-white transform -translate-y-2"/>
                      </>
                  }
                </MenuButton>
                <MenuItems className="z-10 font-normal origin-top-right absolute top-12 right-8 mt-4 w-64 rounded-md py-1 bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem>
                    <div className="h-16 flex px-4 py-2 gap-x-3">
                      <div>
                        {user.picture &&
                          <div>
                            <img className="h-12 w-12 rounded-full border-2 border-white shadow-md" src={user.company.picture}
                                 alt="profile pic"/>
                            <FontAwesomeIcon icon={faChevronDown}
                                             className="text-xs text-white transform -translate-y-2"/>
                          </div>
                        }
                        {!user.picture &&
                          <>
                            <div
                              className="bg-gray-400 text-gray-50 h-12 w-12 rounded-full overflow-hidden border border-white flex items-center justify-center uppercase tracking-wide">
                              {getNameInitials(user.firstName, user.lastName)}
                            </div>
                            <FontAwesomeIcon icon={faChevronDown}
                                             className="text-xs text-white transform -translate-y-2"/>
                          </>
                        }
                      </div>
                      <div className="text-gray-700 font-medium pt-2">
                        {user.company.name}
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="pb-2 pt-1 flex justify-center">
                      <NavLink to="/"
                               className=" w-5/6 text-base-500 inline-flex items-center px-2.5 py-1 border border-base-500 shadow-sm rounded-xl bg-transparent hover:bg-base-600 hover:text-white text-center justify-center">
                        Kontrolni panel
                      </NavLink>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="cursor-pointer border-t border-gray-200">
                      <p className="pl-4 font-bold text-black mt-2">Nalog</p>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/transactions"
                             className="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">
                      Kupovine
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/reimbursements"
                             className="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">
                      Refundacije
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/subscriptions"
                             className="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">
                      Pretplate
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/suggestions" className="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">
                      Sugestije
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <div className="cursor-pointer border-t border-gray-200">
                      <p className="pl-4 font-bold text-black mt-2">Podrška</p>
                    </div>
                  </MenuItem>

                  <MenuItem>
                    <a href="assets/tutorijal-2024.pdf" target="_blank" className="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">
                      Tutorijal
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <div onClick={switchToUserView} className="border-t cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white flex items-center justify-between">
                      <p>Korisnički nalog</p>
                      <Switch
                        checked={isUser}
                        onChange={switchToUserView}
                        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-base-600 focus:ring-offset-2"
                      >
                        <span className="sr-only">Use setting</span>
                        <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md" />
                        <span
                          aria-hidden="true"
                          className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                        />
                        <span
                          aria-hidden="true"
                          className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div>
                      <p onClick={logOutHandler} className="border-t flex items-center cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">
                        <span>Odjavite se</span>
                        <FontAwesomeIcon icon={faArrowRightFromBracket} className="ml-2"/>
                      </p>
                    </div>
                  </MenuItem>
                </MenuItems>
              </Menu>
              {/*<Menu as="div">*/}
              {/*  <MenuButton className="rounded-full focus:outline-none z-20 relative">*/}
              {/*    {user.profilePicture &&*/}
              {/*        <div>*/}
              {/*            <img className="h-8 w-8 rounded-full border-2 border-white" src={user.profilePicture} alt="profile pic"/>*/}
              {/*            <FontAwesomeIcon icon={faChevronDown} className="text-xs text-white transform -translate-y-2"/>*/}
              {/*        </div>*/}
              {/*    }*/}
              {/*    {!user.profilePicture &&*/}
              {/*        <>*/}
              {/*            <div className="bg-gray-400 text-gray-50 overflow-hidden h-8 w-8 border border-white rounded-full flex items-center justify-center uppercase tracking-wide">*/}
              {/*              {getNameInitials(user.firstName, user.lastName)}*/}
              {/*            </div>*/}
              {/*            <FontAwesomeIcon icon={faChevronDown} className="text-xs text-white transform -translate-y-2"/>*/}
              {/*        </>*/}
              {/*    }*/}
              {/*  </MenuButton>*/}
              {/*  <MenuItems*/}
              {/*    className="z-10 font-normal origin-top-right absolute top-12 right-8 mt-3 w-64 rounded-md py-1 bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
              {/*    <MenuItem>*/}
              {/*      <div onClick={switchToUserView} className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-base-600 hover:text-white flex items-center justify-between">*/}
              {/*        <p>Korisnički nalog</p>*/}
              {/*        <Switch*/}
              {/*          checked={isUser}*/}
              {/*          onChange={switchToUserView}*/}
              {/*          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-base-600 focus:ring-offset-2"*/}
              {/*        >*/}
              {/*          <span className="sr-only">Use setting</span>*/}
              {/*          <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md" />*/}
              {/*          <span*/}
              {/*            aria-hidden="true"*/}
              {/*            className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"*/}
              {/*          />*/}
              {/*          <span*/}
              {/*            aria-hidden="true"*/}
              {/*            className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"*/}
              {/*          />*/}
              {/*        </Switch>*/}
              {/*      </div>*/}
              {/*    </MenuItem>*/}
              {/*    <MenuItem>*/}
              {/*      <p onClick={logOutHandler} className="flex items-center cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-base-600 hover:text-white">*/}
              {/*        <span>Izloguj se</span>*/}
              {/*        <FontAwesomeIcon icon={faArrowRightFromBracket} className="ml-2"/>*/}
              {/*      </p>*/}
              {/*    </MenuItem>*/}
              {/*  </MenuItems>*/}
              {/*</Menu>*/}
            </div>
          </div>
          <div className="w-full absolute -bottom-5 left-0">
            <div className="w-10/12 mx-auto h-[45px] bg-base-500 rounded-lg flex items-center">
              <SubHeader />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;