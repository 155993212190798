import React, { useEffect, useState, useMemo } from 'react';
import useWidth from 'hooks/useWidth';
import { NavLink } from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { sidebarActions } from 'store/sidebar/sidebar.store';
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStore,
  faBuildingUser,
  faBarsProgress,
  faChevronRight,
  faCommentDots,
  faChartColumn,
  faMoneyBillTransfer,
  faArrowRightArrowLeft, faBuilding, faPenToSquare, faEnvelope, faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import {getUnreadSuggestionsCount} from 'modules/suggestions/services/suggestions.store';
import {getUnreadBookingsCount} from 'modules/booking/services/booking.store';




const Sidebar = () => {

  const dispatch: any = useDispatch();
  const location = useLocation();
  const isOpen: boolean = useSelector((state: any) => state.sidebarStore.isOpen, shallowEqual);
  const unreadBookingsCount: boolean = useSelector((state: any) => state.bookingStore.unreadBookingsCount, shallowEqual);
  const unreadSuggestionsCount: boolean = useSelector((state: any) => state.suggestionsStore.unreadSuggestionsCount, shallowEqual);

  const menuItems = useMemo(() => [
    { id: 1, name: 'Analitika', icon: faChartColumn, url: '/' },
    // { id: 2, name: 'Postavke', icon: faBuildingUser, url: '/employees' },
    { id: 2, name: 'Benefit provajderi', icon: faStore, url: '/providers' },
    { id: 3, name: 'Klijenti', icon: faBuilding, url: '/clients' },
    { id: 3, name: 'Kupovine', icon: faArrowRightArrowLeft, url: '/transactions' },
    { id: 4, name: 'Refundacije', icon: faMoneyBillTransfer, url: '/reimbursements' },
    { id: 9, name: 'Pretplate', icon: faMoneyBill, url: '/subscriptions' },
    { id: 5, name: 'Sugestije', icon: faCommentDots, url: '/suggestions', unreadCount: unreadSuggestionsCount },
    { id: 8, name: 'Poruke', icon: faEnvelope, url: '/notifications' },
    { id: 7, name: 'Demo prijave', icon: faPenToSquare, url: '/demo-bookings', unreadCount: unreadBookingsCount },
    { id: 6, name: 'Preuzimanja', icon: faBarsProgress, url: '/downloads' },
  ], [unreadSuggestionsCount, unreadBookingsCount]);


  const toggleSidebar = (): void => {
    dispatch(sidebarActions.toggleSidebar());
  };

  useEffect(() => {
    dispatch(getUnreadSuggestionsCount());
    dispatch(getUnreadBookingsCount());
  }, []);

  return (
    <aside className={`fixed top-0 left-0 flex items-center h-[100vh] z-30`}>
      <div className={`h-[83vh] sidebar ${isOpen ? 'open' : 'close'} mt-20 ml-5 mx-auto rounded-xl bg-blur bg-gradient-to-r from-blur-1 via-blur-2 to-blur-1 shadow animation duration-200 ease-in-out`}>
      {/*<div className={`h-[83vh] ${isOpen ? 'w-[300px]' : 'w-[96px]'} mt-20 ml-5 mx-auto rounded-xl bg-blur bg-gradient-to-r from-blur-1 via-blur-2 to-blur-1 shadow animation duration-200 ease-in-out`}>*/}
        <ul className="pt-7">
          <li
            onClick={toggleSidebar}
            className={`w-5 h-5 mb-6 mx-auto flex items-center justify-center bg-white rounded-full cursor-pointer`}>
            <FontAwesomeIcon icon={faChevronRight} className={`mx-auto ${isOpen ? 'transform rotate-180' : ''} animation duration-200 ease-in-out text-gray-500 hover:text-base-500`} />
          </li>
          {menuItems.map((route: any) =>
            <NavLink key={route.id} to={route.url}>
              <li className={`relative ${isOpen ? 'w-10/12' : 'w-14'} p-1 mt-2 ml-5 flex items-center justify-start hover:text-base-600 ${location.pathname === route.url ? 'text-base-500 bg-base-transparent_500' : 'text-gray-600'} hover:bg-white cursor-pointer rounded-lg animation duration-200 ease-in-out`}>
                {(route && route.unreadCount > 0) &&
                  <div className={`absolute right-0 -top-0.5 w-5 h-4.5 rounded-full tracking-tight bg-red-600 text-white font-black text-[9px] flex items-center justify-center`}>
                    {route.unreadCount}
                  </div>
                }
                <div className={`flex items-center gap-x-3 truncate ml-3 animation duration-200 ease-in-out`}>
                  <FontAwesomeIcon icon={route.icon} className="text-2xl mx-auto w-6" />
                  {isOpen && <p className="truncate">{route.name}</p>}
                </div>
              </li>
            </NavLink>
          )}
        </ul>
      </div>
    </aside>
  );
};


export default Sidebar;
