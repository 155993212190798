import React, { useEffect, useRef } from 'react';

import { toast } from 'react-toastify';
import Button from 'components/Button';
import Filters from 'modules/benefit/UI/Filters';
import GridView from 'modules/benefit/UI/GridView';
import ListView from 'modules/benefit/UI/ListView';
import { modalActions } from 'store/modal/modal.store';
import { ConfirmDeleteDialog } from 'components/Dialogs';
import {useLocation, useNavigate, useParams, useRoutes} from 'react-router-dom';
import AddBenefitForm from 'modules/benefit/UI/AddBenefitForm';
import EditBenefitForm from 'modules/benefit/UI/EditBenefitForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { exportXlsx } from 'modules/provider/services/provider.store';
import {faDownload, faIcons, faPlus, faUserTie} from '@fortawesome/free-solid-svg-icons';
import { benefitActions, deleteFixedBenefit, getBenefits } from 'modules/benefit/services/benefit.store';
import {cartActions} from 'modules/cart/services/cart.store';

type Mode = "purchase" | "page";

interface BenefitsProps {
  mode: Mode;
}

const Benefits = ({ mode }: BenefitsProps) => {

  const { providerId } = useParams();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const count: any = useSelector((state: any) => state.benefitStore.count, shallowEqual);
  const filters: any = useSelector((state: any) => state.benefitStore.filters, shallowEqual);
  const loading: any = useSelector((state: any) => state.loaderStore.loading, shallowEqual);
  const nextPage: any = useSelector((state: any) => state.benefitStore.nextPage, shallowEqual);
  const benefits: any = useSelector((state: any) => state.benefitStore.benefits, shallowEqual);
  const selectedProvider: any = useSelector((state: any) => state.providerStore.selectedProvider, shallowEqual);

  const countRef = useRef(count);
  const nextPageRef = useRef(nextPage);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === benefits.length;
  }, [count, benefits]);

    const openAddBenefitForm = (benefit: any = null) => {
    let modalContent: React.JSX.Element = (
      <AddBenefitForm copyData={benefit} providerId={providerId} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Dodaj benefit',
          size: 'regular',
        },
      })
    );
  };

  const openEditBenefitForm = (benefit: any, isPreview: boolean) => {
    let modalContent: React.JSX.Element = (
      <EditBenefitForm benefit={benefit} isPreview={isPreview} providerId={providerId} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: isPreview ? 'Pregled benefita' : 'Ažuriraj benefit',
          size: 'regular',
        },
      })
    );
  };

  const fetchMoreFixedBenefits = async (isFetchMore: boolean): Promise<void> => {
    const id: number = providerId || selectedProvider.id;
    try {
      mode === 'page' ? await dispatch(getBenefits(isFetchMore, id)) : await dispatch(getBenefits(isFetchMore, id, true));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const openDeletePrompt = (benefitId: number): void => {
    let modalContent: React.JSX.Element = (
      <ConfirmDeleteDialog
        title="Izbriši benefit"
        description="Da li zaista želiš da izbrišeš benefit?"
        onDelete={() => deleteFixedBenefitHandler(benefitId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };

  const deleteFixedBenefitHandler = (benefitId: any): void => {
    try {
      dispatch(deleteFixedBenefit(benefitId, providerId));
      dispatch(modalActions.closeModal());
      toast.success('Uspešno ste izbrisali benefit!');
    } catch (e: any) {
      dispatch(modalActions.closeModal());
      toast.error('Nešto nije u redu!');
    }
  };

  const addToCartHandler = (e: any, benefit: any): void => {
    e.preventDefault();
    dispatch(cartActions.addToCart(benefit));
  };

  const removeFromCartHandler = (benefitId: any): void => {
    dispatch(cartActions.removeFromCart(benefitId));
  };


  useEffect(() => {
    return () => {
      dispatch(benefitActions.setNextPage(null));
      dispatch(benefitActions.setCount(0));
      dispatch(benefitActions.resetBenefits());
    }
  }, []);

  useEffect(() => {
    dispatch(benefitActions.resetBenefits());
    fetchMoreFixedBenefits(false);
  }, [filters.searchString, filters.sortBy, filters.ordering]);

  useEffect(() => {
    dispatch(benefitActions.resetBenefits());
    fetchMoreFixedBenefits(false);
  }, [filters.onlyActive]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreFixedBenefits(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(benefitActions.setCount(0));
    dispatch(benefitActions.setNextPage(null));
    dispatch(benefitActions.resetBenefits());
  }, []);

  return (
    <>
      {mode === "page" &&
        <section className="flex items-center justify-between gap-x-5">
          <div className="lg:w-[302px]">
            <Button
              bg="primary"
              className="w-full"
              size="regular"
              onClick={() => openAddBenefitForm()}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2"/>
              Dodaj benefit
            </Button>
          </div>
          <div className="lg:w-[302px]">
            <Button
              bg="primary"
              className="w-full"
              size="regular"
              onClick={() => navigate(`/providers/${providerId}/administrators`)}
            >
              <FontAwesomeIcon icon={faUserTie} className="mr-2"/>
              Provajder admin
            </Button>
          </div>
        </section>
      }

      {mode === "page" && <Filters/>}

      {filters.previewType === 'grid' &&
        <GridView
          benefits={benefits}
          mode={mode}
          addToCart={(e, benefit: any) => addToCartHandler(e, benefit)}
          onCopy={(benefit: any) => openAddBenefitForm(benefit)}
          onEdit={(benefit: any) => openEditBenefitForm(benefit, false)}
          onPreview={(benefit: any) => navigate(`/benefits/${benefit.id}`)}
          onDelete={(benefitId: any) => openDeletePrompt(benefitId)}
        />
      }

      {filters.previewType === 'list' &&
        <ListView
          benefits={benefits}
          onCopy={(benefit: any) => openAddBenefitForm(benefit)}
          onEdit={(benefit: any) => openEditBenefitForm(benefit, false)}
          onPreview={(benefit: any) => navigate(`/benefits/${benefit.id}`)}
          onDelete={(benefitId: any) => openDeletePrompt(benefitId)}
        />
      }

      {benefits.length === 0 && !loading &&
        <div className="h-[55vh] flex items-center justify-center text-gray-600 text-sm font-medium">
          <div className="text-center space-y-4">
            <FontAwesomeIcon icon={faIcons} className="text-4xl"/>
            <p className="font-medium">Nema pronađenih benefita!</p>
          </div>
        </div>
      }
    </>
  );
};

export default Benefits;