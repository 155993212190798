import React, {useEffect, useRef, useState} from 'react';
import http, {setHeaders} from "libs/http";
import { toast } from 'react-toastify';
import Input from 'components/Input';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import TextEditor from 'components/TextEditor';
import { ElementLoader } from 'components/Loaders';
import { modalActions } from 'store/modal/modal.store';
import { useDispatch, useSelector } from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import BenefitDocumentInput from "modules/benefit/UI/BenefitDocumentInput";
import {
  addBenefit, deleteImage, editFixedBenefit,
  editPromo,
  getBenefits, getCategories,
  uploadImages
} from 'modules/benefit/services/benefit.store';
import Datepicker from "components/Datepicker";
import SearchableDropdown from "components/SearchableDropdown";
import CheckBox from "components/Checkbox";
import {formatDate} from "libs/datetime";
import { MUNICIPALITIES_MONTENEGRO, MUNICIPALITIES_SERBIA } from 'libs/locations';
import {TAX_TREATMENTS} from 'modules/benefit/UI/AddBenefitForm';
import {useParams} from 'react-router-dom';

interface EditBenefitsFormProps {
  benefit: any;
  providerId: any;
  isPreview: boolean;
}

const EditBenefitForm = ({ benefit, providerId, isPreview }: EditBenefitsFormProps) => {

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      price: '',
      companyPrice: '',
      discount: '',
      keywords: '',
      validFrom: '',
      validTo: '',
      category: '',
      subcategory: '',
      specialOffer: false,
      isTeamBuilding: false,
    },
  });

  const dispatch: any = useDispatch();
  const user: any = useSelector((state: any) => state.authStore.user);
  const categories: any = useSelector((state: any) => state.benefitStore.categories);
  const [loading, setLoading] = useState(false);
  const [promoMaterial, setPromoMaterial] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const importRef = useRef<any>(null);
  const importImageRef = useRef<any>(null);
  const [description, setDescription] = useState<any>('');
  const [shortDescription, setShortDescription] = useState<any>('');
  const [instructions, setInstructions] = useState<any>('');
  const [note, setNote] = useState<any>('');
  const [limitations, setLimitations] = useState<any>('');
  const [category, setCategory] = useState<any>(null);
  const [subcategory, setSubcategory] = useState<any>(null);
  const [municipality, setMunicipality] = useState<any>(null);
  const [isNfc, setIsNfc] = useState<any>(false);
  const [specialOffers, setSpecialOffers] = useState<any>(false);
  const [teamBuilding, setTeamBuilding] = useState<any>(false);
  const [subscription, setSubscription] = useState<any>(false);

  useEffect(() => {
    if (benefit.name) setValue('name', benefit.name);
    if (benefit.price) setValue('price', benefit.price);
    if (benefit.companyPrice) setValue('companyPrice', benefit.companyPrice);
    if (benefit.discount) {setValue('discount', benefit.discount);}
    if (benefit.keywords) setValue('keywords', benefit.keywords);
    if (benefit.validFrom) {
      setValue('validFrom', benefit.validFrom);
    }
    if (benefit.validTo) setValue('validTo', benefit.validTo);
    if (benefit.location) municipalityHandler({name: benefit.location, value: benefit.location});
    if (benefit.description) setDescription(benefit.description);
    if (benefit.shortDescription) setShortDescription(benefit.shortDescription);
    if (benefit.instructions) setInstructions(benefit.instructions);
    if (benefit.limitations) setLimitations(benefit.limitations);
    if (benefit.note) setNote({name: benefit.note, value: benefit.note});

    // if (benefit.category && !category) {
    //   const target = categories.find((cat: any) => cat.id === benefit.category);
    //   console.log('1', target)
    //   setCategory(target);
    // }
    // if (benefit.category && benefit.subcategory && category && !subcategory) {
    //   const target = category?.children.find((sub: any) => sub.id === benefit.subcategory);
    //   console.log('2', target)
    //   setSubcategory(target);
    // }
    if (benefit.nfc) setIsNfc(benefit.nfc);
    if (benefit.specialOffer) setSpecialOffers(benefit.specialOffer);
    if (benefit.teamBuilding) setTeamBuilding(benefit.teamBuilding);
    if (benefit.subscription) setSubscription(benefit.subscription);
    if (benefit.promoMaterial.length > 0) setPromoMaterial(benefit.promoMaterial);
    if (benefit.pictures.length > 0) setImages(benefit.pictures);
  }, [benefit]);

  useEffect(() => {
    if (benefit.category && !category) {
      const target = categories.find((cat: any) => cat.id === benefit.category);
      console.log('1', target)
      setCategory(target);
    }
    if (benefit.category && benefit.subcategory && category && !subcategory) {
      const target = category?.children.find((sub: any) => sub.id === benefit.subcategory);
      console.log('2', target)
      setSubcategory(target);
    }
  }, [benefit, category, categories]);



  const onSubmit = async (data: any) => {
    let payload: any = {
      ...benefit,
      provider: providerId,
      name: data.name,
      price: data.price,
      company_price: data.companyPrice,
      discount: data.discount ,
      keywords: data.keywords ,
      valid_from: data.validFrom ,
      valid_to: data.validTo,
      location: municipality ? municipality?.value : '',
      description,
      short_description: shortDescription,
      instructions,
      limitations,
      note: note.value,
      category: category ? category?.id : '',
      subcategory: subcategory ? subcategory?.id : '',
      promo_material: promoMaterial.map((e: any) => e.id).join(','),
      pictures: [...images],
      nfc: isNfc,
      special_offer: specialOffers,
      is_team_building: teamBuilding,
      subscription: subscription,
    };

    const formData: FormData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    if (isValid) {
      try {
        await dispatch(editFixedBenefit(benefit.id, formData));
        dispatch(getBenefits(false, providerId));
        dispatch(modalActions.closeModal());
        toast.success('Uspešno ste ažurirali benefit!');
      } catch (e: any) {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const openUploadPrompt = (): void => {
    importRef.current.click();
  }

  const openUploadImagePrompt = (): void => {
    importImageRef.current.click();
  }
  const uploadImageHandler = async (e: any): Promise<any> => {

    if ('id' in benefit) {
      let payload = new FormData()

      payload.append('position', '0');
      payload.append('benefit', benefit.id);
      payload.append('note', '');
      payload.append('image', e.target.files[0]);

      try {
        await setHeaders({ 'content-type': 'multipart/form-data' });
        const data = await dispatch(uploadImages(payload));
        await setHeaders({ 'content-type': 'application/json' });
        const imgs: any = [...images, data];
        setImages(imgs);
      } catch (e: any) {
        console.log(e, 'err');
      }
    }
  }

  const uploadPromoHandler = async (e: any): Promise<any> => {
    let req = new FormData()

    req.append('title', 'promo')
    req.append('file', e.target.files[0])

    try {
      const { data } = await http.post('v2/admin/promo/', req);
      let promo: any = [...promoMaterial, data];
      setPromoMaterial(promo);
      const payload = {
        promoMaterial: promo.map((e: any) => e.id).join(',')
      }
      await dispatch(editPromo(benefit.id, payload));
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  }

  const deletePromoHandler = async (promoId: number): Promise<any> => {
    try {
      await http.delete(`v2/admin/promo/${promoId}/`);
      const promo = promoMaterial.filter((e: any) => e.id != promoId);
      setPromoMaterial(promo);
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };

  const deleteImageHandler = async (imageId: any): Promise<any> => {
    try {
      await dispatch(deleteImage(imageId));
      const imgs = images.filter((e: any) => e.id != imageId);
      setImages(imgs);
    } catch (e: any) {
      toast.error('Nešto nije u redu');
    }
  }

  const descriptionHandler = (e: any): void => {
    setDescription(e);
  }

  const shortDescriptionHandler = (e: any): void => {
    setShortDescription(e);
  }

  const limitationsHandler = (e: any): void => {
    setLimitations(e);
  }

  const instructionsHandler = (e: any): void => {
    setInstructions(e);
  }

  const noteHandler = (note: any): void => {
    setNote(note);
  }

  const categoryHandler = (category: any): void => {
    setCategory(category);
  }

  const subcategoryHandler = (subCat: any): void => {
    console.log(subCat, 'subcat')
    setSubcategory(subCat);
  }

  const municipalityHandler = (municipality: any): void => {
    setMunicipality(municipality);
  }

  const closeForm = () => {
    dispatch(modalActions.closeModal());
  }

  const startDateHandler = (date: any): void => {
    setValue('validFrom', date);
  };

  const endDateHandler = (date: any): void => {
    setValue('validTo', date);
  };

  const toggleNfc = (): void => {
    setIsNfc(!isNfc);
  };

  const toggleSpecialOffers = (): void => {
    setSpecialOffers(!specialOffers);
  };

  const toggleTeamBuilding = (): void => {
    setTeamBuilding(!teamBuilding);
  };

  const toggleSubscription = (): void => {
    setSubscription(!subscription);
  };



  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <div className="relative">
      <div className="p-7 space-y-7">
        <div>
          <Input
            type='text'
            label="Naziv"
            autoFocus
            height='medium'
            disabled={isPreview}
            placeholder="Unesi naziv"
            isTouched={touchedFields.name}
            error={errors.name}
            validationRules={register('name', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='number'
            label="Cena"
            height='medium'
            disabled={isPreview}
            placeholder="Unesi cenu"
            isTouched={touchedFields.price}
            error={errors.price}
            validationRules={register('price', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
              min: {
                value: 0,
                message: 'Minimalan iznos je 1'
              }
            })}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='number'
            label="Procenat poreza i doprinosa"
            disabled={isPreview}
            height='medium'
            placeholder="Unesi procenat poreza i doprinosa"
            isTouched={touchedFields.companyPrice}
            error={errors.companyPrice}
            validationRules={register('companyPrice')}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='number'
            label="Popust"
            height='medium'
            disabled={isPreview}
            placeholder="Unesi popust"
            isTouched={touchedFields.discount}
            error={errors.discount}
            validationRules={register('discount')}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='text'
            label="Ključne reči"
            height='medium'
            disabled={isPreview}
            placeholder="Unesi ključne reči"
            isTouched={touchedFields.keywords}
            error={errors.keywords}
            validationRules={register('keywords')}
            onChange={() => null}
          />
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="w-1/2">
            <Datepicker
              label="Važi od"
              value={getValues('validFrom')}
              disabled={isPreview}
              format="dd.MM.yyyy"
              placeholder="Važi od"
              onChange={(value: any) => startDateHandler(value)}
            />
          </div>
          <div className="w-1/2">
            <Datepicker
              label="Važi do"
              value={getValues('validTo')}
              disabled={isPreview}
              format="dd.MM.yyyy"
              placeholder="Važi do"
              onChange={(value: any) => endDateHandler(value)}
            />
          </div>
        </div>
        {/*<div>*/}
        {/*  <Input*/}
        {/*    type='text'*/}
        {/*    label="Lokacija"*/}
        {/*    height='medium'*/}
        {/*    placeholder="Unesi lokaciju"*/}
        {/*    isTouched={touchedFields.location}*/}
        {/*    error={errors.location}*/}
        {/*    validationRules={register('location')}*/}
        {/*    onChange={() => null}*/}
        {/*  />*/}
        {/*</div>*/}
        <div>
          <SearchableDropdown
            value={municipality}
            label="Lokacija"
            idField="value"
            disabled={isPreview}
            valueToShow="name"
            placeholder="Lokacija"
            options={user.country === 1 ? MUNICIPALITIES_SERBIA : MUNICIPALITIES_MONTENEGRO}
            onChange={(municipality: any) => municipalityHandler(municipality)}
          />
        </div>
        <div className="h-60">
          <TextEditor
            label="Opis"
            value={description}
            onChange={descriptionHandler}
          />
        </div>
        <div className="h-60">
          <TextEditor
            label="Kratak opis"
            value={shortDescription}
            onChange={shortDescriptionHandler}
          />
        </div>
        <div className="h-64 pt-2">
          <TextEditor
            label="Instrukcije"
            value={instructions}
            onChange={instructionsHandler}
          />
        </div>
        <div className="h-64">
          <TextEditor
            label="Ograničenja"
            value={limitations}
            onChange={limitationsHandler}
          />
        </div>
        <div>
          {/*<TextEditor*/}
          {/*  label="Informacija o poreskom tretmanu"*/}
          {/*  value={note}*/}
          {/*  onChange={noteHandler}*/}
          {/*/>*/}
          <SearchableDropdown
            value={note}
            label="Informacija o poreskom tretmanu"
            idField="value"
            valueToShow="name"
            disabled={isPreview}
            placeholder="Informacija o poreskom tretmanu"
            options={TAX_TREATMENTS}
            onChange={(note: any) => noteHandler(note)}
          />
        </div>
        <div className="flex items-center gap-x-4">
          <div className="w-1/2">
            <SearchableDropdown
              value={categories.find((cat: any) => category && cat.id === category?.id) || ''}
              label="Kategorija"
              idField="id"
              disabled={isPreview}
              valueToShow="name"
              placeholder="Kategorija"
              options={categories}
              onChange={(category: any) => categoryHandler(category)}
            />
          </div>
          <div className="w-1/2">
            <SearchableDropdown
              value={category && subcategory ? category.children.find((sub: any) => category && sub.id === subcategory.id) : ''}
              idField="id"
              label="Potkategorija"
              disabled={!category || isPreview}
              valueToShow="name"
              placeholder="Potkategorija"
              options={category ? category.children : []}
              onChange={(subcategory: any) => subcategoryHandler(subcategory)}
            />
          </div>
        </div>
        <div className="flex items-end gap-x-5">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Prilog
            </label>
            <div className="flex flex-wrap items-center gap-6">
              {promoMaterial.map((p: any) =>
                <BenefitDocumentInput key={p.id} promo={p} onDelete={() => deletePromoHandler(p.id)}/>
              )}
            </div>
          </div>
          {!isPreview &&
            <div className="pt-3">
              <input onChange={uploadPromoHandler} hidden type="file" ref={importRef}/>
              <Button
                bg="outline-primary"
                className="w-8 flex items-center justify-center gap-x-2"
                size="small"
                onClick={openUploadPrompt}
              >
                <FontAwesomeIcon icon={faPlus}/>
              </Button>
            </div>
          }
        </div>
        <div className="flex items-end gap-x-5">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Slike
            </label>
            <div className="flex flex-wrap items-center gap-4">
              {images.map((img: any) =>
                <div className="flex items-end gap-x-2">
                  <img key={img.image} src={img.image} alt="" className="h-16 rounded-lg"/>
                  {!isPreview && <div className="flex items-center">
                    <Button
                      bg="outline-warning"
                      className="w-8 mt-0.5 flex items-center justify-center gap-x-2"
                      size="small"
                      onClick={() => deleteImageHandler(img.id)}
                    >
                      <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                  </div>}
                </div>
              )}
            </div>
          </div>
          {!isPreview && <div>
            <div className="pt-3">
              <input onChange={uploadImageHandler} hidden type="file" ref={importImageRef}/>
              <Button
                bg="outline-primary"
                className="w-8 flex items-center justify-center gap-x-2"
                size="small"
                onClick={openUploadImagePrompt}
              >
                <FontAwesomeIcon icon={faPlus}/>
              </Button>
            </div>
          </div>}
        </div>
        <div className="pt-3">
          <CheckBox
            title="IN-STORE"
            size="small"
            value={isNfc}
            disabled={isPreview}
            textClassNames="font-medium"
            onChange={toggleNfc}
          />
        </div>
        <div>
          <CheckBox
            title="Specijalne ponude"
            size="small"
            value={specialOffers}
            disabled={isPreview}
            textClassNames="font-medium"
            onChange={toggleSpecialOffers}
          />
        </div>
        <div>
          <CheckBox
            title="Pretplata"
            size="small"
            value={subscription}
            textClassNames="font-medium"
            onChange={toggleSubscription}
          />
        </div>
        <div>
          <CheckBox
            title="Team Building"
            size="small"
            value={teamBuilding}
            textClassNames="font-medium"
            onChange={toggleTeamBuilding}
          />
        </div>
      </div>
      {!isPreview &&
        <div className="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 px-7 py-6 border-t w-full z-20 bg-white">
          <Button
            bg="white"
            className="w-full flex items-center justify-center gap-x-2"
            size="regular"
            onClick={closeForm}
          >
            Otkaži
          </Button>
          <Button
            bg="primary"
            className="w-full gap-x-2 relative"
            size="regular"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Ažuriraj
            {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
          </Button>
        </div>
      }
    </div>
  );
};

export default EditBenefitForm;