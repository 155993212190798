import React, { useEffect, useState, useRef } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import {
  faUserPlus,
  faFileImport,
  faBuildingCircleArrowRight,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { ElementLoader } from 'components/Loaders';
import { modalActions } from 'store/modal/modal.store';
import { ConfirmDeleteDialog } from 'components/Dialogs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {clientActions, deleteClient, exportClients, getClients} from 'modules/client/services/client.store';
import ClientsGridView from 'modules/client/UI/ClientsGridView';
import ClientsListView from 'modules/client/UI/ClientsListView';
import ClientFilters from 'modules/client/UI/ClientFilters';
import AddEditClientForm from 'modules/client/UI/AddEditClientForm';
import http from 'libs/http';



const Clients = () => {

  const dispatch: any = useDispatch();
  const importRef = useRef<any>(null);
  const globalLoading: any = useSelector((state: any) => state.loaderStore.loading);
  const clients: any = useSelector((state: any) => state.clientStore.clients, shallowEqual);
  const filters: any = useSelector((state: any) => state.clientStore.filters, shallowEqual);
  const pagination: any = useSelector((state: any) => state.clientStore.pagination, shallowEqual);
  const [previewType, setPreviewType] = useState('grid');
  const [loading, setLoading] = useState(false);

  const openAddEditClientForm = (e: any, client: any, type: string): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <AddEditClientForm client={client} type={type} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: `${type === 'add' ? 'Dodaj' : 'Ažuriraj'} klijenta ${type === 'add' ? '' : `: ${client.name}`}`,
          size: 'medium',
        },
      })
    );
  };

  const openDeletePrompt = (e: any, clientId: number): void => {
    e.stopPropagation();
    let modalContent: React.JSX.Element = (
      <ConfirmDeleteDialog
        title="Izbriši provajdera"
        description="Da li zaista želiš obrisati klijenta? Svi podaci će biti trajno uklonjeni sa servera i prvajder se više neće prikazivati na listi. Ova akcija se ne može poništiti."
        onDelete={() => deleteClientHandler(clientId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };


  const deleteClientHandler = async (clientId: number): Promise<void> => {
    try {
      await dispatch(deleteClient(clientId))
      dispatch(modalActions.closeModal());
    } catch (e: any) {
      if (e.status !== 500) {
        toast.error(e.data);
      } else {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const exportXlsxHandler = async () => {
    setLoading(true);
    let url = `/v2/admin/companies/export/`
    http({
      url: url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "hob-clients.xlsx");
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });
    setLoading(false);


    // try {
    //   const response = await dispatch(exportClients());
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "HOB-clients.xlsx");
    //   document.body.appendChild(link);
    //   link.click();
    // } catch (e: any) {
    //   console.log(e, 'err');
    // }
  };

  // const importUsersHandler = async (e: any): Promise<any> => {
  //   let payload = new FormData();
  //   payload.append('file', e.target.files[0]);
  //   try {
  //     await dispatch(importUsers(payload));
  //   } catch (e: any) {
  //     console.log(e, 'err');
  //     toast.error('Nešto je poslo po zlu!')
  //   }
  // }

  // const exportUsersHandler = () => {
  //   setLoading(true);
  //   let url = `v2/admin/employees/export?search=${searchString}`
  //   http({
  //     url: url,
  //     method: "GET",
  //     responseType: "blob", // important
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "korisnici.xlsx");
  //     document.body.appendChild(link);
  //     link.click();
  //     setLoading(false);
  //   });
  //   setLoading(false);
  // }



  useEffect(() => {
    fetchMoreClients(true);
  }, [filters.searchString, filters.ordering, filters.country]);

  const resetFilters = () => {
    dispatch(clientActions.setCount(0));
    dispatch(clientActions.setNextPage(null));
    dispatch(clientActions.setSearchString(''));
    dispatch(clientActions.resetClients());
  };

  useEffect(() => {
    resetFilters();
    return (): void => {
      resetFilters();
    }
  }, []);


  const fetchMoreClients = async (resetData: boolean): Promise<void> => {
    try {
      await dispatch(getClients(resetData));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const nextPageRef = useRef(pagination.nextPage);
  const countRef = useRef(pagination.count);

  useEffect(() => {
    nextPageRef.current = pagination.nextPage;
  }, [pagination.nextPage]);

  useEffect(() => {
    countRef.current = pagination.count === clients.length;
  }, [pagination.count, clients]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreClients(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openImportPopup = () => {
    importRef.current.click();
  };

  return (
    <>
      <section className="flex items-center justify-between">
        <div>
          <Button
            bg="primary"
            className="lg:w-64 flex items-center justify-center gap-x-2"
            size="regular"
            onClick={(e: any) => openAddEditClientForm(e, null, 'add')}
          >
            <FontAwesomeIcon icon={faBuildingCircleArrowRight}/>
            <p>Dodaj klijenta</p>
          </Button>

        </div>
        <div className="flex items-center justify-end gap-x-3">
          <div>
            <Button
              bg="outline-primary"
              className="lg:w-64 flex items-center justify-center gap-x-2"
              size="regular"
              disabled={loading}
              onClick={() => exportXlsxHandler()}
            >
              <FontAwesomeIcon icon={faDownload}/>
              <p>Preuzmi sve klijente (.xlsx)</p>
            </Button>

          </div>
        </div>
      </section>

      <ClientFilters />

      {filters.previewType === 'grid' && clients.length > 0 &&
        <ClientsGridView
          clients={clients}
          onEdit={(e: any, client: any) => openAddEditClientForm(e, client, 'edit')}
          onCopy={(e: any, client: number) => openAddEditClientForm(e, client, 'add')}
          onDelete={(e: any, clientId: number) => openDeletePrompt(e, clientId)}
        />
      }
      {filters.previewType === 'list' && clients.length > 0 &&
        <ClientsListView
          clients={clients}
          onEdit={(e: any, client: any) => openAddEditClientForm(e, client, 'edit')}
          onCopy={(e: any, client: any) => openAddEditClientForm(e, client, 'add')}
          onDelete={(e: any, clientId: number) => openDeletePrompt(e, clientId)}
        />
      }

      {loading && !globalLoading &&
        <div className="relative h-[35vh] lg:h-[55vh] rounded-lg my-9 flex items-center justify-center text-gray-700">
          <ElementLoader color="primary" size="regular" classNames="flex items-center justify-center"/>
        </div>
      }

      {!loading && clients.length === 0 &&
        <div
          className="h-[35vh] lg:h-[55vh] rounded-lg my-9 font-medium flex items-center justify-center text-gray-700">
          Nema podataka.
        </div>
      }
    </>
  )
    ;
};

export default Clients;