import React, {useEffect, useRef, useState, useCallback, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {benefitActions, getBenefit} from 'modules/benefit/services/benefit.store';
import Slider from "react-slick";
import {
  faAngleLeft,
  faAngleRight,
  faMagnifyingGlassPlus,
  faFile,
  faMobileAlt,
  faInfoCircle,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'react-tooltip';
import {modalActions} from 'store/modal/modal.store';
import ImageViewer from 'components/ImageViewer';

const sliderOptions = {
  type: 'slide',
  speed: 300,
  autoWidth: true,
  autoHeight: true,
  gap: 20,
  perPage: 1,
  pagination: true,
  arrows: false,
  autoScroll: {
    speed: 1,
  },
  drag: 'free',
  mediaQuery: 'min',
};

const tabs: any = [
  {name: 'details', key: 'shortDescription'},
  {name: 'description', key: 'description'},
  {name: 'instructions', key: 'instructions'},
  {name: 'limitations', key: 'limitations'},
  {name: 'promo', key: 'promoMaterial'},
];

const BenefitPreview = () => {

  const dispatch: any = useDispatch();
  const sliderRef: any = useRef(null);
  const {id: benefitId}: any = useParams();
  const benefit: any = useSelector((state: any) => state.benefitStore.benefit, shallowEqual);

  const images: any[] = useMemo(() => [
    benefit?.pictures.map((pic: any) => pic.image),
  ], [benefit]);

  console.log(images, 'IMAGES')
  const [selectedTab, setSelectedTab] = useState('shortDescription');

  const getBenefitHanlder = async (benefitId: number): Promise<void> => {
    try {
      await dispatch(getBenefit(benefitId));
    } catch (e: any) {
      console.log(e, "err");
    }
  };

  const goToSlide = (index: any) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const setCurrentTab = (tab: any): void => {
    setSelectedTab(tab.key);
  };

  const openImageViewer = (index: number): void => {
    console.log('uslii')
    const modalContent: React.JSX.Element = (<ImageViewer images={images[0]} index={index} />);

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Foto Album',
          size: 'extra-large',
        },
      })
    );
  };



  console.log(tabs, 'tabs')

  const parseCategoryName = (name: any) => {
    if (name && name.split('/')[1]) return name.split('/')[1];
    return name;
  };


  useEffect(() => {
    getBenefitHanlder(benefitId);

    return () => {
      dispatch(benefitActions.setBenefit(null));
    }
  }, [benefitId]);

  console.log(benefit, 'benefit component')

  return (
    <div className="flex items-start gap-x-5">
      <section className="w-1/2 h-[730px] bg-white rounded-lg p-4">
        <div className="relative">
          <div className="absolute left-0 top-0 h-full flex items-center justify-center">
            <button onClick={goToPrevSlide} className={`h-9 w-9 rounded-full shadow border border-gray-200 bg-white/70 z-10 text-gray-700
              transform -translate-x-5 group transition duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faAngleLeft} className="text-gray-500"/>
              {/*<font-awesome-icon icon="fa-angle-left" :class="`group-hover:text-base-600 font-bold`" />*/}
            </button>
          </div>
          <Slider {...sliderOptions} ref={sliderRef} className="aspect-[2/1] overflow-hidden ">
            {benefit?.pictures.map((picture: any, index: number) =>
              <div className="w-full aspect-[2/1] relative">
                <div
                  onClick={() => openImageViewer(index)}
                  className="absolute right-0 ml-auto font-bold cursor-pointer z-50 w-14 h-14 bg-black/20 flex items-center justify-center rounded-bl-lg rounded-tr-lg">
                  <FontAwesomeIcon icon={faMagnifyingGlassPlus} className="text-white relative"/>
                </div>
                <img
                  onClick={() => openImageViewer(index)}
                  src={picture?.image} className="w-full h-full object-center object-cover sm:rounded-lg"
                  alt="test"/>
              </div>
            )}
          </Slider>
          <div className="absolute right-0 top-0 h-full flex items-center justify-center">
            <button onClick={goToNextSlide} className={`h-9 w-9 rounded-full shadow border border-gray-200 bg-white/70 z-10 text-gray-700
              transform translate-x-4 group transition duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faAngleRight} className="text-gray-500"/>
              {/*<font-awesome-icon icon="fa-angle-left" :class="`group-hover:text-base-600 font-bold`" />*/}
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3 mt-6">
          {benefit?.pictures.map((picture: any, i: number) =>
            <button className="border focus:ring focus:ring-base-500 rounded-lg">
              <img onClick={() => goToSlide(i)} src={picture.image} alt="test"
                   className="w-full h-full rounded-lg object-center object-cover aspect-[2/1]"/>
            </button>
          )}
        </div>
      </section>


      <section className="w-1/2 h-[730px] bg-white rounded-lg p-4 relative">
        {benefit?.discount > 0 &&
          <div
            className="absolute text-white text-lg xl:text-2xl font-medium right-0 top-0 p-2 md:p-3 lg:p-2 xl:p-2 bg-base-500 rounded-bl-2xl rounded-tr-md">
            -{benefit.discount}%
          </div>
        }

        <h1 className="text-xl font-black tracking-tight text-gray-800 w-10/12">{benefit?.name}</h1>

        <div className="flex items-center mt-4">
          <img className="w-12 h-12 rounded-full object-center object-contain border mr-2"
               src={benefit?.provider?.picture} alt="provider-logo"/>
          <p className="text-gray-600 font-semibold">{benefit?.provider?.name}</p>
        </div>

        <div className="mt-3 text-gray-600 text-sm font-medium">
          <span className="font-black">Keywords:&nbsp;</span>
          <span className="italic">{benefit?.keywords}</span>
        </div>

        <div className="my-4 mb-2 rounded-md overflow-hidden select-none mt-5">
          <div className={`bg-gray-50 p-1.5 rounded-xl overflow-hidden hidden md:block`}>
            <div
              className={`relative mx-auto flex items-center justify-between font-medium bg-gray-50 rounded-xl w-full cursor-pointer select-none overflow-hidden`}>
              <div
                className={`absolute left-0 top-0 transform ${selectedTab === tabs[0].key ? 'translate-x-0' : ''} ${selectedTab === tabs[1].key ? 'translate-x-[100%]' : ''} ${selectedTab === tabs[2].key ? 'translate-x-[200%]' : ''} ${selectedTab === tabs[3].key ? 'translate-x-[300%]' : ''} ${selectedTab === tabs[4].key ? 'translate-x-[400%]' : ''} top-0 bg-base-500 w-1/5 h-8 p-1 rounded-xl animation duration-300 ease-in-out`}></div>
              {tabs.map((tab: any) =>
                <div
                  onClick={() => setCurrentTab(tab)}
                  className={`${selectedTab === tab.key ? 'text-white' : ''} w-1/5 flex items-center justify-center rounded-lg h-8 animation duration-500 ease-in-out`}>
                  <p className="relative capitalize">{tab.name}</p>
                </div>
              )}
            </div>
          </div>
        </div>


        <div className="max-h-72 overflow-y-auto w-full p-1.5">
          {selectedTab === 'promoMaterial' &&
            <div className="flex flex-wrap gap-4 h-full">
              {(benefit?.promoMaterial && benefit?.promoMaterial.length > 0) && benefit?.promoMaterial.map((doc: any) =>
                <a href={doc?.file} target="_blank" rel="noreferrer" className="text-center group hover:text-base-600">
                  <FontAwesomeIcon icon={faFile} className="text-gray-400 text-2xl group-hover:text-base-600"/>
                  <p className="text-sm">{doc?.title}</p>
                </a>
              )}
              {(!benefit?.promoMaterial || benefit.promoMaterial.length === 0) &&
                <div className="h-40 w-full flex items-center justify-center text-gray-400 text-sm">Nema dostupnih
                  priloga!</div>
              }
            </div>
          }
          {(selectedTab !== 'promoMaterial' && benefit) &&
            <div
              className="text-sm p-2 h-full"
              dangerouslySetInnerHTML={{__html: benefit[selectedTab]}}>
            </div>
          }
        </div>

        {!benefit?.fixed && !benefit?.specialOffer && Number(benefit?.price) > 0 &&
          <div className="mt-6">
            <div className="flex justify-start text-base-700">
              <div>
                {!benefit?.fixed && +benefit?.price > 0 && benefit?.discount > 0 &&
                  <p className="text-lg font-bold line-through text-yellow-500">{benefit?.priceCurrency}</p>
                }
                {!benefit?.nfc && !benefit?.fixed && +benefit?.price > 0 &&
                  <div className="flex items-center gap-x-2">
                    <p className="text-xl font-bold">
                      {benefit?.priceDiscountCurrency}
                    </p>
                    <Tooltip id="pdv"/>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      data-tooltip-id={`pdv`}
                      data-tooltip-content="U potpunosti oporeziv benefit"
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        }

        {/*IN STORE*/}
        {benefit?.nfc &&
          <div className="w-40 flex items-end gap-x-2 text-base-600 ml-1.5 mt-5">
            <FontAwesomeIcon icon={faMobileAlt} className="text-3xl transform scale-110"/>
            <p className="text-lg font-medium">IN-STORE</p>
          </div>
        }

        {/*INVOICE*/}
        {!benefit?.nfc && !Number(benefit?.price) &&
          <div className="flex items-end gap-x-2 text-base-600 ml-1.5 mt-5">
            <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-3xl transform scale-110"/>
            <p className="text-lg font-medium">INVOICE</p>
          </div>
        }

        <div className="divide-y pt-4 mt-4 text-gray-600">
          <div className="flex justify-between px-1 py-2">
            <p className="font-bold">Kategorija:</p>
            <p className="text-right">{parseCategoryName(benefit?.category[0].name)}</p>
          </div>
          {benefit?.category.length === 2 &&
            <div className="flex justify-between px-1 py-2">
              <p className="font-bold">Potgategorija:</p>
              <p className="text-right">{parseCategoryName(benefit?.category[1].name)}</p>
            </div>
          }
          <div className="flex justify-between px-1 py-2">
            <p className="font-bold">Provajder:</p>
            <p className="text-right">{benefit?.provider.name}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BenefitPreview;