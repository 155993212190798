import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { modalActions } from 'store/modal/modal.store';
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ConfirmDenyDialogProps {
  title: string;
  description: string;
  onDeactivate: () => void;
}

const DeactivatePrompt = ({ title, description, onDeactivate }: ConfirmDenyDialogProps) => {

  const dispatch: any = useDispatch();
  const handleClose = () => {
    // e.stopPropagation();
    dispatch(modalActions.closeModal());
  }

  return (
    <div>
      <section className="py-5 text-gray-600 text-center flex items-center justify-center">
        <div>
          <div className="flex items-center justify-center bg-red-500/20 rounded-full mx-auto h-10 w-10">
            <FontAwesomeIcon icon={faTriangleExclamation} className="text-xl text-red-600" />
          </div>
          {title && <p className="text-lg font-bold mt-2">{ title }</p>
          }
        </div>
      </section>
      {description &&
          <section className="text-center text-gray-600 border-y p-4">
              <p>{ description }</p>
          </section>
      }
      <section className="flex items-center justify-center gap-x-6 py-5">
        <Button
          bg="primary"
          className="w-36"
          size="regular"
          onClick={handleClose}
        >
          Otkaži
        </Button>
        <Button
          bg="info"
          className="w-36"
          size="regular"
          onClick={onDeactivate}
        >
          Deaktiviraj
        </Button>
      </section>
    </div>
  );
};

export default DeactivatePrompt;
